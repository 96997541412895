import React, {FormEvent, useState} from "react";
import {GenerateInvitationRequest, GenerateInvitationResponse} from "../../../compiled";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";

export const ProvidersTab = () => {

  const {getIdTokenClaims} = useAuth0();

  // The RPC callback from generated an invite. Setting this as state
  // causes it to render a message
  const [generatingCodes, setGeneratingCodes] = useState(false);
  const [generatingCodesError, setGeneratedCodesError] = useState('');
  const [response, setResponse] = useState<GenerateInvitationResponse>();

  const [numCodes, setNumCodes] = useState(1);
  const [autoApprove, setAutoApprove] = useState(false);

  const getProviderHost = () => {
    if (window.location.host.startsWith("localhost")) {
      return "http://localhost:3002";
    } else if (window.location.host.endsWith("thymebook.com")) {
      return "https://provider.thymebook.com";
    }

    return "https://provider.kenko.space";
  }

  const generateInvites = function (event: FormEvent<HTMLFormElement>) {

    event.preventDefault();

    setGeneratingCodes(false);
    setGeneratedCodesError('');

    let request = GenerateInvitationRequest.encode(new GenerateInvitationRequest({
      num: numCodes,
      autoApprove: autoApprove
    })).finish();

    SendRpc(getIdTokenClaims, 'generate_provider_invite', request).then(r => {
      let response = GenerateInvitationResponse.decode(r);
      console.log('invite response', response);
      setResponse(response);

    }).catch(e => {
      console.log(e);
      setGeneratedCodesError('Error generating invites. ' + e)
    }).finally(() => {
      setGeneratingCodes(false);
    });
  };

  return (
      <>
        <div>
          <b>Provider Admin</b>
          <br/>
          <br/>
        </div>

        <div>
          Generate a provider invite code<br/><br/>
          <form style={{display: 'flex', flexDirection: 'column', gap: 20}} onSubmit={generateInvites}>

            <div>How many?
              <input type={'number'} value={numCodes} min={1} max={10}
                     onChange={v => setNumCodes(parseInt(v.target.value))}/>
            </div>

            <div>Auto-approve providers?
              <input type={'checkbox'} checked={autoApprove}
                     onChange={v => setAutoApprove(v.target.checked)}/>
            </div>

            <button type="submit" value="Submit">Generate the code!!</button>
          </form>

          {generatingCodesError &&
              <div style={{color: 'red'}}>Error generating invite! {generatingCodesError}</div>}

          {response && response.inviteCodes &&
              <div>
                <br/>
                Please share this URL with a provider.
                <br/>

                {response.inviteCodes.map(code => {
                  return <div>
                    <a href={`${getProviderHost()}/redeemInvite/${code}`}>
                      {getProviderHost()}/redeemInvite/{code}
                    </a>
                  </div>
                })
                }
              </div>
          }
        </div>
      </>
  );
}

/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.AdminInvitationProto = (function() {

    /**
     * Properties of an AdminInvitationProto.
     * @exports IAdminInvitationProto
     * @interface IAdminInvitationProto
     * @property {string|null} [inviteCode] AdminInvitationProto inviteCode
     * @property {number|Long|null} [generationTime] AdminInvitationProto generationTime
     * @property {boolean|null} [redeemed] AdminInvitationProto redeemed
     */

    /**
     * Constructs a new AdminInvitationProto.
     * @exports AdminInvitationProto
     * @classdesc Represents an AdminInvitationProto.
     * @implements IAdminInvitationProto
     * @constructor
     * @param {IAdminInvitationProto=} [properties] Properties to set
     */
    function AdminInvitationProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminInvitationProto inviteCode.
     * @member {string} inviteCode
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.inviteCode = "";

    /**
     * AdminInvitationProto generationTime.
     * @member {number|Long} generationTime
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.generationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * AdminInvitationProto redeemed.
     * @member {boolean} redeemed
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.redeemed = false;

    /**
     * Creates a new AdminInvitationProto instance using the specified properties.
     * @function create
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto=} [properties] Properties to set
     * @returns {AdminInvitationProto} AdminInvitationProto instance
     */
    AdminInvitationProto.create = function create(properties) {
        return new AdminInvitationProto(properties);
    };

    /**
     * Encodes the specified AdminInvitationProto message. Does not implicitly {@link AdminInvitationProto.verify|verify} messages.
     * @function encode
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto} message AdminInvitationProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminInvitationProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
        if (message.generationTime != null && Object.hasOwnProperty.call(message, "generationTime"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generationTime);
        if (message.redeemed != null && Object.hasOwnProperty.call(message, "redeemed"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.redeemed);
        return writer;
    };

    /**
     * Encodes the specified AdminInvitationProto message, length delimited. Does not implicitly {@link AdminInvitationProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto} message AdminInvitationProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminInvitationProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminInvitationProto message from the specified reader or buffer.
     * @function decode
     * @memberof AdminInvitationProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminInvitationProto} AdminInvitationProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminInvitationProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminInvitationProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.inviteCode = reader.string();
                    break;
                }
            case 2: {
                    message.generationTime = reader.int64();
                    break;
                }
            case 3: {
                    message.redeemed = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminInvitationProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminInvitationProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminInvitationProto} AdminInvitationProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminInvitationProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminInvitationProto message.
     * @function verify
     * @memberof AdminInvitationProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminInvitationProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        if (message.generationTime != null && message.hasOwnProperty("generationTime"))
            if (!$util.isInteger(message.generationTime) && !(message.generationTime && $util.isInteger(message.generationTime.low) && $util.isInteger(message.generationTime.high)))
                return "generationTime: integer|Long expected";
        if (message.redeemed != null && message.hasOwnProperty("redeemed"))
            if (typeof message.redeemed !== "boolean")
                return "redeemed: boolean expected";
        return null;
    };

    /**
     * Creates an AdminInvitationProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminInvitationProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminInvitationProto} AdminInvitationProto
     */
    AdminInvitationProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminInvitationProto)
            return object;
        var message = new $root.AdminInvitationProto();
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        if (object.generationTime != null)
            if ($util.Long)
                (message.generationTime = $util.Long.fromValue(object.generationTime)).unsigned = false;
            else if (typeof object.generationTime === "string")
                message.generationTime = parseInt(object.generationTime, 10);
            else if (typeof object.generationTime === "number")
                message.generationTime = object.generationTime;
            else if (typeof object.generationTime === "object")
                message.generationTime = new $util.LongBits(object.generationTime.low >>> 0, object.generationTime.high >>> 0).toNumber();
        if (object.redeemed != null)
            message.redeemed = Boolean(object.redeemed);
        return message;
    };

    /**
     * Creates a plain object from an AdminInvitationProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminInvitationProto
     * @static
     * @param {AdminInvitationProto} message AdminInvitationProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminInvitationProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.inviteCode = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.generationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.generationTime = options.longs === String ? "0" : 0;
            object.redeemed = false;
        }
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        if (message.generationTime != null && message.hasOwnProperty("generationTime"))
            if (typeof message.generationTime === "number")
                object.generationTime = options.longs === String ? String(message.generationTime) : message.generationTime;
            else
                object.generationTime = options.longs === String ? $util.Long.prototype.toString.call(message.generationTime) : options.longs === Number ? new $util.LongBits(message.generationTime.low >>> 0, message.generationTime.high >>> 0).toNumber() : message.generationTime;
        if (message.redeemed != null && message.hasOwnProperty("redeemed"))
            object.redeemed = message.redeemed;
        return object;
    };

    /**
     * Converts this AdminInvitationProto to JSON.
     * @function toJSON
     * @memberof AdminInvitationProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminInvitationProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminInvitationProto
     * @function getTypeUrl
     * @memberof AdminInvitationProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminInvitationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminInvitationProto";
    };

    return AdminInvitationProto;
})();

$root.CheckAdminRoleRequest = (function() {

    /**
     * Properties of a CheckAdminRoleRequest.
     * @exports ICheckAdminRoleRequest
     * @interface ICheckAdminRoleRequest
     */

    /**
     * Constructs a new CheckAdminRoleRequest.
     * @exports CheckAdminRoleRequest
     * @classdesc Represents a CheckAdminRoleRequest.
     * @implements ICheckAdminRoleRequest
     * @constructor
     * @param {ICheckAdminRoleRequest=} [properties] Properties to set
     */
    function CheckAdminRoleRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CheckAdminRoleRequest instance using the specified properties.
     * @function create
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest=} [properties] Properties to set
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest instance
     */
    CheckAdminRoleRequest.create = function create(properties) {
        return new CheckAdminRoleRequest(properties);
    };

    /**
     * Encodes the specified CheckAdminRoleRequest message. Does not implicitly {@link CheckAdminRoleRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest} message CheckAdminRoleRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CheckAdminRoleRequest message, length delimited. Does not implicitly {@link CheckAdminRoleRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest} message CheckAdminRoleRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckAdminRoleRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckAdminRoleRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckAdminRoleRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckAdminRoleRequest message.
     * @function verify
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckAdminRoleRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CheckAdminRoleRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     */
    CheckAdminRoleRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckAdminRoleRequest)
            return object;
        return new $root.CheckAdminRoleRequest();
    };

    /**
     * Creates a plain object from a CheckAdminRoleRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {CheckAdminRoleRequest} message CheckAdminRoleRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckAdminRoleRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CheckAdminRoleRequest to JSON.
     * @function toJSON
     * @memberof CheckAdminRoleRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckAdminRoleRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckAdminRoleRequest
     * @function getTypeUrl
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckAdminRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckAdminRoleRequest";
    };

    return CheckAdminRoleRequest;
})();

$root.CheckAdminRoleResponse = (function() {

    /**
     * Properties of a CheckAdminRoleResponse.
     * @exports ICheckAdminRoleResponse
     * @interface ICheckAdminRoleResponse
     * @property {boolean|null} [okay] CheckAdminRoleResponse okay
     * @property {boolean|null} [isAdmin] CheckAdminRoleResponse isAdmin
     */

    /**
     * Constructs a new CheckAdminRoleResponse.
     * @exports CheckAdminRoleResponse
     * @classdesc Represents a CheckAdminRoleResponse.
     * @implements ICheckAdminRoleResponse
     * @constructor
     * @param {ICheckAdminRoleResponse=} [properties] Properties to set
     */
    function CheckAdminRoleResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckAdminRoleResponse okay.
     * @member {boolean} okay
     * @memberof CheckAdminRoleResponse
     * @instance
     */
    CheckAdminRoleResponse.prototype.okay = false;

    /**
     * CheckAdminRoleResponse isAdmin.
     * @member {boolean} isAdmin
     * @memberof CheckAdminRoleResponse
     * @instance
     */
    CheckAdminRoleResponse.prototype.isAdmin = false;

    /**
     * Creates a new CheckAdminRoleResponse instance using the specified properties.
     * @function create
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse=} [properties] Properties to set
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse instance
     */
    CheckAdminRoleResponse.create = function create(properties) {
        return new CheckAdminRoleResponse(properties);
    };

    /**
     * Encodes the specified CheckAdminRoleResponse message. Does not implicitly {@link CheckAdminRoleResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse} message CheckAdminRoleResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.isAdmin != null && Object.hasOwnProperty.call(message, "isAdmin"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isAdmin);
        return writer;
    };

    /**
     * Encodes the specified CheckAdminRoleResponse message, length delimited. Does not implicitly {@link CheckAdminRoleResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse} message CheckAdminRoleResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckAdminRoleResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckAdminRoleResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.isAdmin = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckAdminRoleResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckAdminRoleResponse message.
     * @function verify
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckAdminRoleResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
            if (typeof message.isAdmin !== "boolean")
                return "isAdmin: boolean expected";
        return null;
    };

    /**
     * Creates a CheckAdminRoleResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     */
    CheckAdminRoleResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckAdminRoleResponse)
            return object;
        var message = new $root.CheckAdminRoleResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.isAdmin != null)
            message.isAdmin = Boolean(object.isAdmin);
        return message;
    };

    /**
     * Creates a plain object from a CheckAdminRoleResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {CheckAdminRoleResponse} message CheckAdminRoleResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckAdminRoleResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.isAdmin = false;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
            object.isAdmin = message.isAdmin;
        return object;
    };

    /**
     * Converts this CheckAdminRoleResponse to JSON.
     * @function toJSON
     * @memberof CheckAdminRoleResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckAdminRoleResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckAdminRoleResponse
     * @function getTypeUrl
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckAdminRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckAdminRoleResponse";
    };

    return CheckAdminRoleResponse;
})();

$root.GenerateInvitationRequest = (function() {

    /**
     * Properties of a GenerateInvitationRequest.
     * @exports IGenerateInvitationRequest
     * @interface IGenerateInvitationRequest
     * @property {number|null} [num] GenerateInvitationRequest num
     * @property {boolean|null} [autoApprove] GenerateInvitationRequest autoApprove
     */

    /**
     * Constructs a new GenerateInvitationRequest.
     * @exports GenerateInvitationRequest
     * @classdesc Represents a GenerateInvitationRequest.
     * @implements IGenerateInvitationRequest
     * @constructor
     * @param {IGenerateInvitationRequest=} [properties] Properties to set
     */
    function GenerateInvitationRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateInvitationRequest num.
     * @member {number} num
     * @memberof GenerateInvitationRequest
     * @instance
     */
    GenerateInvitationRequest.prototype.num = 0;

    /**
     * GenerateInvitationRequest autoApprove.
     * @member {boolean} autoApprove
     * @memberof GenerateInvitationRequest
     * @instance
     */
    GenerateInvitationRequest.prototype.autoApprove = false;

    /**
     * Creates a new GenerateInvitationRequest instance using the specified properties.
     * @function create
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest=} [properties] Properties to set
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest instance
     */
    GenerateInvitationRequest.create = function create(properties) {
        return new GenerateInvitationRequest(properties);
    };

    /**
     * Encodes the specified GenerateInvitationRequest message. Does not implicitly {@link GenerateInvitationRequest.verify|verify} messages.
     * @function encode
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest} message GenerateInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.num != null && Object.hasOwnProperty.call(message, "num"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.num);
        if (message.autoApprove != null && Object.hasOwnProperty.call(message, "autoApprove"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.autoApprove);
        return writer;
    };

    /**
     * Encodes the specified GenerateInvitationRequest message, length delimited. Does not implicitly {@link GenerateInvitationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest} message GenerateInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateInvitationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateInvitationRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.num = reader.int32();
                    break;
                }
            case 2: {
                    message.autoApprove = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateInvitationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateInvitationRequest message.
     * @function verify
     * @memberof GenerateInvitationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateInvitationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.num != null && message.hasOwnProperty("num"))
            if (!$util.isInteger(message.num))
                return "num: integer expected";
        if (message.autoApprove != null && message.hasOwnProperty("autoApprove"))
            if (typeof message.autoApprove !== "boolean")
                return "autoApprove: boolean expected";
        return null;
    };

    /**
     * Creates a GenerateInvitationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateInvitationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     */
    GenerateInvitationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateInvitationRequest)
            return object;
        var message = new $root.GenerateInvitationRequest();
        if (object.num != null)
            message.num = object.num | 0;
        if (object.autoApprove != null)
            message.autoApprove = Boolean(object.autoApprove);
        return message;
    };

    /**
     * Creates a plain object from a GenerateInvitationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateInvitationRequest
     * @static
     * @param {GenerateInvitationRequest} message GenerateInvitationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateInvitationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.num = 0;
            object.autoApprove = false;
        }
        if (message.num != null && message.hasOwnProperty("num"))
            object.num = message.num;
        if (message.autoApprove != null && message.hasOwnProperty("autoApprove"))
            object.autoApprove = message.autoApprove;
        return object;
    };

    /**
     * Converts this GenerateInvitationRequest to JSON.
     * @function toJSON
     * @memberof GenerateInvitationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateInvitationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateInvitationRequest
     * @function getTypeUrl
     * @memberof GenerateInvitationRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateInvitationRequest";
    };

    return GenerateInvitationRequest;
})();

$root.GenerateInvitationResponse = (function() {

    /**
     * Properties of a GenerateInvitationResponse.
     * @exports IGenerateInvitationResponse
     * @interface IGenerateInvitationResponse
     * @property {boolean|null} [okay] GenerateInvitationResponse okay
     * @property {Array.<string>|null} [inviteCodes] GenerateInvitationResponse inviteCodes
     */

    /**
     * Constructs a new GenerateInvitationResponse.
     * @exports GenerateInvitationResponse
     * @classdesc Represents a GenerateInvitationResponse.
     * @implements IGenerateInvitationResponse
     * @constructor
     * @param {IGenerateInvitationResponse=} [properties] Properties to set
     */
    function GenerateInvitationResponse(properties) {
        this.inviteCodes = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateInvitationResponse okay.
     * @member {boolean} okay
     * @memberof GenerateInvitationResponse
     * @instance
     */
    GenerateInvitationResponse.prototype.okay = false;

    /**
     * GenerateInvitationResponse inviteCodes.
     * @member {Array.<string>} inviteCodes
     * @memberof GenerateInvitationResponse
     * @instance
     */
    GenerateInvitationResponse.prototype.inviteCodes = $util.emptyArray;

    /**
     * Creates a new GenerateInvitationResponse instance using the specified properties.
     * @function create
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse=} [properties] Properties to set
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse instance
     */
    GenerateInvitationResponse.create = function create(properties) {
        return new GenerateInvitationResponse(properties);
    };

    /**
     * Encodes the specified GenerateInvitationResponse message. Does not implicitly {@link GenerateInvitationResponse.verify|verify} messages.
     * @function encode
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse} message GenerateInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.inviteCodes != null && message.inviteCodes.length)
            for (var i = 0; i < message.inviteCodes.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCodes[i]);
        return writer;
    };

    /**
     * Encodes the specified GenerateInvitationResponse message, length delimited. Does not implicitly {@link GenerateInvitationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse} message GenerateInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateInvitationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateInvitationResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.inviteCodes && message.inviteCodes.length))
                        message.inviteCodes = [];
                    message.inviteCodes.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateInvitationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateInvitationResponse message.
     * @function verify
     * @memberof GenerateInvitationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateInvitationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.inviteCodes != null && message.hasOwnProperty("inviteCodes")) {
            if (!Array.isArray(message.inviteCodes))
                return "inviteCodes: array expected";
            for (var i = 0; i < message.inviteCodes.length; ++i)
                if (!$util.isString(message.inviteCodes[i]))
                    return "inviteCodes: string[] expected";
        }
        return null;
    };

    /**
     * Creates a GenerateInvitationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateInvitationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     */
    GenerateInvitationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateInvitationResponse)
            return object;
        var message = new $root.GenerateInvitationResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.inviteCodes) {
            if (!Array.isArray(object.inviteCodes))
                throw TypeError(".GenerateInvitationResponse.inviteCodes: array expected");
            message.inviteCodes = [];
            for (var i = 0; i < object.inviteCodes.length; ++i)
                message.inviteCodes[i] = String(object.inviteCodes[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a GenerateInvitationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateInvitationResponse
     * @static
     * @param {GenerateInvitationResponse} message GenerateInvitationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateInvitationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.inviteCodes = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.inviteCodes && message.inviteCodes.length) {
            object.inviteCodes = [];
            for (var j = 0; j < message.inviteCodes.length; ++j)
                object.inviteCodes[j] = message.inviteCodes[j];
        }
        return object;
    };

    /**
     * Converts this GenerateInvitationResponse to JSON.
     * @function toJSON
     * @memberof GenerateInvitationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateInvitationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateInvitationResponse
     * @function getTypeUrl
     * @memberof GenerateInvitationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateInvitationResponse";
    };

    return GenerateInvitationResponse;
})();

$root.GetExistingInvitationsRequest = (function() {

    /**
     * Properties of a GetExistingInvitationsRequest.
     * @exports IGetExistingInvitationsRequest
     * @interface IGetExistingInvitationsRequest
     * @property {string|null} [networkId] GetExistingInvitationsRequest networkId
     */

    /**
     * Constructs a new GetExistingInvitationsRequest.
     * @exports GetExistingInvitationsRequest
     * @classdesc Represents a GetExistingInvitationsRequest.
     * @implements IGetExistingInvitationsRequest
     * @constructor
     * @param {IGetExistingInvitationsRequest=} [properties] Properties to set
     */
    function GetExistingInvitationsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetExistingInvitationsRequest networkId.
     * @member {string} networkId
     * @memberof GetExistingInvitationsRequest
     * @instance
     */
    GetExistingInvitationsRequest.prototype.networkId = "";

    /**
     * Creates a new GetExistingInvitationsRequest instance using the specified properties.
     * @function create
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest=} [properties] Properties to set
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest instance
     */
    GetExistingInvitationsRequest.create = function create(properties) {
        return new GetExistingInvitationsRequest(properties);
    };

    /**
     * Encodes the specified GetExistingInvitationsRequest message. Does not implicitly {@link GetExistingInvitationsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest} message GetExistingInvitationsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.networkId != null && Object.hasOwnProperty.call(message, "networkId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.networkId);
        return writer;
    };

    /**
     * Encodes the specified GetExistingInvitationsRequest message, length delimited. Does not implicitly {@link GetExistingInvitationsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest} message GetExistingInvitationsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetExistingInvitationsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetExistingInvitationsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.networkId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetExistingInvitationsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetExistingInvitationsRequest message.
     * @function verify
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetExistingInvitationsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            if (!$util.isString(message.networkId))
                return "networkId: string expected";
        return null;
    };

    /**
     * Creates a GetExistingInvitationsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     */
    GetExistingInvitationsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetExistingInvitationsRequest)
            return object;
        var message = new $root.GetExistingInvitationsRequest();
        if (object.networkId != null)
            message.networkId = String(object.networkId);
        return message;
    };

    /**
     * Creates a plain object from a GetExistingInvitationsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {GetExistingInvitationsRequest} message GetExistingInvitationsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetExistingInvitationsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.networkId = "";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            object.networkId = message.networkId;
        return object;
    };

    /**
     * Converts this GetExistingInvitationsRequest to JSON.
     * @function toJSON
     * @memberof GetExistingInvitationsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetExistingInvitationsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetExistingInvitationsRequest
     * @function getTypeUrl
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetExistingInvitationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetExistingInvitationsRequest";
    };

    return GetExistingInvitationsRequest;
})();

$root.GetExistingInvitationsResponse = (function() {

    /**
     * Properties of a GetExistingInvitationsResponse.
     * @exports IGetExistingInvitationsResponse
     * @interface IGetExistingInvitationsResponse
     * @property {boolean|null} [okay] GetExistingInvitationsResponse okay
     * @property {IAdminInvitationProto|null} [invitation] GetExistingInvitationsResponse invitation
     */

    /**
     * Constructs a new GetExistingInvitationsResponse.
     * @exports GetExistingInvitationsResponse
     * @classdesc Represents a GetExistingInvitationsResponse.
     * @implements IGetExistingInvitationsResponse
     * @constructor
     * @param {IGetExistingInvitationsResponse=} [properties] Properties to set
     */
    function GetExistingInvitationsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetExistingInvitationsResponse okay.
     * @member {boolean} okay
     * @memberof GetExistingInvitationsResponse
     * @instance
     */
    GetExistingInvitationsResponse.prototype.okay = false;

    /**
     * GetExistingInvitationsResponse invitation.
     * @member {IAdminInvitationProto|null|undefined} invitation
     * @memberof GetExistingInvitationsResponse
     * @instance
     */
    GetExistingInvitationsResponse.prototype.invitation = null;

    /**
     * Creates a new GetExistingInvitationsResponse instance using the specified properties.
     * @function create
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse=} [properties] Properties to set
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse instance
     */
    GetExistingInvitationsResponse.create = function create(properties) {
        return new GetExistingInvitationsResponse(properties);
    };

    /**
     * Encodes the specified GetExistingInvitationsResponse message. Does not implicitly {@link GetExistingInvitationsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse} message GetExistingInvitationsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.invitation != null && Object.hasOwnProperty.call(message, "invitation"))
            $root.AdminInvitationProto.encode(message.invitation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetExistingInvitationsResponse message, length delimited. Does not implicitly {@link GetExistingInvitationsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse} message GetExistingInvitationsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetExistingInvitationsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetExistingInvitationsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.invitation = $root.AdminInvitationProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetExistingInvitationsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetExistingInvitationsResponse message.
     * @function verify
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetExistingInvitationsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.invitation != null && message.hasOwnProperty("invitation")) {
            var error = $root.AdminInvitationProto.verify(message.invitation);
            if (error)
                return "invitation." + error;
        }
        return null;
    };

    /**
     * Creates a GetExistingInvitationsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     */
    GetExistingInvitationsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetExistingInvitationsResponse)
            return object;
        var message = new $root.GetExistingInvitationsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.invitation != null) {
            if (typeof object.invitation !== "object")
                throw TypeError(".GetExistingInvitationsResponse.invitation: object expected");
            message.invitation = $root.AdminInvitationProto.fromObject(object.invitation);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetExistingInvitationsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {GetExistingInvitationsResponse} message GetExistingInvitationsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetExistingInvitationsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.invitation = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.invitation != null && message.hasOwnProperty("invitation"))
            object.invitation = $root.AdminInvitationProto.toObject(message.invitation, options);
        return object;
    };

    /**
     * Converts this GetExistingInvitationsResponse to JSON.
     * @function toJSON
     * @memberof GetExistingInvitationsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetExistingInvitationsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetExistingInvitationsResponse
     * @function getTypeUrl
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetExistingInvitationsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetExistingInvitationsResponse";
    };

    return GetExistingInvitationsResponse;
})();

$root.UpdateSettingsRequest = (function() {

    /**
     * Properties of an UpdateSettingsRequest.
     * @exports IUpdateSettingsRequest
     * @interface IUpdateSettingsRequest
     * @property {space.kenko.proto.ISettingsProto|null} [settings] UpdateSettingsRequest settings
     */

    /**
     * Constructs a new UpdateSettingsRequest.
     * @exports UpdateSettingsRequest
     * @classdesc Represents an UpdateSettingsRequest.
     * @implements IUpdateSettingsRequest
     * @constructor
     * @param {IUpdateSettingsRequest=} [properties] Properties to set
     */
    function UpdateSettingsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSettingsRequest settings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} settings
     * @memberof UpdateSettingsRequest
     * @instance
     */
    UpdateSettingsRequest.prototype.settings = null;

    /**
     * Creates a new UpdateSettingsRequest instance using the specified properties.
     * @function create
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest=} [properties] Properties to set
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest instance
     */
    UpdateSettingsRequest.create = function create(properties) {
        return new UpdateSettingsRequest(properties);
    };

    /**
     * Encodes the specified UpdateSettingsRequest message. Does not implicitly {@link UpdateSettingsRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest} message UpdateSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
            $root.space.kenko.proto.SettingsProto.encode(message.settings, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateSettingsRequest message, length delimited. Does not implicitly {@link UpdateSettingsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest} message UpdateSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSettingsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSettingsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.settings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSettingsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSettingsRequest message.
     * @function verify
     * @memberof UpdateSettingsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSettingsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.settings != null && message.hasOwnProperty("settings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.settings);
            if (error)
                return "settings." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateSettingsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSettingsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     */
    UpdateSettingsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSettingsRequest)
            return object;
        var message = new $root.UpdateSettingsRequest();
        if (object.settings != null) {
            if (typeof object.settings !== "object")
                throw TypeError(".UpdateSettingsRequest.settings: object expected");
            message.settings = $root.space.kenko.proto.SettingsProto.fromObject(object.settings);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateSettingsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSettingsRequest
     * @static
     * @param {UpdateSettingsRequest} message UpdateSettingsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSettingsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.settings = null;
        if (message.settings != null && message.hasOwnProperty("settings"))
            object.settings = $root.space.kenko.proto.SettingsProto.toObject(message.settings, options);
        return object;
    };

    /**
     * Converts this UpdateSettingsRequest to JSON.
     * @function toJSON
     * @memberof UpdateSettingsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSettingsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSettingsRequest
     * @function getTypeUrl
     * @memberof UpdateSettingsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSettingsRequest";
    };

    return UpdateSettingsRequest;
})();

$root.UpdateSettingsResponse = (function() {

    /**
     * Properties of an UpdateSettingsResponse.
     * @exports IUpdateSettingsResponse
     * @interface IUpdateSettingsResponse
     * @property {boolean|null} [okay] UpdateSettingsResponse okay
     * @property {space.kenko.proto.ISettingsProto|null} [newSettings] UpdateSettingsResponse newSettings
     */

    /**
     * Constructs a new UpdateSettingsResponse.
     * @exports UpdateSettingsResponse
     * @classdesc Represents an UpdateSettingsResponse.
     * @implements IUpdateSettingsResponse
     * @constructor
     * @param {IUpdateSettingsResponse=} [properties] Properties to set
     */
    function UpdateSettingsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSettingsResponse okay.
     * @member {boolean} okay
     * @memberof UpdateSettingsResponse
     * @instance
     */
    UpdateSettingsResponse.prototype.okay = false;

    /**
     * UpdateSettingsResponse newSettings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} newSettings
     * @memberof UpdateSettingsResponse
     * @instance
     */
    UpdateSettingsResponse.prototype.newSettings = null;

    /**
     * Creates a new UpdateSettingsResponse instance using the specified properties.
     * @function create
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse=} [properties] Properties to set
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse instance
     */
    UpdateSettingsResponse.create = function create(properties) {
        return new UpdateSettingsResponse(properties);
    };

    /**
     * Encodes the specified UpdateSettingsResponse message. Does not implicitly {@link UpdateSettingsResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse} message UpdateSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.newSettings != null && Object.hasOwnProperty.call(message, "newSettings"))
            $root.space.kenko.proto.SettingsProto.encode(message.newSettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateSettingsResponse message, length delimited. Does not implicitly {@link UpdateSettingsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse} message UpdateSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSettingsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSettingsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.newSettings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSettingsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSettingsResponse message.
     * @function verify
     * @memberof UpdateSettingsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSettingsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.newSettings != null && message.hasOwnProperty("newSettings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.newSettings);
            if (error)
                return "newSettings." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateSettingsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSettingsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     */
    UpdateSettingsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSettingsResponse)
            return object;
        var message = new $root.UpdateSettingsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.newSettings != null) {
            if (typeof object.newSettings !== "object")
                throw TypeError(".UpdateSettingsResponse.newSettings: object expected");
            message.newSettings = $root.space.kenko.proto.SettingsProto.fromObject(object.newSettings);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateSettingsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSettingsResponse
     * @static
     * @param {UpdateSettingsResponse} message UpdateSettingsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSettingsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.newSettings = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.newSettings != null && message.hasOwnProperty("newSettings"))
            object.newSettings = $root.space.kenko.proto.SettingsProto.toObject(message.newSettings, options);
        return object;
    };

    /**
     * Converts this UpdateSettingsResponse to JSON.
     * @function toJSON
     * @memberof UpdateSettingsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSettingsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSettingsResponse
     * @function getTypeUrl
     * @memberof UpdateSettingsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSettingsResponse";
    };

    return UpdateSettingsResponse;
})();

$root.GetSettingsRequest = (function() {

    /**
     * Properties of a GetSettingsRequest.
     * @exports IGetSettingsRequest
     * @interface IGetSettingsRequest
     */

    /**
     * Constructs a new GetSettingsRequest.
     * @exports GetSettingsRequest
     * @classdesc Represents a GetSettingsRequest.
     * @implements IGetSettingsRequest
     * @constructor
     * @param {IGetSettingsRequest=} [properties] Properties to set
     */
    function GetSettingsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetSettingsRequest instance using the specified properties.
     * @function create
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest=} [properties] Properties to set
     * @returns {GetSettingsRequest} GetSettingsRequest instance
     */
    GetSettingsRequest.create = function create(properties) {
        return new GetSettingsRequest(properties);
    };

    /**
     * Encodes the specified GetSettingsRequest message. Does not implicitly {@link GetSettingsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest} message GetSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetSettingsRequest message, length delimited. Does not implicitly {@link GetSettingsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest} message GetSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetSettingsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetSettingsRequest} GetSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetSettingsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetSettingsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetSettingsRequest} GetSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetSettingsRequest message.
     * @function verify
     * @memberof GetSettingsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSettingsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetSettingsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetSettingsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetSettingsRequest} GetSettingsRequest
     */
    GetSettingsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetSettingsRequest)
            return object;
        return new $root.GetSettingsRequest();
    };

    /**
     * Creates a plain object from a GetSettingsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetSettingsRequest
     * @static
     * @param {GetSettingsRequest} message GetSettingsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSettingsRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetSettingsRequest to JSON.
     * @function toJSON
     * @memberof GetSettingsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSettingsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetSettingsRequest
     * @function getTypeUrl
     * @memberof GetSettingsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetSettingsRequest";
    };

    return GetSettingsRequest;
})();

$root.GetSettingsResponse = (function() {

    /**
     * Properties of a GetSettingsResponse.
     * @exports IGetSettingsResponse
     * @interface IGetSettingsResponse
     * @property {boolean|null} [okay] GetSettingsResponse okay
     * @property {space.kenko.proto.ISettingsProto|null} [newSettings] GetSettingsResponse newSettings
     */

    /**
     * Constructs a new GetSettingsResponse.
     * @exports GetSettingsResponse
     * @classdesc Represents a GetSettingsResponse.
     * @implements IGetSettingsResponse
     * @constructor
     * @param {IGetSettingsResponse=} [properties] Properties to set
     */
    function GetSettingsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetSettingsResponse okay.
     * @member {boolean} okay
     * @memberof GetSettingsResponse
     * @instance
     */
    GetSettingsResponse.prototype.okay = false;

    /**
     * GetSettingsResponse newSettings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} newSettings
     * @memberof GetSettingsResponse
     * @instance
     */
    GetSettingsResponse.prototype.newSettings = null;

    /**
     * Creates a new GetSettingsResponse instance using the specified properties.
     * @function create
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse=} [properties] Properties to set
     * @returns {GetSettingsResponse} GetSettingsResponse instance
     */
    GetSettingsResponse.create = function create(properties) {
        return new GetSettingsResponse(properties);
    };

    /**
     * Encodes the specified GetSettingsResponse message. Does not implicitly {@link GetSettingsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse} message GetSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.newSettings != null && Object.hasOwnProperty.call(message, "newSettings"))
            $root.space.kenko.proto.SettingsProto.encode(message.newSettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetSettingsResponse message, length delimited. Does not implicitly {@link GetSettingsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse} message GetSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetSettingsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetSettingsResponse} GetSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetSettingsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.newSettings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetSettingsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetSettingsResponse} GetSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetSettingsResponse message.
     * @function verify
     * @memberof GetSettingsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSettingsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.newSettings != null && message.hasOwnProperty("newSettings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.newSettings);
            if (error)
                return "newSettings." + error;
        }
        return null;
    };

    /**
     * Creates a GetSettingsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetSettingsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetSettingsResponse} GetSettingsResponse
     */
    GetSettingsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetSettingsResponse)
            return object;
        var message = new $root.GetSettingsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.newSettings != null) {
            if (typeof object.newSettings !== "object")
                throw TypeError(".GetSettingsResponse.newSettings: object expected");
            message.newSettings = $root.space.kenko.proto.SettingsProto.fromObject(object.newSettings);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetSettingsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetSettingsResponse
     * @static
     * @param {GetSettingsResponse} message GetSettingsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSettingsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.newSettings = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.newSettings != null && message.hasOwnProperty("newSettings"))
            object.newSettings = $root.space.kenko.proto.SettingsProto.toObject(message.newSettings, options);
        return object;
    };

    /**
     * Converts this GetSettingsResponse to JSON.
     * @function toJSON
     * @memberof GetSettingsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSettingsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetSettingsResponse
     * @function getTypeUrl
     * @memberof GetSettingsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetSettingsResponse";
    };

    return GetSettingsResponse;
})();

$root.AdminBusinessProfileProto = (function() {

    /**
     * Properties of an AdminBusinessProfileProto.
     * @exports IAdminBusinessProfileProto
     * @interface IAdminBusinessProfileProto
     * @property {string|null} [businessId] AdminBusinessProfileProto businessId
     * @property {string|null} [versionId] AdminBusinessProfileProto versionId
     * @property {number|Long|null} [lastModifiedTimestamp] AdminBusinessProfileProto lastModifiedTimestamp
     * @property {number|Long|null} [reviewTimestamp] AdminBusinessProfileProto reviewTimestamp
     * @property {space.kenko.proto.IProfileReviewProto|null} [review] AdminBusinessProfileProto review
     * @property {space.kenko.proto.IBusinessProto|null} [proto] AdminBusinessProfileProto proto
     * @property {Array.<space.kenko.proto.IBusinessLocationProto>|null} [locations] AdminBusinessProfileProto locations
     * @property {Array.<space.kenko.proto.IProviderBioProto>|null} [providers] AdminBusinessProfileProto providers
     * @property {space.kenko.proto.IPracticeDetailsProto|null} [practice] AdminBusinessProfileProto practice
     */

    /**
     * Constructs a new AdminBusinessProfileProto.
     * @exports AdminBusinessProfileProto
     * @classdesc Represents an AdminBusinessProfileProto.
     * @implements IAdminBusinessProfileProto
     * @constructor
     * @param {IAdminBusinessProfileProto=} [properties] Properties to set
     */
    function AdminBusinessProfileProto(properties) {
        this.locations = [];
        this.providers = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminBusinessProfileProto businessId.
     * @member {string} businessId
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.businessId = "";

    /**
     * AdminBusinessProfileProto versionId.
     * @member {string} versionId
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.versionId = "";

    /**
     * AdminBusinessProfileProto lastModifiedTimestamp.
     * @member {number|Long} lastModifiedTimestamp
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.lastModifiedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * AdminBusinessProfileProto reviewTimestamp.
     * @member {number|Long} reviewTimestamp
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.reviewTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * AdminBusinessProfileProto review.
     * @member {space.kenko.proto.IProfileReviewProto|null|undefined} review
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.review = null;

    /**
     * AdminBusinessProfileProto proto.
     * @member {space.kenko.proto.IBusinessProto|null|undefined} proto
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.proto = null;

    /**
     * AdminBusinessProfileProto locations.
     * @member {Array.<space.kenko.proto.IBusinessLocationProto>} locations
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.locations = $util.emptyArray;

    /**
     * AdminBusinessProfileProto providers.
     * @member {Array.<space.kenko.proto.IProviderBioProto>} providers
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.providers = $util.emptyArray;

    /**
     * AdminBusinessProfileProto practice.
     * @member {space.kenko.proto.IPracticeDetailsProto|null|undefined} practice
     * @memberof AdminBusinessProfileProto
     * @instance
     */
    AdminBusinessProfileProto.prototype.practice = null;

    /**
     * Creates a new AdminBusinessProfileProto instance using the specified properties.
     * @function create
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {IAdminBusinessProfileProto=} [properties] Properties to set
     * @returns {AdminBusinessProfileProto} AdminBusinessProfileProto instance
     */
    AdminBusinessProfileProto.create = function create(properties) {
        return new AdminBusinessProfileProto(properties);
    };

    /**
     * Encodes the specified AdminBusinessProfileProto message. Does not implicitly {@link AdminBusinessProfileProto.verify|verify} messages.
     * @function encode
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {IAdminBusinessProfileProto} message AdminBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.versionId);
        if (message.lastModifiedTimestamp != null && Object.hasOwnProperty.call(message, "lastModifiedTimestamp"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.lastModifiedTimestamp);
        if (message.reviewTimestamp != null && Object.hasOwnProperty.call(message, "reviewTimestamp"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.reviewTimestamp);
        if (message.review != null && Object.hasOwnProperty.call(message, "review"))
            $root.space.kenko.proto.ProfileReviewProto.encode(message.review, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.proto != null && Object.hasOwnProperty.call(message, "proto"))
            $root.space.kenko.proto.BusinessProto.encode(message.proto, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.locations != null && message.locations.length)
            for (var i = 0; i < message.locations.length; ++i)
                $root.space.kenko.proto.BusinessLocationProto.encode(message.locations[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.providers != null && message.providers.length)
            for (var i = 0; i < message.providers.length; ++i)
                $root.space.kenko.proto.ProviderBioProto.encode(message.providers[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.practice != null && Object.hasOwnProperty.call(message, "practice"))
            $root.space.kenko.proto.PracticeDetailsProto.encode(message.practice, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AdminBusinessProfileProto message, length delimited. Does not implicitly {@link AdminBusinessProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {IAdminBusinessProfileProto} message AdminBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminBusinessProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminBusinessProfileProto} AdminBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminBusinessProfileProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessId = reader.string();
                    break;
                }
            case 2: {
                    message.versionId = reader.string();
                    break;
                }
            case 3: {
                    message.lastModifiedTimestamp = reader.int64();
                    break;
                }
            case 4: {
                    message.reviewTimestamp = reader.int64();
                    break;
                }
            case 5: {
                    message.review = $root.space.kenko.proto.ProfileReviewProto.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.proto = $root.space.kenko.proto.BusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    if (!(message.locations && message.locations.length))
                        message.locations = [];
                    message.locations.push($root.space.kenko.proto.BusinessLocationProto.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    if (!(message.providers && message.providers.length))
                        message.providers = [];
                    message.providers.push($root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32()));
                    break;
                }
            case 100: {
                    message.practice = $root.space.kenko.proto.PracticeDetailsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminBusinessProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminBusinessProfileProto} AdminBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminBusinessProfileProto message.
     * @function verify
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminBusinessProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            if (!$util.isString(message.versionId))
                return "versionId: string expected";
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (!$util.isInteger(message.lastModifiedTimestamp) && !(message.lastModifiedTimestamp && $util.isInteger(message.lastModifiedTimestamp.low) && $util.isInteger(message.lastModifiedTimestamp.high)))
                return "lastModifiedTimestamp: integer|Long expected";
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (!$util.isInteger(message.reviewTimestamp) && !(message.reviewTimestamp && $util.isInteger(message.reviewTimestamp.low) && $util.isInteger(message.reviewTimestamp.high)))
                return "reviewTimestamp: integer|Long expected";
        if (message.review != null && message.hasOwnProperty("review")) {
            var error = $root.space.kenko.proto.ProfileReviewProto.verify(message.review);
            if (error)
                return "review." + error;
        }
        if (message.proto != null && message.hasOwnProperty("proto")) {
            var error = $root.space.kenko.proto.BusinessProto.verify(message.proto);
            if (error)
                return "proto." + error;
        }
        if (message.locations != null && message.hasOwnProperty("locations")) {
            if (!Array.isArray(message.locations))
                return "locations: array expected";
            for (var i = 0; i < message.locations.length; ++i) {
                var error = $root.space.kenko.proto.BusinessLocationProto.verify(message.locations[i]);
                if (error)
                    return "locations." + error;
            }
        }
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (var i = 0; i < message.providers.length; ++i) {
                var error = $root.space.kenko.proto.ProviderBioProto.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        if (message.practice != null && message.hasOwnProperty("practice")) {
            var error = $root.space.kenko.proto.PracticeDetailsProto.verify(message.practice);
            if (error)
                return "practice." + error;
        }
        return null;
    };

    /**
     * Creates an AdminBusinessProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminBusinessProfileProto} AdminBusinessProfileProto
     */
    AdminBusinessProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminBusinessProfileProto)
            return object;
        var message = new $root.AdminBusinessProfileProto();
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.versionId != null)
            message.versionId = String(object.versionId);
        if (object.lastModifiedTimestamp != null)
            if ($util.Long)
                (message.lastModifiedTimestamp = $util.Long.fromValue(object.lastModifiedTimestamp)).unsigned = false;
            else if (typeof object.lastModifiedTimestamp === "string")
                message.lastModifiedTimestamp = parseInt(object.lastModifiedTimestamp, 10);
            else if (typeof object.lastModifiedTimestamp === "number")
                message.lastModifiedTimestamp = object.lastModifiedTimestamp;
            else if (typeof object.lastModifiedTimestamp === "object")
                message.lastModifiedTimestamp = new $util.LongBits(object.lastModifiedTimestamp.low >>> 0, object.lastModifiedTimestamp.high >>> 0).toNumber();
        if (object.reviewTimestamp != null)
            if ($util.Long)
                (message.reviewTimestamp = $util.Long.fromValue(object.reviewTimestamp)).unsigned = false;
            else if (typeof object.reviewTimestamp === "string")
                message.reviewTimestamp = parseInt(object.reviewTimestamp, 10);
            else if (typeof object.reviewTimestamp === "number")
                message.reviewTimestamp = object.reviewTimestamp;
            else if (typeof object.reviewTimestamp === "object")
                message.reviewTimestamp = new $util.LongBits(object.reviewTimestamp.low >>> 0, object.reviewTimestamp.high >>> 0).toNumber();
        if (object.review != null) {
            if (typeof object.review !== "object")
                throw TypeError(".AdminBusinessProfileProto.review: object expected");
            message.review = $root.space.kenko.proto.ProfileReviewProto.fromObject(object.review);
        }
        if (object.proto != null) {
            if (typeof object.proto !== "object")
                throw TypeError(".AdminBusinessProfileProto.proto: object expected");
            message.proto = $root.space.kenko.proto.BusinessProto.fromObject(object.proto);
        }
        if (object.locations) {
            if (!Array.isArray(object.locations))
                throw TypeError(".AdminBusinessProfileProto.locations: array expected");
            message.locations = [];
            for (var i = 0; i < object.locations.length; ++i) {
                if (typeof object.locations[i] !== "object")
                    throw TypeError(".AdminBusinessProfileProto.locations: object expected");
                message.locations[i] = $root.space.kenko.proto.BusinessLocationProto.fromObject(object.locations[i]);
            }
        }
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".AdminBusinessProfileProto.providers: array expected");
            message.providers = [];
            for (var i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".AdminBusinessProfileProto.providers: object expected");
                message.providers[i] = $root.space.kenko.proto.ProviderBioProto.fromObject(object.providers[i]);
            }
        }
        if (object.practice != null) {
            if (typeof object.practice !== "object")
                throw TypeError(".AdminBusinessProfileProto.practice: object expected");
            message.practice = $root.space.kenko.proto.PracticeDetailsProto.fromObject(object.practice);
        }
        return message;
    };

    /**
     * Creates a plain object from an AdminBusinessProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {AdminBusinessProfileProto} message AdminBusinessProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminBusinessProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.locations = [];
            object.providers = [];
        }
        if (options.defaults) {
            object.businessId = "";
            object.versionId = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.lastModifiedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastModifiedTimestamp = options.longs === String ? "0" : 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.reviewTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.reviewTimestamp = options.longs === String ? "0" : 0;
            object.review = null;
            object.proto = null;
            object.practice = null;
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            object.versionId = message.versionId;
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (typeof message.lastModifiedTimestamp === "number")
                object.lastModifiedTimestamp = options.longs === String ? String(message.lastModifiedTimestamp) : message.lastModifiedTimestamp;
            else
                object.lastModifiedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.lastModifiedTimestamp) : options.longs === Number ? new $util.LongBits(message.lastModifiedTimestamp.low >>> 0, message.lastModifiedTimestamp.high >>> 0).toNumber() : message.lastModifiedTimestamp;
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (typeof message.reviewTimestamp === "number")
                object.reviewTimestamp = options.longs === String ? String(message.reviewTimestamp) : message.reviewTimestamp;
            else
                object.reviewTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.reviewTimestamp) : options.longs === Number ? new $util.LongBits(message.reviewTimestamp.low >>> 0, message.reviewTimestamp.high >>> 0).toNumber() : message.reviewTimestamp;
        if (message.review != null && message.hasOwnProperty("review"))
            object.review = $root.space.kenko.proto.ProfileReviewProto.toObject(message.review, options);
        if (message.proto != null && message.hasOwnProperty("proto"))
            object.proto = $root.space.kenko.proto.BusinessProto.toObject(message.proto, options);
        if (message.locations && message.locations.length) {
            object.locations = [];
            for (var j = 0; j < message.locations.length; ++j)
                object.locations[j] = $root.space.kenko.proto.BusinessLocationProto.toObject(message.locations[j], options);
        }
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (var j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.space.kenko.proto.ProviderBioProto.toObject(message.providers[j], options);
        }
        if (message.practice != null && message.hasOwnProperty("practice"))
            object.practice = $root.space.kenko.proto.PracticeDetailsProto.toObject(message.practice, options);
        return object;
    };

    /**
     * Converts this AdminBusinessProfileProto to JSON.
     * @function toJSON
     * @memberof AdminBusinessProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminBusinessProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminBusinessProfileProto
     * @function getTypeUrl
     * @memberof AdminBusinessProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminBusinessProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminBusinessProfileProto";
    };

    return AdminBusinessProfileProto;
})();

$root.AdminBusinessProto = (function() {

    /**
     * Properties of an AdminBusinessProto.
     * @exports IAdminBusinessProto
     * @interface IAdminBusinessProto
     * @property {string|null} [businessId] AdminBusinessProto businessId
     * @property {space.kenko.proto.ProviderApprovalState|null} [approvalState] AdminBusinessProto approvalState
     * @property {space.kenko.proto.IProviderSurveyProto|null} [surveyProto] AdminBusinessProto surveyProto
     * @property {string|null} [shortUrl] AdminBusinessProto shortUrl
     * @property {string|null} [liveProfileVersion] AdminBusinessProto liveProfileVersion
     * @property {number|Long|null} [approvalReviewTimestamp] AdminBusinessProto approvalReviewTimestamp
     */

    /**
     * Constructs a new AdminBusinessProto.
     * @exports AdminBusinessProto
     * @classdesc Represents an AdminBusinessProto.
     * @implements IAdminBusinessProto
     * @constructor
     * @param {IAdminBusinessProto=} [properties] Properties to set
     */
    function AdminBusinessProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminBusinessProto businessId.
     * @member {string} businessId
     * @memberof AdminBusinessProto
     * @instance
     */
    AdminBusinessProto.prototype.businessId = "";

    /**
     * AdminBusinessProto approvalState.
     * @member {space.kenko.proto.ProviderApprovalState} approvalState
     * @memberof AdminBusinessProto
     * @instance
     */
    AdminBusinessProto.prototype.approvalState = 0;

    /**
     * AdminBusinessProto surveyProto.
     * @member {space.kenko.proto.IProviderSurveyProto|null|undefined} surveyProto
     * @memberof AdminBusinessProto
     * @instance
     */
    AdminBusinessProto.prototype.surveyProto = null;

    /**
     * AdminBusinessProto shortUrl.
     * @member {string} shortUrl
     * @memberof AdminBusinessProto
     * @instance
     */
    AdminBusinessProto.prototype.shortUrl = "";

    /**
     * AdminBusinessProto liveProfileVersion.
     * @member {string} liveProfileVersion
     * @memberof AdminBusinessProto
     * @instance
     */
    AdminBusinessProto.prototype.liveProfileVersion = "";

    /**
     * AdminBusinessProto approvalReviewTimestamp.
     * @member {number|Long} approvalReviewTimestamp
     * @memberof AdminBusinessProto
     * @instance
     */
    AdminBusinessProto.prototype.approvalReviewTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new AdminBusinessProto instance using the specified properties.
     * @function create
     * @memberof AdminBusinessProto
     * @static
     * @param {IAdminBusinessProto=} [properties] Properties to set
     * @returns {AdminBusinessProto} AdminBusinessProto instance
     */
    AdminBusinessProto.create = function create(properties) {
        return new AdminBusinessProto(properties);
    };

    /**
     * Encodes the specified AdminBusinessProto message. Does not implicitly {@link AdminBusinessProto.verify|verify} messages.
     * @function encode
     * @memberof AdminBusinessProto
     * @static
     * @param {IAdminBusinessProto} message AdminBusinessProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
        if (message.approvalState != null && Object.hasOwnProperty.call(message, "approvalState"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.approvalState);
        if (message.surveyProto != null && Object.hasOwnProperty.call(message, "surveyProto"))
            $root.space.kenko.proto.ProviderSurveyProto.encode(message.surveyProto, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.shortUrl);
        if (message.liveProfileVersion != null && Object.hasOwnProperty.call(message, "liveProfileVersion"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.liveProfileVersion);
        if (message.approvalReviewTimestamp != null && Object.hasOwnProperty.call(message, "approvalReviewTimestamp"))
            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.approvalReviewTimestamp);
        return writer;
    };

    /**
     * Encodes the specified AdminBusinessProto message, length delimited. Does not implicitly {@link AdminBusinessProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminBusinessProto
     * @static
     * @param {IAdminBusinessProto} message AdminBusinessProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminBusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminBusinessProto message from the specified reader or buffer.
     * @function decode
     * @memberof AdminBusinessProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminBusinessProto} AdminBusinessProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminBusinessProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessId = reader.string();
                    break;
                }
            case 2: {
                    message.approvalState = reader.int32();
                    break;
                }
            case 3: {
                    message.surveyProto = $root.space.kenko.proto.ProviderSurveyProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.shortUrl = reader.string();
                    break;
                }
            case 5: {
                    message.liveProfileVersion = reader.string();
                    break;
                }
            case 6: {
                    message.approvalReviewTimestamp = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminBusinessProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminBusinessProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminBusinessProto} AdminBusinessProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminBusinessProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminBusinessProto message.
     * @function verify
     * @memberof AdminBusinessProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminBusinessProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.approvalState != null && message.hasOwnProperty("approvalState"))
            switch (message.approvalState) {
            default:
                return "approvalState: enum value expected";
            case 0:
            case 10:
            case 11:
            case 20:
            case 30:
            case 40:
                break;
            }
        if (message.surveyProto != null && message.hasOwnProperty("surveyProto")) {
            var error = $root.space.kenko.proto.ProviderSurveyProto.verify(message.surveyProto);
            if (error)
                return "surveyProto." + error;
        }
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            if (!$util.isString(message.shortUrl))
                return "shortUrl: string expected";
        if (message.liveProfileVersion != null && message.hasOwnProperty("liveProfileVersion"))
            if (!$util.isString(message.liveProfileVersion))
                return "liveProfileVersion: string expected";
        if (message.approvalReviewTimestamp != null && message.hasOwnProperty("approvalReviewTimestamp"))
            if (!$util.isInteger(message.approvalReviewTimestamp) && !(message.approvalReviewTimestamp && $util.isInteger(message.approvalReviewTimestamp.low) && $util.isInteger(message.approvalReviewTimestamp.high)))
                return "approvalReviewTimestamp: integer|Long expected";
        return null;
    };

    /**
     * Creates an AdminBusinessProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminBusinessProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminBusinessProto} AdminBusinessProto
     */
    AdminBusinessProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminBusinessProto)
            return object;
        var message = new $root.AdminBusinessProto();
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        switch (object.approvalState) {
        default:
            if (typeof object.approvalState === "number") {
                message.approvalState = object.approvalState;
                break;
            }
            break;
        case "APPROVAL_STATE_UNKNOWN":
        case 0:
            message.approvalState = 0;
            break;
        case "NEW_PROVIDER_MANUAL_APPROVE":
        case 10:
            message.approvalState = 10;
            break;
        case "NEW_PROVIDER_AUTO_APPROVE":
        case 11:
            message.approvalState = 11;
            break;
        case "AWAITING_REVIEW":
        case 20:
            message.approvalState = 20;
            break;
        case "PROVIDER_APPROVED":
        case 30:
            message.approvalState = 30;
            break;
        case "PROVIDER_REJECTED":
        case 40:
            message.approvalState = 40;
            break;
        }
        if (object.surveyProto != null) {
            if (typeof object.surveyProto !== "object")
                throw TypeError(".AdminBusinessProto.surveyProto: object expected");
            message.surveyProto = $root.space.kenko.proto.ProviderSurveyProto.fromObject(object.surveyProto);
        }
        if (object.shortUrl != null)
            message.shortUrl = String(object.shortUrl);
        if (object.liveProfileVersion != null)
            message.liveProfileVersion = String(object.liveProfileVersion);
        if (object.approvalReviewTimestamp != null)
            if ($util.Long)
                (message.approvalReviewTimestamp = $util.Long.fromValue(object.approvalReviewTimestamp)).unsigned = false;
            else if (typeof object.approvalReviewTimestamp === "string")
                message.approvalReviewTimestamp = parseInt(object.approvalReviewTimestamp, 10);
            else if (typeof object.approvalReviewTimestamp === "number")
                message.approvalReviewTimestamp = object.approvalReviewTimestamp;
            else if (typeof object.approvalReviewTimestamp === "object")
                message.approvalReviewTimestamp = new $util.LongBits(object.approvalReviewTimestamp.low >>> 0, object.approvalReviewTimestamp.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from an AdminBusinessProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminBusinessProto
     * @static
     * @param {AdminBusinessProto} message AdminBusinessProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminBusinessProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.businessId = "";
            object.approvalState = options.enums === String ? "APPROVAL_STATE_UNKNOWN" : 0;
            object.surveyProto = null;
            object.shortUrl = "";
            object.liveProfileVersion = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.approvalReviewTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.approvalReviewTimestamp = options.longs === String ? "0" : 0;
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.approvalState != null && message.hasOwnProperty("approvalState"))
            object.approvalState = options.enums === String ? $root.space.kenko.proto.ProviderApprovalState[message.approvalState] === undefined ? message.approvalState : $root.space.kenko.proto.ProviderApprovalState[message.approvalState] : message.approvalState;
        if (message.surveyProto != null && message.hasOwnProperty("surveyProto"))
            object.surveyProto = $root.space.kenko.proto.ProviderSurveyProto.toObject(message.surveyProto, options);
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            object.shortUrl = message.shortUrl;
        if (message.liveProfileVersion != null && message.hasOwnProperty("liveProfileVersion"))
            object.liveProfileVersion = message.liveProfileVersion;
        if (message.approvalReviewTimestamp != null && message.hasOwnProperty("approvalReviewTimestamp"))
            if (typeof message.approvalReviewTimestamp === "number")
                object.approvalReviewTimestamp = options.longs === String ? String(message.approvalReviewTimestamp) : message.approvalReviewTimestamp;
            else
                object.approvalReviewTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.approvalReviewTimestamp) : options.longs === Number ? new $util.LongBits(message.approvalReviewTimestamp.low >>> 0, message.approvalReviewTimestamp.high >>> 0).toNumber() : message.approvalReviewTimestamp;
        return object;
    };

    /**
     * Converts this AdminBusinessProto to JSON.
     * @function toJSON
     * @memberof AdminBusinessProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminBusinessProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminBusinessProto
     * @function getTypeUrl
     * @memberof AdminBusinessProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminBusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminBusinessProto";
    };

    return AdminBusinessProto;
})();

$root.AdminProviderLoginProto = (function() {

    /**
     * Properties of an AdminProviderLoginProto.
     * @exports IAdminProviderLoginProto
     * @interface IAdminProviderLoginProto
     * @property {string|null} [providerId] AdminProviderLoginProto providerId
     * @property {string|null} [businessId] AdminProviderLoginProto businessId
     * @property {string|null} [loginId] AdminProviderLoginProto loginId
     * @property {Array.<string>|null} [roles] AdminProviderLoginProto roles
     * @property {string|null} [lastLoginEmail] AdminProviderLoginProto lastLoginEmail
     * @property {number|Long|null} [lastLoginTimestamp] AdminProviderLoginProto lastLoginTimestamp
     * @property {space.kenko.proto.IUserProfileProto|null} [profile] AdminProviderLoginProto profile
     */

    /**
     * Constructs a new AdminProviderLoginProto.
     * @exports AdminProviderLoginProto
     * @classdesc Represents an AdminProviderLoginProto.
     * @implements IAdminProviderLoginProto
     * @constructor
     * @param {IAdminProviderLoginProto=} [properties] Properties to set
     */
    function AdminProviderLoginProto(properties) {
        this.roles = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminProviderLoginProto providerId.
     * @member {string} providerId
     * @memberof AdminProviderLoginProto
     * @instance
     */
    AdminProviderLoginProto.prototype.providerId = "";

    /**
     * AdminProviderLoginProto businessId.
     * @member {string} businessId
     * @memberof AdminProviderLoginProto
     * @instance
     */
    AdminProviderLoginProto.prototype.businessId = "";

    /**
     * AdminProviderLoginProto loginId.
     * @member {string} loginId
     * @memberof AdminProviderLoginProto
     * @instance
     */
    AdminProviderLoginProto.prototype.loginId = "";

    /**
     * AdminProviderLoginProto roles.
     * @member {Array.<string>} roles
     * @memberof AdminProviderLoginProto
     * @instance
     */
    AdminProviderLoginProto.prototype.roles = $util.emptyArray;

    /**
     * AdminProviderLoginProto lastLoginEmail.
     * @member {string} lastLoginEmail
     * @memberof AdminProviderLoginProto
     * @instance
     */
    AdminProviderLoginProto.prototype.lastLoginEmail = "";

    /**
     * AdminProviderLoginProto lastLoginTimestamp.
     * @member {number|Long} lastLoginTimestamp
     * @memberof AdminProviderLoginProto
     * @instance
     */
    AdminProviderLoginProto.prototype.lastLoginTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * AdminProviderLoginProto profile.
     * @member {space.kenko.proto.IUserProfileProto|null|undefined} profile
     * @memberof AdminProviderLoginProto
     * @instance
     */
    AdminProviderLoginProto.prototype.profile = null;

    /**
     * Creates a new AdminProviderLoginProto instance using the specified properties.
     * @function create
     * @memberof AdminProviderLoginProto
     * @static
     * @param {IAdminProviderLoginProto=} [properties] Properties to set
     * @returns {AdminProviderLoginProto} AdminProviderLoginProto instance
     */
    AdminProviderLoginProto.create = function create(properties) {
        return new AdminProviderLoginProto(properties);
    };

    /**
     * Encodes the specified AdminProviderLoginProto message. Does not implicitly {@link AdminProviderLoginProto.verify|verify} messages.
     * @function encode
     * @memberof AdminProviderLoginProto
     * @static
     * @param {IAdminProviderLoginProto} message AdminProviderLoginProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminProviderLoginProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
        if (message.loginId != null && Object.hasOwnProperty.call(message, "loginId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.loginId);
        if (message.roles != null && message.roles.length)
            for (var i = 0; i < message.roles.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.roles[i]);
        if (message.lastLoginEmail != null && Object.hasOwnProperty.call(message, "lastLoginEmail"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastLoginEmail);
        if (message.lastLoginTimestamp != null && Object.hasOwnProperty.call(message, "lastLoginTimestamp"))
            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.lastLoginTimestamp);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.space.kenko.proto.UserProfileProto.encode(message.profile, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AdminProviderLoginProto message, length delimited. Does not implicitly {@link AdminProviderLoginProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminProviderLoginProto
     * @static
     * @param {IAdminProviderLoginProto} message AdminProviderLoginProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminProviderLoginProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminProviderLoginProto message from the specified reader or buffer.
     * @function decode
     * @memberof AdminProviderLoginProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminProviderLoginProto} AdminProviderLoginProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminProviderLoginProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminProviderLoginProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.businessId = reader.string();
                    break;
                }
            case 3: {
                    message.loginId = reader.string();
                    break;
                }
            case 4: {
                    if (!(message.roles && message.roles.length))
                        message.roles = [];
                    message.roles.push(reader.string());
                    break;
                }
            case 5: {
                    message.lastLoginEmail = reader.string();
                    break;
                }
            case 6: {
                    message.lastLoginTimestamp = reader.int64();
                    break;
                }
            case 7: {
                    message.profile = $root.space.kenko.proto.UserProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminProviderLoginProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminProviderLoginProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminProviderLoginProto} AdminProviderLoginProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminProviderLoginProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminProviderLoginProto message.
     * @function verify
     * @memberof AdminProviderLoginProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminProviderLoginProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            if (!$util.isString(message.loginId))
                return "loginId: string expected";
        if (message.roles != null && message.hasOwnProperty("roles")) {
            if (!Array.isArray(message.roles))
                return "roles: array expected";
            for (var i = 0; i < message.roles.length; ++i)
                if (!$util.isString(message.roles[i]))
                    return "roles: string[] expected";
        }
        if (message.lastLoginEmail != null && message.hasOwnProperty("lastLoginEmail"))
            if (!$util.isString(message.lastLoginEmail))
                return "lastLoginEmail: string expected";
        if (message.lastLoginTimestamp != null && message.hasOwnProperty("lastLoginTimestamp"))
            if (!$util.isInteger(message.lastLoginTimestamp) && !(message.lastLoginTimestamp && $util.isInteger(message.lastLoginTimestamp.low) && $util.isInteger(message.lastLoginTimestamp.high)))
                return "lastLoginTimestamp: integer|Long expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.space.kenko.proto.UserProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an AdminProviderLoginProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminProviderLoginProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminProviderLoginProto} AdminProviderLoginProto
     */
    AdminProviderLoginProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminProviderLoginProto)
            return object;
        var message = new $root.AdminProviderLoginProto();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.loginId != null)
            message.loginId = String(object.loginId);
        if (object.roles) {
            if (!Array.isArray(object.roles))
                throw TypeError(".AdminProviderLoginProto.roles: array expected");
            message.roles = [];
            for (var i = 0; i < object.roles.length; ++i)
                message.roles[i] = String(object.roles[i]);
        }
        if (object.lastLoginEmail != null)
            message.lastLoginEmail = String(object.lastLoginEmail);
        if (object.lastLoginTimestamp != null)
            if ($util.Long)
                (message.lastLoginTimestamp = $util.Long.fromValue(object.lastLoginTimestamp)).unsigned = false;
            else if (typeof object.lastLoginTimestamp === "string")
                message.lastLoginTimestamp = parseInt(object.lastLoginTimestamp, 10);
            else if (typeof object.lastLoginTimestamp === "number")
                message.lastLoginTimestamp = object.lastLoginTimestamp;
            else if (typeof object.lastLoginTimestamp === "object")
                message.lastLoginTimestamp = new $util.LongBits(object.lastLoginTimestamp.low >>> 0, object.lastLoginTimestamp.high >>> 0).toNumber();
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".AdminProviderLoginProto.profile: object expected");
            message.profile = $root.space.kenko.proto.UserProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an AdminProviderLoginProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminProviderLoginProto
     * @static
     * @param {AdminProviderLoginProto} message AdminProviderLoginProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminProviderLoginProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.roles = [];
        if (options.defaults) {
            object.providerId = "";
            object.businessId = "";
            object.loginId = "";
            object.lastLoginEmail = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.lastLoginTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastLoginTimestamp = options.longs === String ? "0" : 0;
            object.profile = null;
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            object.loginId = message.loginId;
        if (message.roles && message.roles.length) {
            object.roles = [];
            for (var j = 0; j < message.roles.length; ++j)
                object.roles[j] = message.roles[j];
        }
        if (message.lastLoginEmail != null && message.hasOwnProperty("lastLoginEmail"))
            object.lastLoginEmail = message.lastLoginEmail;
        if (message.lastLoginTimestamp != null && message.hasOwnProperty("lastLoginTimestamp"))
            if (typeof message.lastLoginTimestamp === "number")
                object.lastLoginTimestamp = options.longs === String ? String(message.lastLoginTimestamp) : message.lastLoginTimestamp;
            else
                object.lastLoginTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.lastLoginTimestamp) : options.longs === Number ? new $util.LongBits(message.lastLoginTimestamp.low >>> 0, message.lastLoginTimestamp.high >>> 0).toNumber() : message.lastLoginTimestamp;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.space.kenko.proto.UserProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this AdminProviderLoginProto to JSON.
     * @function toJSON
     * @memberof AdminProviderLoginProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminProviderLoginProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminProviderLoginProto
     * @function getTypeUrl
     * @memberof AdminProviderLoginProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminProviderLoginProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminProviderLoginProto";
    };

    return AdminProviderLoginProto;
})();

$root.ProviderReviewInfo = (function() {

    /**
     * Properties of a ProviderReviewInfo.
     * @exports IProviderReviewInfo
     * @interface IProviderReviewInfo
     * @property {IAdminBusinessProto|null} [business] ProviderReviewInfo business
     * @property {IAdminProviderLoginProto|null} [provider] ProviderReviewInfo provider
     */

    /**
     * Constructs a new ProviderReviewInfo.
     * @exports ProviderReviewInfo
     * @classdesc Represents a ProviderReviewInfo.
     * @implements IProviderReviewInfo
     * @constructor
     * @param {IProviderReviewInfo=} [properties] Properties to set
     */
    function ProviderReviewInfo(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderReviewInfo business.
     * @member {IAdminBusinessProto|null|undefined} business
     * @memberof ProviderReviewInfo
     * @instance
     */
    ProviderReviewInfo.prototype.business = null;

    /**
     * ProviderReviewInfo provider.
     * @member {IAdminProviderLoginProto|null|undefined} provider
     * @memberof ProviderReviewInfo
     * @instance
     */
    ProviderReviewInfo.prototype.provider = null;

    /**
     * Creates a new ProviderReviewInfo instance using the specified properties.
     * @function create
     * @memberof ProviderReviewInfo
     * @static
     * @param {IProviderReviewInfo=} [properties] Properties to set
     * @returns {ProviderReviewInfo} ProviderReviewInfo instance
     */
    ProviderReviewInfo.create = function create(properties) {
        return new ProviderReviewInfo(properties);
    };

    /**
     * Encodes the specified ProviderReviewInfo message. Does not implicitly {@link ProviderReviewInfo.verify|verify} messages.
     * @function encode
     * @memberof ProviderReviewInfo
     * @static
     * @param {IProviderReviewInfo} message ProviderReviewInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderReviewInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.business != null && Object.hasOwnProperty.call(message, "business"))
            $root.AdminBusinessProto.encode(message.business, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.AdminProviderLoginProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProviderReviewInfo message, length delimited. Does not implicitly {@link ProviderReviewInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderReviewInfo
     * @static
     * @param {IProviderReviewInfo} message ProviderReviewInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderReviewInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderReviewInfo message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderReviewInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderReviewInfo} ProviderReviewInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderReviewInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderReviewInfo();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.business = $root.AdminBusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.provider = $root.AdminProviderLoginProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProviderReviewInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderReviewInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderReviewInfo} ProviderReviewInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderReviewInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderReviewInfo message.
     * @function verify
     * @memberof ProviderReviewInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderReviewInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.business != null && message.hasOwnProperty("business")) {
            var error = $root.AdminBusinessProto.verify(message.business);
            if (error)
                return "business." + error;
        }
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.AdminProviderLoginProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        return null;
    };

    /**
     * Creates a ProviderReviewInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderReviewInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderReviewInfo} ProviderReviewInfo
     */
    ProviderReviewInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderReviewInfo)
            return object;
        var message = new $root.ProviderReviewInfo();
        if (object.business != null) {
            if (typeof object.business !== "object")
                throw TypeError(".ProviderReviewInfo.business: object expected");
            message.business = $root.AdminBusinessProto.fromObject(object.business);
        }
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".ProviderReviewInfo.provider: object expected");
            message.provider = $root.AdminProviderLoginProto.fromObject(object.provider);
        }
        return message;
    };

    /**
     * Creates a plain object from a ProviderReviewInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderReviewInfo
     * @static
     * @param {ProviderReviewInfo} message ProviderReviewInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderReviewInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.business = null;
            object.provider = null;
        }
        if (message.business != null && message.hasOwnProperty("business"))
            object.business = $root.AdminBusinessProto.toObject(message.business, options);
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.AdminProviderLoginProto.toObject(message.provider, options);
        return object;
    };

    /**
     * Converts this ProviderReviewInfo to JSON.
     * @function toJSON
     * @memberof ProviderReviewInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderReviewInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProviderReviewInfo
     * @function getTypeUrl
     * @memberof ProviderReviewInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProviderReviewInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProviderReviewInfo";
    };

    return ProviderReviewInfo;
})();

$root.GetPendingBusinessApprovalsRequest = (function() {

    /**
     * Properties of a GetPendingBusinessApprovalsRequest.
     * @exports IGetPendingBusinessApprovalsRequest
     * @interface IGetPendingBusinessApprovalsRequest
     * @property {string|null} [startIndex] GetPendingBusinessApprovalsRequest startIndex
     */

    /**
     * Constructs a new GetPendingBusinessApprovalsRequest.
     * @exports GetPendingBusinessApprovalsRequest
     * @classdesc Represents a GetPendingBusinessApprovalsRequest.
     * @implements IGetPendingBusinessApprovalsRequest
     * @constructor
     * @param {IGetPendingBusinessApprovalsRequest=} [properties] Properties to set
     */
    function GetPendingBusinessApprovalsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetPendingBusinessApprovalsRequest startIndex.
     * @member {string} startIndex
     * @memberof GetPendingBusinessApprovalsRequest
     * @instance
     */
    GetPendingBusinessApprovalsRequest.prototype.startIndex = "";

    /**
     * Creates a new GetPendingBusinessApprovalsRequest instance using the specified properties.
     * @function create
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {IGetPendingBusinessApprovalsRequest=} [properties] Properties to set
     * @returns {GetPendingBusinessApprovalsRequest} GetPendingBusinessApprovalsRequest instance
     */
    GetPendingBusinessApprovalsRequest.create = function create(properties) {
        return new GetPendingBusinessApprovalsRequest(properties);
    };

    /**
     * Encodes the specified GetPendingBusinessApprovalsRequest message. Does not implicitly {@link GetPendingBusinessApprovalsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {IGetPendingBusinessApprovalsRequest} message GetPendingBusinessApprovalsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingBusinessApprovalsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.startIndex != null && Object.hasOwnProperty.call(message, "startIndex"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.startIndex);
        return writer;
    };

    /**
     * Encodes the specified GetPendingBusinessApprovalsRequest message, length delimited. Does not implicitly {@link GetPendingBusinessApprovalsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {IGetPendingBusinessApprovalsRequest} message GetPendingBusinessApprovalsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingBusinessApprovalsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetPendingBusinessApprovalsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetPendingBusinessApprovalsRequest} GetPendingBusinessApprovalsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingBusinessApprovalsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetPendingBusinessApprovalsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.startIndex = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetPendingBusinessApprovalsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetPendingBusinessApprovalsRequest} GetPendingBusinessApprovalsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingBusinessApprovalsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetPendingBusinessApprovalsRequest message.
     * @function verify
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetPendingBusinessApprovalsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.startIndex != null && message.hasOwnProperty("startIndex"))
            if (!$util.isString(message.startIndex))
                return "startIndex: string expected";
        return null;
    };

    /**
     * Creates a GetPendingBusinessApprovalsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetPendingBusinessApprovalsRequest} GetPendingBusinessApprovalsRequest
     */
    GetPendingBusinessApprovalsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetPendingBusinessApprovalsRequest)
            return object;
        var message = new $root.GetPendingBusinessApprovalsRequest();
        if (object.startIndex != null)
            message.startIndex = String(object.startIndex);
        return message;
    };

    /**
     * Creates a plain object from a GetPendingBusinessApprovalsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {GetPendingBusinessApprovalsRequest} message GetPendingBusinessApprovalsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetPendingBusinessApprovalsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.startIndex = "";
        if (message.startIndex != null && message.hasOwnProperty("startIndex"))
            object.startIndex = message.startIndex;
        return object;
    };

    /**
     * Converts this GetPendingBusinessApprovalsRequest to JSON.
     * @function toJSON
     * @memberof GetPendingBusinessApprovalsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetPendingBusinessApprovalsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetPendingBusinessApprovalsRequest
     * @function getTypeUrl
     * @memberof GetPendingBusinessApprovalsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetPendingBusinessApprovalsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetPendingBusinessApprovalsRequest";
    };

    return GetPendingBusinessApprovalsRequest;
})();

$root.GetPendingBusinessApprovalsResponse = (function() {

    /**
     * Properties of a GetPendingBusinessApprovalsResponse.
     * @exports IGetPendingBusinessApprovalsResponse
     * @interface IGetPendingBusinessApprovalsResponse
     * @property {boolean|null} [okay] GetPendingBusinessApprovalsResponse okay
     * @property {string|null} [error] GetPendingBusinessApprovalsResponse error
     * @property {Array.<IProviderReviewInfo>|null} [business] GetPendingBusinessApprovalsResponse business
     */

    /**
     * Constructs a new GetPendingBusinessApprovalsResponse.
     * @exports GetPendingBusinessApprovalsResponse
     * @classdesc Represents a GetPendingBusinessApprovalsResponse.
     * @implements IGetPendingBusinessApprovalsResponse
     * @constructor
     * @param {IGetPendingBusinessApprovalsResponse=} [properties] Properties to set
     */
    function GetPendingBusinessApprovalsResponse(properties) {
        this.business = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetPendingBusinessApprovalsResponse okay.
     * @member {boolean} okay
     * @memberof GetPendingBusinessApprovalsResponse
     * @instance
     */
    GetPendingBusinessApprovalsResponse.prototype.okay = false;

    /**
     * GetPendingBusinessApprovalsResponse error.
     * @member {string} error
     * @memberof GetPendingBusinessApprovalsResponse
     * @instance
     */
    GetPendingBusinessApprovalsResponse.prototype.error = "";

    /**
     * GetPendingBusinessApprovalsResponse business.
     * @member {Array.<IProviderReviewInfo>} business
     * @memberof GetPendingBusinessApprovalsResponse
     * @instance
     */
    GetPendingBusinessApprovalsResponse.prototype.business = $util.emptyArray;

    /**
     * Creates a new GetPendingBusinessApprovalsResponse instance using the specified properties.
     * @function create
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {IGetPendingBusinessApprovalsResponse=} [properties] Properties to set
     * @returns {GetPendingBusinessApprovalsResponse} GetPendingBusinessApprovalsResponse instance
     */
    GetPendingBusinessApprovalsResponse.create = function create(properties) {
        return new GetPendingBusinessApprovalsResponse(properties);
    };

    /**
     * Encodes the specified GetPendingBusinessApprovalsResponse message. Does not implicitly {@link GetPendingBusinessApprovalsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {IGetPendingBusinessApprovalsResponse} message GetPendingBusinessApprovalsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingBusinessApprovalsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.business != null && message.business.length)
            for (var i = 0; i < message.business.length; ++i)
                $root.ProviderReviewInfo.encode(message.business[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetPendingBusinessApprovalsResponse message, length delimited. Does not implicitly {@link GetPendingBusinessApprovalsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {IGetPendingBusinessApprovalsResponse} message GetPendingBusinessApprovalsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingBusinessApprovalsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetPendingBusinessApprovalsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetPendingBusinessApprovalsResponse} GetPendingBusinessApprovalsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingBusinessApprovalsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetPendingBusinessApprovalsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.business && message.business.length))
                        message.business = [];
                    message.business.push($root.ProviderReviewInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetPendingBusinessApprovalsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetPendingBusinessApprovalsResponse} GetPendingBusinessApprovalsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingBusinessApprovalsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetPendingBusinessApprovalsResponse message.
     * @function verify
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetPendingBusinessApprovalsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.business != null && message.hasOwnProperty("business")) {
            if (!Array.isArray(message.business))
                return "business: array expected";
            for (var i = 0; i < message.business.length; ++i) {
                var error = $root.ProviderReviewInfo.verify(message.business[i]);
                if (error)
                    return "business." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetPendingBusinessApprovalsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetPendingBusinessApprovalsResponse} GetPendingBusinessApprovalsResponse
     */
    GetPendingBusinessApprovalsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetPendingBusinessApprovalsResponse)
            return object;
        var message = new $root.GetPendingBusinessApprovalsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.error != null)
            message.error = String(object.error);
        if (object.business) {
            if (!Array.isArray(object.business))
                throw TypeError(".GetPendingBusinessApprovalsResponse.business: array expected");
            message.business = [];
            for (var i = 0; i < object.business.length; ++i) {
                if (typeof object.business[i] !== "object")
                    throw TypeError(".GetPendingBusinessApprovalsResponse.business: object expected");
                message.business[i] = $root.ProviderReviewInfo.fromObject(object.business[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetPendingBusinessApprovalsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {GetPendingBusinessApprovalsResponse} message GetPendingBusinessApprovalsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetPendingBusinessApprovalsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.business = [];
        if (options.defaults) {
            object.okay = false;
            object.error = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.business && message.business.length) {
            object.business = [];
            for (var j = 0; j < message.business.length; ++j)
                object.business[j] = $root.ProviderReviewInfo.toObject(message.business[j], options);
        }
        return object;
    };

    /**
     * Converts this GetPendingBusinessApprovalsResponse to JSON.
     * @function toJSON
     * @memberof GetPendingBusinessApprovalsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetPendingBusinessApprovalsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetPendingBusinessApprovalsResponse
     * @function getTypeUrl
     * @memberof GetPendingBusinessApprovalsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetPendingBusinessApprovalsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetPendingBusinessApprovalsResponse";
    };

    return GetPendingBusinessApprovalsResponse;
})();

$root.ApproveBusinessRequest = (function() {

    /**
     * Properties of an ApproveBusinessRequest.
     * @exports IApproveBusinessRequest
     * @interface IApproveBusinessRequest
     * @property {string|null} [businessId] ApproveBusinessRequest businessId
     * @property {space.kenko.proto.ProviderApprovalState|null} [status] ApproveBusinessRequest status
     * @property {string|null} [reviewMessage] ApproveBusinessRequest reviewMessage
     */

    /**
     * Constructs a new ApproveBusinessRequest.
     * @exports ApproveBusinessRequest
     * @classdesc Represents an ApproveBusinessRequest.
     * @implements IApproveBusinessRequest
     * @constructor
     * @param {IApproveBusinessRequest=} [properties] Properties to set
     */
    function ApproveBusinessRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApproveBusinessRequest businessId.
     * @member {string} businessId
     * @memberof ApproveBusinessRequest
     * @instance
     */
    ApproveBusinessRequest.prototype.businessId = "";

    /**
     * ApproveBusinessRequest status.
     * @member {space.kenko.proto.ProviderApprovalState} status
     * @memberof ApproveBusinessRequest
     * @instance
     */
    ApproveBusinessRequest.prototype.status = 0;

    /**
     * ApproveBusinessRequest reviewMessage.
     * @member {string} reviewMessage
     * @memberof ApproveBusinessRequest
     * @instance
     */
    ApproveBusinessRequest.prototype.reviewMessage = "";

    /**
     * Creates a new ApproveBusinessRequest instance using the specified properties.
     * @function create
     * @memberof ApproveBusinessRequest
     * @static
     * @param {IApproveBusinessRequest=} [properties] Properties to set
     * @returns {ApproveBusinessRequest} ApproveBusinessRequest instance
     */
    ApproveBusinessRequest.create = function create(properties) {
        return new ApproveBusinessRequest(properties);
    };

    /**
     * Encodes the specified ApproveBusinessRequest message. Does not implicitly {@link ApproveBusinessRequest.verify|verify} messages.
     * @function encode
     * @memberof ApproveBusinessRequest
     * @static
     * @param {IApproveBusinessRequest} message ApproveBusinessRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApproveBusinessRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
        if (message.reviewMessage != null && Object.hasOwnProperty.call(message, "reviewMessage"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.reviewMessage);
        return writer;
    };

    /**
     * Encodes the specified ApproveBusinessRequest message, length delimited. Does not implicitly {@link ApproveBusinessRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApproveBusinessRequest
     * @static
     * @param {IApproveBusinessRequest} message ApproveBusinessRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApproveBusinessRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApproveBusinessRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ApproveBusinessRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApproveBusinessRequest} ApproveBusinessRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApproveBusinessRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApproveBusinessRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessId = reader.string();
                    break;
                }
            case 2: {
                    message.status = reader.int32();
                    break;
                }
            case 3: {
                    message.reviewMessage = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ApproveBusinessRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApproveBusinessRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApproveBusinessRequest} ApproveBusinessRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApproveBusinessRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApproveBusinessRequest message.
     * @function verify
     * @memberof ApproveBusinessRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApproveBusinessRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 10:
            case 11:
            case 20:
            case 30:
            case 40:
                break;
            }
        if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
            if (!$util.isString(message.reviewMessage))
                return "reviewMessage: string expected";
        return null;
    };

    /**
     * Creates an ApproveBusinessRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApproveBusinessRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApproveBusinessRequest} ApproveBusinessRequest
     */
    ApproveBusinessRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ApproveBusinessRequest)
            return object;
        var message = new $root.ApproveBusinessRequest();
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "APPROVAL_STATE_UNKNOWN":
        case 0:
            message.status = 0;
            break;
        case "NEW_PROVIDER_MANUAL_APPROVE":
        case 10:
            message.status = 10;
            break;
        case "NEW_PROVIDER_AUTO_APPROVE":
        case 11:
            message.status = 11;
            break;
        case "AWAITING_REVIEW":
        case 20:
            message.status = 20;
            break;
        case "PROVIDER_APPROVED":
        case 30:
            message.status = 30;
            break;
        case "PROVIDER_REJECTED":
        case 40:
            message.status = 40;
            break;
        }
        if (object.reviewMessage != null)
            message.reviewMessage = String(object.reviewMessage);
        return message;
    };

    /**
     * Creates a plain object from an ApproveBusinessRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApproveBusinessRequest
     * @static
     * @param {ApproveBusinessRequest} message ApproveBusinessRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApproveBusinessRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.businessId = "";
            object.status = options.enums === String ? "APPROVAL_STATE_UNKNOWN" : 0;
            object.reviewMessage = "";
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.space.kenko.proto.ProviderApprovalState[message.status] === undefined ? message.status : $root.space.kenko.proto.ProviderApprovalState[message.status] : message.status;
        if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
            object.reviewMessage = message.reviewMessage;
        return object;
    };

    /**
     * Converts this ApproveBusinessRequest to JSON.
     * @function toJSON
     * @memberof ApproveBusinessRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApproveBusinessRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ApproveBusinessRequest
     * @function getTypeUrl
     * @memberof ApproveBusinessRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ApproveBusinessRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ApproveBusinessRequest";
    };

    return ApproveBusinessRequest;
})();

$root.ApproveBusinessResponse = (function() {

    /**
     * Properties of an ApproveBusinessResponse.
     * @exports IApproveBusinessResponse
     * @interface IApproveBusinessResponse
     * @property {boolean|null} [okay] ApproveBusinessResponse okay
     * @property {IAdminBusinessProto|null} [updated] ApproveBusinessResponse updated
     */

    /**
     * Constructs a new ApproveBusinessResponse.
     * @exports ApproveBusinessResponse
     * @classdesc Represents an ApproveBusinessResponse.
     * @implements IApproveBusinessResponse
     * @constructor
     * @param {IApproveBusinessResponse=} [properties] Properties to set
     */
    function ApproveBusinessResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApproveBusinessResponse okay.
     * @member {boolean} okay
     * @memberof ApproveBusinessResponse
     * @instance
     */
    ApproveBusinessResponse.prototype.okay = false;

    /**
     * ApproveBusinessResponse updated.
     * @member {IAdminBusinessProto|null|undefined} updated
     * @memberof ApproveBusinessResponse
     * @instance
     */
    ApproveBusinessResponse.prototype.updated = null;

    /**
     * Creates a new ApproveBusinessResponse instance using the specified properties.
     * @function create
     * @memberof ApproveBusinessResponse
     * @static
     * @param {IApproveBusinessResponse=} [properties] Properties to set
     * @returns {ApproveBusinessResponse} ApproveBusinessResponse instance
     */
    ApproveBusinessResponse.create = function create(properties) {
        return new ApproveBusinessResponse(properties);
    };

    /**
     * Encodes the specified ApproveBusinessResponse message. Does not implicitly {@link ApproveBusinessResponse.verify|verify} messages.
     * @function encode
     * @memberof ApproveBusinessResponse
     * @static
     * @param {IApproveBusinessResponse} message ApproveBusinessResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApproveBusinessResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
            $root.AdminBusinessProto.encode(message.updated, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ApproveBusinessResponse message, length delimited. Does not implicitly {@link ApproveBusinessResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApproveBusinessResponse
     * @static
     * @param {IApproveBusinessResponse} message ApproveBusinessResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApproveBusinessResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApproveBusinessResponse message from the specified reader or buffer.
     * @function decode
     * @memberof ApproveBusinessResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApproveBusinessResponse} ApproveBusinessResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApproveBusinessResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApproveBusinessResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.updated = $root.AdminBusinessProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ApproveBusinessResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApproveBusinessResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApproveBusinessResponse} ApproveBusinessResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApproveBusinessResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApproveBusinessResponse message.
     * @function verify
     * @memberof ApproveBusinessResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApproveBusinessResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.updated != null && message.hasOwnProperty("updated")) {
            var error = $root.AdminBusinessProto.verify(message.updated);
            if (error)
                return "updated." + error;
        }
        return null;
    };

    /**
     * Creates an ApproveBusinessResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApproveBusinessResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApproveBusinessResponse} ApproveBusinessResponse
     */
    ApproveBusinessResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.ApproveBusinessResponse)
            return object;
        var message = new $root.ApproveBusinessResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.updated != null) {
            if (typeof object.updated !== "object")
                throw TypeError(".ApproveBusinessResponse.updated: object expected");
            message.updated = $root.AdminBusinessProto.fromObject(object.updated);
        }
        return message;
    };

    /**
     * Creates a plain object from an ApproveBusinessResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApproveBusinessResponse
     * @static
     * @param {ApproveBusinessResponse} message ApproveBusinessResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApproveBusinessResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.updated = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.updated != null && message.hasOwnProperty("updated"))
            object.updated = $root.AdminBusinessProto.toObject(message.updated, options);
        return object;
    };

    /**
     * Converts this ApproveBusinessResponse to JSON.
     * @function toJSON
     * @memberof ApproveBusinessResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApproveBusinessResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ApproveBusinessResponse
     * @function getTypeUrl
     * @memberof ApproveBusinessResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ApproveBusinessResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ApproveBusinessResponse";
    };

    return ApproveBusinessResponse;
})();

$root.GetPendingWebsiteReviewsRequest = (function() {

    /**
     * Properties of a GetPendingWebsiteReviewsRequest.
     * @exports IGetPendingWebsiteReviewsRequest
     * @interface IGetPendingWebsiteReviewsRequest
     * @property {string|null} [startIndex] GetPendingWebsiteReviewsRequest startIndex
     */

    /**
     * Constructs a new GetPendingWebsiteReviewsRequest.
     * @exports GetPendingWebsiteReviewsRequest
     * @classdesc Represents a GetPendingWebsiteReviewsRequest.
     * @implements IGetPendingWebsiteReviewsRequest
     * @constructor
     * @param {IGetPendingWebsiteReviewsRequest=} [properties] Properties to set
     */
    function GetPendingWebsiteReviewsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetPendingWebsiteReviewsRequest startIndex.
     * @member {string} startIndex
     * @memberof GetPendingWebsiteReviewsRequest
     * @instance
     */
    GetPendingWebsiteReviewsRequest.prototype.startIndex = "";

    /**
     * Creates a new GetPendingWebsiteReviewsRequest instance using the specified properties.
     * @function create
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {IGetPendingWebsiteReviewsRequest=} [properties] Properties to set
     * @returns {GetPendingWebsiteReviewsRequest} GetPendingWebsiteReviewsRequest instance
     */
    GetPendingWebsiteReviewsRequest.create = function create(properties) {
        return new GetPendingWebsiteReviewsRequest(properties);
    };

    /**
     * Encodes the specified GetPendingWebsiteReviewsRequest message. Does not implicitly {@link GetPendingWebsiteReviewsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {IGetPendingWebsiteReviewsRequest} message GetPendingWebsiteReviewsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingWebsiteReviewsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.startIndex != null && Object.hasOwnProperty.call(message, "startIndex"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.startIndex);
        return writer;
    };

    /**
     * Encodes the specified GetPendingWebsiteReviewsRequest message, length delimited. Does not implicitly {@link GetPendingWebsiteReviewsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {IGetPendingWebsiteReviewsRequest} message GetPendingWebsiteReviewsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingWebsiteReviewsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetPendingWebsiteReviewsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetPendingWebsiteReviewsRequest} GetPendingWebsiteReviewsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingWebsiteReviewsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetPendingWebsiteReviewsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.startIndex = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetPendingWebsiteReviewsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetPendingWebsiteReviewsRequest} GetPendingWebsiteReviewsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingWebsiteReviewsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetPendingWebsiteReviewsRequest message.
     * @function verify
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetPendingWebsiteReviewsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.startIndex != null && message.hasOwnProperty("startIndex"))
            if (!$util.isString(message.startIndex))
                return "startIndex: string expected";
        return null;
    };

    /**
     * Creates a GetPendingWebsiteReviewsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetPendingWebsiteReviewsRequest} GetPendingWebsiteReviewsRequest
     */
    GetPendingWebsiteReviewsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetPendingWebsiteReviewsRequest)
            return object;
        var message = new $root.GetPendingWebsiteReviewsRequest();
        if (object.startIndex != null)
            message.startIndex = String(object.startIndex);
        return message;
    };

    /**
     * Creates a plain object from a GetPendingWebsiteReviewsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {GetPendingWebsiteReviewsRequest} message GetPendingWebsiteReviewsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetPendingWebsiteReviewsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.startIndex = "";
        if (message.startIndex != null && message.hasOwnProperty("startIndex"))
            object.startIndex = message.startIndex;
        return object;
    };

    /**
     * Converts this GetPendingWebsiteReviewsRequest to JSON.
     * @function toJSON
     * @memberof GetPendingWebsiteReviewsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetPendingWebsiteReviewsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetPendingWebsiteReviewsRequest
     * @function getTypeUrl
     * @memberof GetPendingWebsiteReviewsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetPendingWebsiteReviewsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetPendingWebsiteReviewsRequest";
    };

    return GetPendingWebsiteReviewsRequest;
})();

$root.GetPendingWebsiteReviewsResponse = (function() {

    /**
     * Properties of a GetPendingWebsiteReviewsResponse.
     * @exports IGetPendingWebsiteReviewsResponse
     * @interface IGetPendingWebsiteReviewsResponse
     * @property {boolean|null} [okay] GetPendingWebsiteReviewsResponse okay
     * @property {string|null} [error] GetPendingWebsiteReviewsResponse error
     * @property {Array.<IAdminBusinessProfileProto>|null} [profiles] GetPendingWebsiteReviewsResponse profiles
     */

    /**
     * Constructs a new GetPendingWebsiteReviewsResponse.
     * @exports GetPendingWebsiteReviewsResponse
     * @classdesc Represents a GetPendingWebsiteReviewsResponse.
     * @implements IGetPendingWebsiteReviewsResponse
     * @constructor
     * @param {IGetPendingWebsiteReviewsResponse=} [properties] Properties to set
     */
    function GetPendingWebsiteReviewsResponse(properties) {
        this.profiles = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetPendingWebsiteReviewsResponse okay.
     * @member {boolean} okay
     * @memberof GetPendingWebsiteReviewsResponse
     * @instance
     */
    GetPendingWebsiteReviewsResponse.prototype.okay = false;

    /**
     * GetPendingWebsiteReviewsResponse error.
     * @member {string} error
     * @memberof GetPendingWebsiteReviewsResponse
     * @instance
     */
    GetPendingWebsiteReviewsResponse.prototype.error = "";

    /**
     * GetPendingWebsiteReviewsResponse profiles.
     * @member {Array.<IAdminBusinessProfileProto>} profiles
     * @memberof GetPendingWebsiteReviewsResponse
     * @instance
     */
    GetPendingWebsiteReviewsResponse.prototype.profiles = $util.emptyArray;

    /**
     * Creates a new GetPendingWebsiteReviewsResponse instance using the specified properties.
     * @function create
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {IGetPendingWebsiteReviewsResponse=} [properties] Properties to set
     * @returns {GetPendingWebsiteReviewsResponse} GetPendingWebsiteReviewsResponse instance
     */
    GetPendingWebsiteReviewsResponse.create = function create(properties) {
        return new GetPendingWebsiteReviewsResponse(properties);
    };

    /**
     * Encodes the specified GetPendingWebsiteReviewsResponse message. Does not implicitly {@link GetPendingWebsiteReviewsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {IGetPendingWebsiteReviewsResponse} message GetPendingWebsiteReviewsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingWebsiteReviewsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.profiles != null && message.profiles.length)
            for (var i = 0; i < message.profiles.length; ++i)
                $root.AdminBusinessProfileProto.encode(message.profiles[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetPendingWebsiteReviewsResponse message, length delimited. Does not implicitly {@link GetPendingWebsiteReviewsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {IGetPendingWebsiteReviewsResponse} message GetPendingWebsiteReviewsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPendingWebsiteReviewsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetPendingWebsiteReviewsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetPendingWebsiteReviewsResponse} GetPendingWebsiteReviewsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingWebsiteReviewsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetPendingWebsiteReviewsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.profiles && message.profiles.length))
                        message.profiles = [];
                    message.profiles.push($root.AdminBusinessProfileProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetPendingWebsiteReviewsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetPendingWebsiteReviewsResponse} GetPendingWebsiteReviewsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPendingWebsiteReviewsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetPendingWebsiteReviewsResponse message.
     * @function verify
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetPendingWebsiteReviewsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.profiles != null && message.hasOwnProperty("profiles")) {
            if (!Array.isArray(message.profiles))
                return "profiles: array expected";
            for (var i = 0; i < message.profiles.length; ++i) {
                var error = $root.AdminBusinessProfileProto.verify(message.profiles[i]);
                if (error)
                    return "profiles." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetPendingWebsiteReviewsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetPendingWebsiteReviewsResponse} GetPendingWebsiteReviewsResponse
     */
    GetPendingWebsiteReviewsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetPendingWebsiteReviewsResponse)
            return object;
        var message = new $root.GetPendingWebsiteReviewsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.error != null)
            message.error = String(object.error);
        if (object.profiles) {
            if (!Array.isArray(object.profiles))
                throw TypeError(".GetPendingWebsiteReviewsResponse.profiles: array expected");
            message.profiles = [];
            for (var i = 0; i < object.profiles.length; ++i) {
                if (typeof object.profiles[i] !== "object")
                    throw TypeError(".GetPendingWebsiteReviewsResponse.profiles: object expected");
                message.profiles[i] = $root.AdminBusinessProfileProto.fromObject(object.profiles[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetPendingWebsiteReviewsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {GetPendingWebsiteReviewsResponse} message GetPendingWebsiteReviewsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetPendingWebsiteReviewsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.profiles = [];
        if (options.defaults) {
            object.okay = false;
            object.error = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.profiles && message.profiles.length) {
            object.profiles = [];
            for (var j = 0; j < message.profiles.length; ++j)
                object.profiles[j] = $root.AdminBusinessProfileProto.toObject(message.profiles[j], options);
        }
        return object;
    };

    /**
     * Converts this GetPendingWebsiteReviewsResponse to JSON.
     * @function toJSON
     * @memberof GetPendingWebsiteReviewsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetPendingWebsiteReviewsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetPendingWebsiteReviewsResponse
     * @function getTypeUrl
     * @memberof GetPendingWebsiteReviewsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetPendingWebsiteReviewsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetPendingWebsiteReviewsResponse";
    };

    return GetPendingWebsiteReviewsResponse;
})();

$root.FinishWebsiteReviewRequest = (function() {

    /**
     * Properties of a FinishWebsiteReviewRequest.
     * @exports IFinishWebsiteReviewRequest
     * @interface IFinishWebsiteReviewRequest
     * @property {string|null} [businessId] FinishWebsiteReviewRequest businessId
     * @property {string|null} [version] FinishWebsiteReviewRequest version
     * @property {space.kenko.proto.ApprovalStatus|null} [status] FinishWebsiteReviewRequest status
     * @property {string|null} [reviewMessage] FinishWebsiteReviewRequest reviewMessage
     */

    /**
     * Constructs a new FinishWebsiteReviewRequest.
     * @exports FinishWebsiteReviewRequest
     * @classdesc Represents a FinishWebsiteReviewRequest.
     * @implements IFinishWebsiteReviewRequest
     * @constructor
     * @param {IFinishWebsiteReviewRequest=} [properties] Properties to set
     */
    function FinishWebsiteReviewRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FinishWebsiteReviewRequest businessId.
     * @member {string} businessId
     * @memberof FinishWebsiteReviewRequest
     * @instance
     */
    FinishWebsiteReviewRequest.prototype.businessId = "";

    /**
     * FinishWebsiteReviewRequest version.
     * @member {string} version
     * @memberof FinishWebsiteReviewRequest
     * @instance
     */
    FinishWebsiteReviewRequest.prototype.version = "";

    /**
     * FinishWebsiteReviewRequest status.
     * @member {space.kenko.proto.ApprovalStatus} status
     * @memberof FinishWebsiteReviewRequest
     * @instance
     */
    FinishWebsiteReviewRequest.prototype.status = 0;

    /**
     * FinishWebsiteReviewRequest reviewMessage.
     * @member {string} reviewMessage
     * @memberof FinishWebsiteReviewRequest
     * @instance
     */
    FinishWebsiteReviewRequest.prototype.reviewMessage = "";

    /**
     * Creates a new FinishWebsiteReviewRequest instance using the specified properties.
     * @function create
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {IFinishWebsiteReviewRequest=} [properties] Properties to set
     * @returns {FinishWebsiteReviewRequest} FinishWebsiteReviewRequest instance
     */
    FinishWebsiteReviewRequest.create = function create(properties) {
        return new FinishWebsiteReviewRequest(properties);
    };

    /**
     * Encodes the specified FinishWebsiteReviewRequest message. Does not implicitly {@link FinishWebsiteReviewRequest.verify|verify} messages.
     * @function encode
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {IFinishWebsiteReviewRequest} message FinishWebsiteReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FinishWebsiteReviewRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
        if (message.reviewMessage != null && Object.hasOwnProperty.call(message, "reviewMessage"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.reviewMessage);
        return writer;
    };

    /**
     * Encodes the specified FinishWebsiteReviewRequest message, length delimited. Does not implicitly {@link FinishWebsiteReviewRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {IFinishWebsiteReviewRequest} message FinishWebsiteReviewRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FinishWebsiteReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FinishWebsiteReviewRequest message from the specified reader or buffer.
     * @function decode
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FinishWebsiteReviewRequest} FinishWebsiteReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FinishWebsiteReviewRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.FinishWebsiteReviewRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessId = reader.string();
                    break;
                }
            case 2: {
                    message.version = reader.string();
                    break;
                }
            case 3: {
                    message.status = reader.int32();
                    break;
                }
            case 4: {
                    message.reviewMessage = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FinishWebsiteReviewRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FinishWebsiteReviewRequest} FinishWebsiteReviewRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FinishWebsiteReviewRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FinishWebsiteReviewRequest message.
     * @function verify
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FinishWebsiteReviewRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isString(message.version))
                return "version: string expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
            if (!$util.isString(message.reviewMessage))
                return "reviewMessage: string expected";
        return null;
    };

    /**
     * Creates a FinishWebsiteReviewRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FinishWebsiteReviewRequest} FinishWebsiteReviewRequest
     */
    FinishWebsiteReviewRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.FinishWebsiteReviewRequest)
            return object;
        var message = new $root.FinishWebsiteReviewRequest();
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.version != null)
            message.version = String(object.version);
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "UNKNOWN":
        case 0:
            message.status = 0;
            break;
        case "IN_REVIEW":
        case 1:
            message.status = 1;
            break;
        case "APPROVED":
        case 2:
            message.status = 2;
            break;
        case "REJECTED":
        case 3:
            message.status = 3;
            break;
        }
        if (object.reviewMessage != null)
            message.reviewMessage = String(object.reviewMessage);
        return message;
    };

    /**
     * Creates a plain object from a FinishWebsiteReviewRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {FinishWebsiteReviewRequest} message FinishWebsiteReviewRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FinishWebsiteReviewRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.businessId = "";
            object.version = "";
            object.status = options.enums === String ? "UNKNOWN" : 0;
            object.reviewMessage = "";
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.space.kenko.proto.ApprovalStatus[message.status] === undefined ? message.status : $root.space.kenko.proto.ApprovalStatus[message.status] : message.status;
        if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
            object.reviewMessage = message.reviewMessage;
        return object;
    };

    /**
     * Converts this FinishWebsiteReviewRequest to JSON.
     * @function toJSON
     * @memberof FinishWebsiteReviewRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FinishWebsiteReviewRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FinishWebsiteReviewRequest
     * @function getTypeUrl
     * @memberof FinishWebsiteReviewRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FinishWebsiteReviewRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FinishWebsiteReviewRequest";
    };

    return FinishWebsiteReviewRequest;
})();

$root.FinishWebsiteReviewResponse = (function() {

    /**
     * Properties of a FinishWebsiteReviewResponse.
     * @exports IFinishWebsiteReviewResponse
     * @interface IFinishWebsiteReviewResponse
     * @property {boolean|null} [okay] FinishWebsiteReviewResponse okay
     * @property {IAdminBusinessProfileProto|null} [updated] FinishWebsiteReviewResponse updated
     */

    /**
     * Constructs a new FinishWebsiteReviewResponse.
     * @exports FinishWebsiteReviewResponse
     * @classdesc Represents a FinishWebsiteReviewResponse.
     * @implements IFinishWebsiteReviewResponse
     * @constructor
     * @param {IFinishWebsiteReviewResponse=} [properties] Properties to set
     */
    function FinishWebsiteReviewResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FinishWebsiteReviewResponse okay.
     * @member {boolean} okay
     * @memberof FinishWebsiteReviewResponse
     * @instance
     */
    FinishWebsiteReviewResponse.prototype.okay = false;

    /**
     * FinishWebsiteReviewResponse updated.
     * @member {IAdminBusinessProfileProto|null|undefined} updated
     * @memberof FinishWebsiteReviewResponse
     * @instance
     */
    FinishWebsiteReviewResponse.prototype.updated = null;

    /**
     * Creates a new FinishWebsiteReviewResponse instance using the specified properties.
     * @function create
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {IFinishWebsiteReviewResponse=} [properties] Properties to set
     * @returns {FinishWebsiteReviewResponse} FinishWebsiteReviewResponse instance
     */
    FinishWebsiteReviewResponse.create = function create(properties) {
        return new FinishWebsiteReviewResponse(properties);
    };

    /**
     * Encodes the specified FinishWebsiteReviewResponse message. Does not implicitly {@link FinishWebsiteReviewResponse.verify|verify} messages.
     * @function encode
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {IFinishWebsiteReviewResponse} message FinishWebsiteReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FinishWebsiteReviewResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
            $root.AdminBusinessProfileProto.encode(message.updated, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified FinishWebsiteReviewResponse message, length delimited. Does not implicitly {@link FinishWebsiteReviewResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {IFinishWebsiteReviewResponse} message FinishWebsiteReviewResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FinishWebsiteReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FinishWebsiteReviewResponse message from the specified reader or buffer.
     * @function decode
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FinishWebsiteReviewResponse} FinishWebsiteReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FinishWebsiteReviewResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.FinishWebsiteReviewResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.updated = $root.AdminBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FinishWebsiteReviewResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FinishWebsiteReviewResponse} FinishWebsiteReviewResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FinishWebsiteReviewResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FinishWebsiteReviewResponse message.
     * @function verify
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FinishWebsiteReviewResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.updated != null && message.hasOwnProperty("updated")) {
            var error = $root.AdminBusinessProfileProto.verify(message.updated);
            if (error)
                return "updated." + error;
        }
        return null;
    };

    /**
     * Creates a FinishWebsiteReviewResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FinishWebsiteReviewResponse} FinishWebsiteReviewResponse
     */
    FinishWebsiteReviewResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FinishWebsiteReviewResponse)
            return object;
        var message = new $root.FinishWebsiteReviewResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.updated != null) {
            if (typeof object.updated !== "object")
                throw TypeError(".FinishWebsiteReviewResponse.updated: object expected");
            message.updated = $root.AdminBusinessProfileProto.fromObject(object.updated);
        }
        return message;
    };

    /**
     * Creates a plain object from a FinishWebsiteReviewResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {FinishWebsiteReviewResponse} message FinishWebsiteReviewResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FinishWebsiteReviewResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.updated = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.updated != null && message.hasOwnProperty("updated"))
            object.updated = $root.AdminBusinessProfileProto.toObject(message.updated, options);
        return object;
    };

    /**
     * Converts this FinishWebsiteReviewResponse to JSON.
     * @function toJSON
     * @memberof FinishWebsiteReviewResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FinishWebsiteReviewResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FinishWebsiteReviewResponse
     * @function getTypeUrl
     * @memberof FinishWebsiteReviewResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FinishWebsiteReviewResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FinishWebsiteReviewResponse";
    };

    return FinishWebsiteReviewResponse;
})();

$root.space = (function() {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    var space = {};

    space.kenko = (function() {

        /**
         * Namespace kenko.
         * @memberof space
         * @namespace
         */
        var kenko = {};

        kenko.proto = (function() {

            /**
             * Namespace proto.
             * @memberof space.kenko
             * @namespace
             */
            var proto = {};

            proto.LocalTimeProto = (function() {

                /**
                 * Properties of a LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalTimeProto
                 * @property {number|null} [hour] LocalTimeProto hour
                 * @property {number|null} [minute] LocalTimeProto minute
                 */

                /**
                 * Constructs a new LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalTimeProto.
                 * @implements ILocalTimeProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 */
                function LocalTimeProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalTimeProto hour.
                 * @member {number} hour
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.hour = 0;

                /**
                 * LocalTimeProto minute.
                 * @member {number} minute
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.minute = 0;

                /**
                 * Creates a new LocalTimeProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto instance
                 */
                LocalTimeProto.create = function create(properties) {
                    return new LocalTimeProto(properties);
                };

                /**
                 * Encodes the specified LocalTimeProto message. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hour);
                    if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minute);
                    return writer;
                };

                /**
                 * Encodes the specified LocalTimeProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalTimeProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hour = reader.int32();
                                break;
                            }
                        case 2: {
                                message.minute = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalTimeProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalTimeProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        if (!$util.isInteger(message.hour))
                            return "hour: integer expected";
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        if (!$util.isInteger(message.minute))
                            return "minute: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalTimeProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 */
                LocalTimeProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalTimeProto)
                        return object;
                    var message = new $root.space.kenko.proto.LocalTimeProto();
                    if (object.hour != null)
                        message.hour = object.hour | 0;
                    if (object.minute != null)
                        message.minute = object.minute | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalTimeProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.LocalTimeProto} message LocalTimeProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalTimeProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.hour = 0;
                        object.minute = 0;
                    }
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        object.hour = message.hour;
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        object.minute = message.minute;
                    return object;
                };

                /**
                 * Converts this LocalTimeProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalTimeProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalTimeProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalTimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalTimeProto";
                };

                return LocalTimeProto;
            })();

            proto.LocalDateProto = (function() {

                /**
                 * Properties of a LocalDateProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalDateProto
                 * @property {number|null} [year] LocalDateProto year
                 * @property {number|null} [month] LocalDateProto month
                 * @property {number|null} [date] LocalDateProto date
                 */

                /**
                 * Constructs a new LocalDateProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalDateProto.
                 * @implements ILocalDateProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalDateProto=} [properties] Properties to set
                 */
                function LocalDateProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalDateProto year.
                 * @member {number} year
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.year = 0;

                /**
                 * LocalDateProto month.
                 * @member {number} month
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.month = 0;

                /**
                 * LocalDateProto date.
                 * @member {number} date
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.date = 0;

                /**
                 * Creates a new LocalDateProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto instance
                 */
                LocalDateProto.create = function create(properties) {
                    return new LocalDateProto(properties);
                };

                /**
                 * Encodes the specified LocalDateProto message. Does not implicitly {@link space.kenko.proto.LocalDateProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto} message LocalDateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalDateProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.date);
                    return writer;
                };

                /**
                 * Encodes the specified LocalDateProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalDateProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto} message LocalDateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalDateProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalDateProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalDateProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalDateProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.year = reader.int32();
                                break;
                            }
                        case 2: {
                                message.month = reader.int32();
                                break;
                            }
                        case 3: {
                                message.date = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalDateProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalDateProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalDateProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalDateProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isInteger(message.month))
                            return "month: integer expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isInteger(message.date))
                            return "date: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalDateProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 */
                LocalDateProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalDateProto)
                        return object;
                    var message = new $root.space.kenko.proto.LocalDateProto();
                    if (object.year != null)
                        message.year = object.year | 0;
                    if (object.month != null)
                        message.month = object.month | 0;
                    if (object.date != null)
                        message.date = object.date | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalDateProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.LocalDateProto} message LocalDateProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalDateProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.year = 0;
                        object.month = 0;
                        object.date = 0;
                    }
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    return object;
                };

                /**
                 * Converts this LocalDateProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalDateProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalDateProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalDateProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalDateProto";
                };

                return LocalDateProto;
            })();

            proto.PriceProto = (function() {

                /**
                 * Properties of a PriceProto.
                 * @memberof space.kenko.proto
                 * @interface IPriceProto
                 * @property {number|null} [price] PriceProto price
                 * @property {string|null} [currencyCode] PriceProto currencyCode
                 */

                /**
                 * Constructs a new PriceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PriceProto.
                 * @implements IPriceProto
                 * @constructor
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 */
                function PriceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PriceProto price.
                 * @member {number} price
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.price = 0;

                /**
                 * PriceProto currencyCode.
                 * @member {string} currencyCode
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.currencyCode = "";

                /**
                 * Creates a new PriceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PriceProto} PriceProto instance
                 */
                PriceProto.create = function create(properties) {
                    return new PriceProto(properties);
                };

                /**
                 * Encodes the specified PriceProto message. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.price);
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                    return writer;
                };

                /**
                 * Encodes the specified PriceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PriceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.price = reader.int32();
                                break;
                            }
                        case 2: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PriceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PriceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (!$util.isInteger(message.price))
                            return "price: integer expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    return null;
                };

                /**
                 * Creates a PriceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 */
                PriceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PriceProto)
                        return object;
                    var message = new $root.space.kenko.proto.PriceProto();
                    if (object.price != null)
                        message.price = object.price | 0;
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    return message;
                };

                /**
                 * Creates a plain object from a PriceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.PriceProto} message PriceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.price = 0;
                        object.currencyCode = "";
                    }
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = message.price;
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    return object;
                };

                /**
                 * Converts this PriceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PriceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PriceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PriceProto";
                };

                return PriceProto;
            })();

            proto.ErrorProto = (function() {

                /**
                 * Properties of an ErrorProto.
                 * @memberof space.kenko.proto
                 * @interface IErrorProto
                 * @property {string|null} [path] ErrorProto path
                 * @property {string|null} [error] ErrorProto error
                 */

                /**
                 * Constructs a new ErrorProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an ErrorProto.
                 * @implements IErrorProto
                 * @constructor
                 * @param {space.kenko.proto.IErrorProto=} [properties] Properties to set
                 */
                function ErrorProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ErrorProto path.
                 * @member {string} path
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 */
                ErrorProto.prototype.path = "";

                /**
                 * ErrorProto error.
                 * @member {string} error
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 */
                ErrorProto.prototype.error = "";

                /**
                 * Creates a new ErrorProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto instance
                 */
                ErrorProto.create = function create(properties) {
                    return new ErrorProto(properties);
                };

                /**
                 * Encodes the specified ErrorProto message. Does not implicitly {@link space.kenko.proto.ErrorProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto} message ErrorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ErrorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
                    return writer;
                };

                /**
                 * Encodes the specified ErrorProto message, length delimited. Does not implicitly {@link space.kenko.proto.ErrorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto} message ErrorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ErrorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ErrorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ErrorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ErrorProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.path = reader.string();
                                break;
                            }
                        case 2: {
                                message.error = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ErrorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ErrorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ErrorProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ErrorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path"))
                        if (!$util.isString(message.path))
                            return "path: string expected";
                    if (message.error != null && message.hasOwnProperty("error"))
                        if (!$util.isString(message.error))
                            return "error: string expected";
                    return null;
                };

                /**
                 * Creates an ErrorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 */
                ErrorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ErrorProto)
                        return object;
                    var message = new $root.space.kenko.proto.ErrorProto();
                    if (object.path != null)
                        message.path = String(object.path);
                    if (object.error != null)
                        message.error = String(object.error);
                    return message;
                };

                /**
                 * Creates a plain object from an ErrorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.ErrorProto} message ErrorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ErrorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.path = "";
                        object.error = "";
                    }
                    if (message.path != null && message.hasOwnProperty("path"))
                        object.path = message.path;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = message.error;
                    return object;
                };

                /**
                 * Converts this ErrorProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ErrorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ErrorProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ErrorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ErrorProto";
                };

                return ErrorProto;
            })();

            proto.BusinessProto = (function() {

                /**
                 * Properties of a BusinessProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessProto
                 * @property {string|null} [businessName] BusinessProto businessName
                 * @property {string|null} [businessDescription] BusinessProto businessDescription
                 * @property {string|null} [websiteUrl] BusinessProto websiteUrl
                 * @property {string|null} [phoneNumber] BusinessProto phoneNumber
                 * @property {string|null} [email] BusinessProto email
                 * @property {Array.<string>|null} [imageUrl] BusinessProto imageUrl
                 * @property {string|null} [shortUrl] BusinessProto shortUrl
                 * @property {space.kenko.proto.IVirtualAppointmentInfo|null} [virtualAppointmentInfo] BusinessProto virtualAppointmentInfo
                 */

                /**
                 * Constructs a new BusinessProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessProto.
                 * @implements IBusinessProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 */
                function BusinessProto(properties) {
                    this.imageUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessProto businessName.
                 * @member {string} businessName
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessName = "";

                /**
                 * BusinessProto businessDescription.
                 * @member {string} businessDescription
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessDescription = "";

                /**
                 * BusinessProto websiteUrl.
                 * @member {string} websiteUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.websiteUrl = "";

                /**
                 * BusinessProto phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.phoneNumber = "";

                /**
                 * BusinessProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.email = "";

                /**
                 * BusinessProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * BusinessProto shortUrl.
                 * @member {string} shortUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.shortUrl = "";

                /**
                 * BusinessProto virtualAppointmentInfo.
                 * @member {space.kenko.proto.IVirtualAppointmentInfo|null|undefined} virtualAppointmentInfo
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.virtualAppointmentInfo = null;

                /**
                 * Creates a new BusinessProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto instance
                 */
                BusinessProto.create = function create(properties) {
                    return new BusinessProto(properties);
                };

                /**
                 * Encodes the specified BusinessProto message. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.businessDescription != null && Object.hasOwnProperty.call(message, "businessDescription"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessDescription);
                    if (message.websiteUrl != null && Object.hasOwnProperty.call(message, "websiteUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.websiteUrl);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phoneNumber);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.imageUrl[i]);
                    if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.shortUrl);
                    if (message.virtualAppointmentInfo != null && Object.hasOwnProperty.call(message, "virtualAppointmentInfo"))
                        $root.space.kenko.proto.VirtualAppointmentInfo.encode(message.virtualAppointmentInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified BusinessProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessDescription = reader.string();
                                break;
                            }
                        case 3: {
                                message.websiteUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.shortUrl = reader.string();
                                break;
                            }
                        case 8: {
                                message.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        if (!$util.isString(message.businessDescription))
                            return "businessDescription: string expected";
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        if (!$util.isString(message.websiteUrl))
                            return "websiteUrl: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        if (!$util.isString(message.shortUrl))
                            return "shortUrl: string expected";
                    if (message.virtualAppointmentInfo != null && message.hasOwnProperty("virtualAppointmentInfo")) {
                        var error = $root.space.kenko.proto.VirtualAppointmentInfo.verify(message.virtualAppointmentInfo);
                        if (error)
                            return "virtualAppointmentInfo." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BusinessProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 */
                BusinessProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessProto)
                        return object;
                    var message = new $root.space.kenko.proto.BusinessProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessDescription != null)
                        message.businessDescription = String(object.businessDescription);
                    if (object.websiteUrl != null)
                        message.websiteUrl = String(object.websiteUrl);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (var i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    if (object.shortUrl != null)
                        message.shortUrl = String(object.shortUrl);
                    if (object.virtualAppointmentInfo != null) {
                        if (typeof object.virtualAppointmentInfo !== "object")
                            throw TypeError(".space.kenko.proto.BusinessProto.virtualAppointmentInfo: object expected");
                        message.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.fromObject(object.virtualAppointmentInfo);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.BusinessProto} message BusinessProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrl = [];
                    if (options.defaults) {
                        object.businessName = "";
                        object.businessDescription = "";
                        object.websiteUrl = "";
                        object.phoneNumber = "";
                        object.email = "";
                        object.shortUrl = "";
                        object.virtualAppointmentInfo = null;
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        object.businessDescription = message.businessDescription;
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        object.websiteUrl = message.websiteUrl;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (var j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        object.shortUrl = message.shortUrl;
                    if (message.virtualAppointmentInfo != null && message.hasOwnProperty("virtualAppointmentInfo"))
                        object.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.toObject(message.virtualAppointmentInfo, options);
                    return object;
                };

                /**
                 * Converts this BusinessProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessProto";
                };

                return BusinessProto;
            })();

            proto.VirtualAppointmentInfo = (function() {

                /**
                 * Properties of a VirtualAppointmentInfo.
                 * @memberof space.kenko.proto
                 * @interface IVirtualAppointmentInfo
                 * @property {boolean|null} [acceptsVirtualAppointments] VirtualAppointmentInfo acceptsVirtualAppointments
                 */

                /**
                 * Constructs a new VirtualAppointmentInfo.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a VirtualAppointmentInfo.
                 * @implements IVirtualAppointmentInfo
                 * @constructor
                 * @param {space.kenko.proto.IVirtualAppointmentInfo=} [properties] Properties to set
                 */
                function VirtualAppointmentInfo(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VirtualAppointmentInfo acceptsVirtualAppointments.
                 * @member {boolean} acceptsVirtualAppointments
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @instance
                 */
                VirtualAppointmentInfo.prototype.acceptsVirtualAppointments = false;

                /**
                 * Creates a new VirtualAppointmentInfo instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo=} [properties] Properties to set
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo instance
                 */
                VirtualAppointmentInfo.create = function create(properties) {
                    return new VirtualAppointmentInfo(properties);
                };

                /**
                 * Encodes the specified VirtualAppointmentInfo message. Does not implicitly {@link space.kenko.proto.VirtualAppointmentInfo.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo} message VirtualAppointmentInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VirtualAppointmentInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.acceptsVirtualAppointments != null && Object.hasOwnProperty.call(message, "acceptsVirtualAppointments"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.acceptsVirtualAppointments);
                    return writer;
                };

                /**
                 * Encodes the specified VirtualAppointmentInfo message, length delimited. Does not implicitly {@link space.kenko.proto.VirtualAppointmentInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo} message VirtualAppointmentInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VirtualAppointmentInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VirtualAppointmentInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VirtualAppointmentInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.VirtualAppointmentInfo();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.acceptsVirtualAppointments = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VirtualAppointmentInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VirtualAppointmentInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VirtualAppointmentInfo message.
                 * @function verify
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VirtualAppointmentInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.acceptsVirtualAppointments != null && message.hasOwnProperty("acceptsVirtualAppointments"))
                        if (typeof message.acceptsVirtualAppointments !== "boolean")
                            return "acceptsVirtualAppointments: boolean expected";
                    return null;
                };

                /**
                 * Creates a VirtualAppointmentInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 */
                VirtualAppointmentInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.VirtualAppointmentInfo)
                        return object;
                    var message = new $root.space.kenko.proto.VirtualAppointmentInfo();
                    if (object.acceptsVirtualAppointments != null)
                        message.acceptsVirtualAppointments = Boolean(object.acceptsVirtualAppointments);
                    return message;
                };

                /**
                 * Creates a plain object from a VirtualAppointmentInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.VirtualAppointmentInfo} message VirtualAppointmentInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VirtualAppointmentInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.acceptsVirtualAppointments = false;
                    if (message.acceptsVirtualAppointments != null && message.hasOwnProperty("acceptsVirtualAppointments"))
                        object.acceptsVirtualAppointments = message.acceptsVirtualAppointments;
                    return object;
                };

                /**
                 * Converts this VirtualAppointmentInfo to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VirtualAppointmentInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VirtualAppointmentInfo
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VirtualAppointmentInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.VirtualAppointmentInfo";
                };

                return VirtualAppointmentInfo;
            })();

            /**
             * ProviderApprovalState enum.
             * @name space.kenko.proto.ProviderApprovalState
             * @enum {number}
             * @property {number} APPROVAL_STATE_UNKNOWN=0 APPROVAL_STATE_UNKNOWN value
             * @property {number} NEW_PROVIDER_MANUAL_APPROVE=10 NEW_PROVIDER_MANUAL_APPROVE value
             * @property {number} NEW_PROVIDER_AUTO_APPROVE=11 NEW_PROVIDER_AUTO_APPROVE value
             * @property {number} AWAITING_REVIEW=20 AWAITING_REVIEW value
             * @property {number} PROVIDER_APPROVED=30 PROVIDER_APPROVED value
             * @property {number} PROVIDER_REJECTED=40 PROVIDER_REJECTED value
             */
            proto.ProviderApprovalState = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "APPROVAL_STATE_UNKNOWN"] = 0;
                values[valuesById[10] = "NEW_PROVIDER_MANUAL_APPROVE"] = 10;
                values[valuesById[11] = "NEW_PROVIDER_AUTO_APPROVE"] = 11;
                values[valuesById[20] = "AWAITING_REVIEW"] = 20;
                values[valuesById[30] = "PROVIDER_APPROVED"] = 30;
                values[valuesById[40] = "PROVIDER_REJECTED"] = 40;
                return values;
            })();

            /**
             * ApprovalStatus enum.
             * @name space.kenko.proto.ApprovalStatus
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} IN_REVIEW=1 IN_REVIEW value
             * @property {number} APPROVED=2 APPROVED value
             * @property {number} REJECTED=3 REJECTED value
             */
            proto.ApprovalStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "IN_REVIEW"] = 1;
                values[valuesById[2] = "APPROVED"] = 2;
                values[valuesById[3] = "REJECTED"] = 3;
                return values;
            })();

            proto.ProfileReviewProto = (function() {

                /**
                 * Properties of a ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @interface IProfileReviewProto
                 * @property {space.kenko.proto.ApprovalStatus|null} [status] ProfileReviewProto status
                 * @property {Array.<string>|null} [messages] ProfileReviewProto messages
                 */

                /**
                 * Constructs a new ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProfileReviewProto.
                 * @implements IProfileReviewProto
                 * @constructor
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 */
                function ProfileReviewProto(properties) {
                    this.messages = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProfileReviewProto status.
                 * @member {space.kenko.proto.ApprovalStatus} status
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.status = 0;

                /**
                 * ProfileReviewProto messages.
                 * @member {Array.<string>} messages
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.messages = $util.emptyArray;

                /**
                 * Creates a new ProfileReviewProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto instance
                 */
                ProfileReviewProto.create = function create(properties) {
                    return new ProfileReviewProto(properties);
                };

                /**
                 * Encodes the specified ProfileReviewProto message. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.messages != null && message.messages.length)
                        for (var i = 0; i < message.messages.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.messages[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ProfileReviewProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProfileReviewProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.messages && message.messages.length))
                                    message.messages = [];
                                message.messages.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProfileReviewProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProfileReviewProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.messages != null && message.hasOwnProperty("messages")) {
                        if (!Array.isArray(message.messages))
                            return "messages: array expected";
                        for (var i = 0; i < message.messages.length; ++i)
                            if (!$util.isString(message.messages[i]))
                                return "messages: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ProfileReviewProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 */
                ProfileReviewProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProfileReviewProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProfileReviewProto();
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "IN_REVIEW":
                    case 1:
                        message.status = 1;
                        break;
                    case "APPROVED":
                    case 2:
                        message.status = 2;
                        break;
                    case "REJECTED":
                    case 3:
                        message.status = 3;
                        break;
                    }
                    if (object.messages) {
                        if (!Array.isArray(object.messages))
                            throw TypeError(".space.kenko.proto.ProfileReviewProto.messages: array expected");
                        message.messages = [];
                        for (var i = 0; i < object.messages.length; ++i)
                            message.messages[i] = String(object.messages[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProfileReviewProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.ProfileReviewProto} message ProfileReviewProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProfileReviewProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.messages = [];
                    if (options.defaults)
                        object.status = options.enums === String ? "UNKNOWN" : 0;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.space.kenko.proto.ApprovalStatus[message.status] === undefined ? message.status : $root.space.kenko.proto.ApprovalStatus[message.status] : message.status;
                    if (message.messages && message.messages.length) {
                        object.messages = [];
                        for (var j = 0; j < message.messages.length; ++j)
                            object.messages[j] = message.messages[j];
                    }
                    return object;
                };

                /**
                 * Converts this ProfileReviewProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProfileReviewProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProfileReviewProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProfileReviewProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProfileReviewProto";
                };

                return ProfileReviewProto;
            })();

            proto.BusinessLocationProto = (function() {

                /**
                 * Properties of a BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessLocationProto
                 * @property {string|null} [locationName] BusinessLocationProto locationName
                 * @property {string|null} [address] BusinessLocationProto address
                 * @property {string|null} [phone] BusinessLocationProto phone
                 * @property {string|null} [email] BusinessLocationProto email
                 * @property {number|null} [lat] BusinessLocationProto lat
                 * @property {number|null} [lng] BusinessLocationProto lng
                 * @property {string|null} [timeZone] BusinessLocationProto timeZone
                 * @property {boolean|null} ["private"] BusinessLocationProto private
                 * @property {boolean|null} [disabled] BusinessLocationProto disabled
                 * @property {Array.<string>|null} [imageUrl] BusinessLocationProto imageUrl
                 */

                /**
                 * Constructs a new BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessLocationProto.
                 * @implements IBusinessLocationProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 */
                function BusinessLocationProto(properties) {
                    this.imageUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessLocationProto locationName.
                 * @member {string} locationName
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.locationName = "";

                /**
                 * BusinessLocationProto address.
                 * @member {string} address
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.address = "";

                /**
                 * BusinessLocationProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.phone = "";

                /**
                 * BusinessLocationProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.email = "";

                /**
                 * BusinessLocationProto lat.
                 * @member {number} lat
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lat = 0;

                /**
                 * BusinessLocationProto lng.
                 * @member {number} lng
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lng = 0;

                /**
                 * BusinessLocationProto timeZone.
                 * @member {string} timeZone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.timeZone = "";

                /**
                 * BusinessLocationProto private.
                 * @member {boolean} private
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype["private"] = false;

                /**
                 * BusinessLocationProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.disabled = false;

                /**
                 * BusinessLocationProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * Creates a new BusinessLocationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto instance
                 */
                BusinessLocationProto.create = function create(properties) {
                    return new BusinessLocationProto(properties);
                };

                /**
                 * Encodes the specified BusinessLocationProto message. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationName);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.lat);
                    if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                        writer.uint32(/* id 7, wireType 1 =*/57).double(message.lng);
                    if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.timeZone);
                    if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message["private"]);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.disabled);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.imageUrl[i]);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessLocationProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessLocationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 3: {
                                message.address = reader.string();
                                break;
                            }
                        case 4: {
                                message.phone = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                message.lat = reader.double();
                                break;
                            }
                        case 7: {
                                message.lng = reader.double();
                                break;
                            }
                        case 8: {
                                message.timeZone = reader.string();
                                break;
                            }
                        case 9: {
                                message["private"] = reader.bool();
                                break;
                            }
                        case 10: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 11: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessLocationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessLocationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (typeof message.lat !== "number")
                            return "lat: number expected";
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        if (typeof message.lng !== "number")
                            return "lng: number expected";
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        if (!$util.isString(message.timeZone))
                            return "timeZone: string expected";
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        if (typeof message["private"] !== "boolean")
                            return "private: boolean expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (var i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a BusinessLocationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 */
                BusinessLocationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessLocationProto)
                        return object;
                    var message = new $root.space.kenko.proto.BusinessLocationProto();
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.lat != null)
                        message.lat = Number(object.lat);
                    if (object.lng != null)
                        message.lng = Number(object.lng);
                    if (object.timeZone != null)
                        message.timeZone = String(object.timeZone);
                    if (object["private"] != null)
                        message["private"] = Boolean(object["private"]);
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessLocationProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (var i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessLocationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.BusinessLocationProto} message BusinessLocationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessLocationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrl = [];
                    if (options.defaults) {
                        object.locationName = "";
                        object.address = "";
                        object.phone = "";
                        object.email = "";
                        object.lat = 0;
                        object.lng = 0;
                        object.timeZone = "";
                        object["private"] = false;
                        object.disabled = false;
                    }
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        object.timeZone = message.timeZone;
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        object["private"] = message["private"];
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (var j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    return object;
                };

                /**
                 * Converts this BusinessLocationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessLocationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessLocationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessLocationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessLocationProto";
                };

                return BusinessLocationProto;
            })();

            proto.UserProfileProto = (function() {

                /**
                 * Properties of a UserProfileProto.
                 * @memberof space.kenko.proto
                 * @interface IUserProfileProto
                 * @property {string|null} [firstName] UserProfileProto firstName
                 * @property {string|null} [lastName] UserProfileProto lastName
                 * @property {string|null} [mobileNumber] UserProfileProto mobileNumber
                 * @property {string|null} [email] UserProfileProto email
                 * @property {string|null} [countryCode] UserProfileProto countryCode
                 * @property {boolean|null} [agreedToTerms] UserProfileProto agreedToTerms
                 */

                /**
                 * Constructs a new UserProfileProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a UserProfileProto.
                 * @implements IUserProfileProto
                 * @constructor
                 * @param {space.kenko.proto.IUserProfileProto=} [properties] Properties to set
                 */
                function UserProfileProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserProfileProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.firstName = "";

                /**
                 * UserProfileProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.lastName = "";

                /**
                 * UserProfileProto mobileNumber.
                 * @member {string} mobileNumber
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.mobileNumber = "";

                /**
                 * UserProfileProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.email = "";

                /**
                 * UserProfileProto countryCode.
                 * @member {string} countryCode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.countryCode = "";

                /**
                 * UserProfileProto agreedToTerms.
                 * @member {boolean} agreedToTerms
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.agreedToTerms = false;

                /**
                 * Creates a new UserProfileProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto instance
                 */
                UserProfileProto.create = function create(properties) {
                    return new UserProfileProto(properties);
                };

                /**
                 * Encodes the specified UserProfileProto message. Does not implicitly {@link space.kenko.proto.UserProfileProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto} message UserProfileProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    if (message.mobileNumber != null && Object.hasOwnProperty.call(message, "mobileNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.mobileNumber);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                    if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.countryCode);
                    if (message.agreedToTerms != null && Object.hasOwnProperty.call(message, "agreedToTerms"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.agreedToTerms);
                    return writer;
                };

                /**
                 * Encodes the specified UserProfileProto message, length delimited. Does not implicitly {@link space.kenko.proto.UserProfileProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto} message UserProfileProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserProfileProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UserProfileProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 2: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 3: {
                                message.mobileNumber = reader.string();
                                break;
                            }
                        case 4: {
                                message.email = reader.string();
                                break;
                            }
                        case 5: {
                                message.countryCode = reader.string();
                                break;
                            }
                        case 6: {
                                message.agreedToTerms = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserProfileProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserProfileProto message.
                 * @function verify
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProfileProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.mobileNumber != null && message.hasOwnProperty("mobileNumber"))
                        if (!$util.isString(message.mobileNumber))
                            return "mobileNumber: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                        if (!$util.isString(message.countryCode))
                            return "countryCode: string expected";
                    if (message.agreedToTerms != null && message.hasOwnProperty("agreedToTerms"))
                        if (typeof message.agreedToTerms !== "boolean")
                            return "agreedToTerms: boolean expected";
                    return null;
                };

                /**
                 * Creates a UserProfileProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 */
                UserProfileProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UserProfileProto)
                        return object;
                    var message = new $root.space.kenko.proto.UserProfileProto();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.mobileNumber != null)
                        message.mobileNumber = String(object.mobileNumber);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.countryCode != null)
                        message.countryCode = String(object.countryCode);
                    if (object.agreedToTerms != null)
                        message.agreedToTerms = Boolean(object.agreedToTerms);
                    return message;
                };

                /**
                 * Creates a plain object from a UserProfileProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.UserProfileProto} message UserProfileProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProfileProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                        object.mobileNumber = "";
                        object.email = "";
                        object.countryCode = "";
                        object.agreedToTerms = false;
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.mobileNumber != null && message.hasOwnProperty("mobileNumber"))
                        object.mobileNumber = message.mobileNumber;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                        object.countryCode = message.countryCode;
                    if (message.agreedToTerms != null && message.hasOwnProperty("agreedToTerms"))
                        object.agreedToTerms = message.agreedToTerms;
                    return object;
                };

                /**
                 * Converts this UserProfileProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProfileProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserProfileProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UserProfileProto";
                };

                return UserProfileProto;
            })();

            proto.AddressProto = (function() {

                /**
                 * Properties of an AddressProto.
                 * @memberof space.kenko.proto
                 * @interface IAddressProto
                 * @property {string|null} [formattedAddress] AddressProto formattedAddress
                 * @property {number|null} [lat] AddressProto lat
                 * @property {number|null} [lng] AddressProto lng
                 */

                /**
                 * Constructs a new AddressProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an AddressProto.
                 * @implements IAddressProto
                 * @constructor
                 * @param {space.kenko.proto.IAddressProto=} [properties] Properties to set
                 */
                function AddressProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AddressProto formattedAddress.
                 * @member {string} formattedAddress
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.formattedAddress = "";

                /**
                 * AddressProto lat.
                 * @member {number} lat
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.lat = 0;

                /**
                 * AddressProto lng.
                 * @member {number} lng
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.lng = 0;

                /**
                 * Creates a new AddressProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.AddressProto} AddressProto instance
                 */
                AddressProto.create = function create(properties) {
                    return new AddressProto(properties);
                };

                /**
                 * Encodes the specified AddressProto message. Does not implicitly {@link space.kenko.proto.AddressProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto} message AddressProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.formattedAddress != null && Object.hasOwnProperty.call(message, "formattedAddress"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.formattedAddress);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.lat);
                    if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.lng);
                    return writer;
                };

                /**
                 * Encodes the specified AddressProto message, length delimited. Does not implicitly {@link space.kenko.proto.AddressProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto} message AddressProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AddressProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.AddressProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.formattedAddress = reader.string();
                                break;
                            }
                        case 2: {
                                message.lat = reader.double();
                                break;
                            }
                        case 3: {
                                message.lng = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AddressProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AddressProto message.
                 * @function verify
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddressProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formattedAddress != null && message.hasOwnProperty("formattedAddress"))
                        if (!$util.isString(message.formattedAddress))
                            return "formattedAddress: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (typeof message.lat !== "number")
                            return "lat: number expected";
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        if (typeof message.lng !== "number")
                            return "lng: number expected";
                    return null;
                };

                /**
                 * Creates an AddressProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 */
                AddressProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.AddressProto)
                        return object;
                    var message = new $root.space.kenko.proto.AddressProto();
                    if (object.formattedAddress != null)
                        message.formattedAddress = String(object.formattedAddress);
                    if (object.lat != null)
                        message.lat = Number(object.lat);
                    if (object.lng != null)
                        message.lng = Number(object.lng);
                    return message;
                };

                /**
                 * Creates a plain object from an AddressProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.AddressProto} message AddressProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddressProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.formattedAddress = "";
                        object.lat = 0;
                        object.lng = 0;
                    }
                    if (message.formattedAddress != null && message.hasOwnProperty("formattedAddress"))
                        object.formattedAddress = message.formattedAddress;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
                    return object;
                };

                /**
                 * Converts this AddressProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddressProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AddressProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.AddressProto";
                };

                return AddressProto;
            })();

            proto.ProviderBioProto = (function() {

                /**
                 * Properties of a ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderBioProto
                 * @property {string|null} [providerId] ProviderBioProto providerId
                 * @property {string|null} [firstName] ProviderBioProto firstName
                 * @property {string|null} [lastName] ProviderBioProto lastName
                 * @property {boolean|null} [isPractitioner] ProviderBioProto isPractitioner
                 * @property {string|null} [bio] ProviderBioProto bio
                 * @property {Array.<string>|null} [imageUrls] ProviderBioProto imageUrls
                 * @property {string|null} [email] ProviderBioProto email
                 * @property {string|null} [phone] ProviderBioProto phone
                 * @property {string|null} [jobTitle] ProviderBioProto jobTitle
                 * @property {Array.<space.kenko.proto.IEducationProto>|null} [education] ProviderBioProto education
                 * @property {Array.<space.kenko.proto.ILicenseOrCredentialsProto>|null} [credentials] ProviderBioProto credentials
                 * @property {Array.<string>|null} [languages] ProviderBioProto languages
                 * @property {boolean|null} [disabled] ProviderBioProto disabled
                 * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] ProviderBioProto errors
                 */

                /**
                 * Constructs a new ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderBioProto.
                 * @implements IProviderBioProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 */
                function ProviderBioProto(properties) {
                    this.imageUrls = [];
                    this.education = [];
                    this.credentials = [];
                    this.languages = [];
                    this.errors = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderBioProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.providerId = "";

                /**
                 * ProviderBioProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.firstName = "";

                /**
                 * ProviderBioProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.lastName = "";

                /**
                 * ProviderBioProto isPractitioner.
                 * @member {boolean} isPractitioner
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.isPractitioner = false;

                /**
                 * ProviderBioProto bio.
                 * @member {string} bio
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.bio = "";

                /**
                 * ProviderBioProto imageUrls.
                 * @member {Array.<string>} imageUrls
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.imageUrls = $util.emptyArray;

                /**
                 * ProviderBioProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.email = "";

                /**
                 * ProviderBioProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.phone = "";

                /**
                 * ProviderBioProto jobTitle.
                 * @member {string} jobTitle
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.jobTitle = "";

                /**
                 * ProviderBioProto education.
                 * @member {Array.<space.kenko.proto.IEducationProto>} education
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.education = $util.emptyArray;

                /**
                 * ProviderBioProto credentials.
                 * @member {Array.<space.kenko.proto.ILicenseOrCredentialsProto>} credentials
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.credentials = $util.emptyArray;

                /**
                 * ProviderBioProto languages.
                 * @member {Array.<string>} languages
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.languages = $util.emptyArray;

                /**
                 * ProviderBioProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.disabled = false;

                /**
                 * ProviderBioProto errors.
                 * @member {Array.<space.kenko.proto.IErrorProto>} errors
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.errors = $util.emptyArray;

                /**
                 * Creates a new ProviderBioProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto instance
                 */
                ProviderBioProto.create = function create(properties) {
                    return new ProviderBioProto(properties);
                };

                /**
                 * Encodes the specified ProviderBioProto message. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                    if (message.bio != null && Object.hasOwnProperty.call(message, "bio"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.bio);
                    if (message.imageUrls != null && message.imageUrls.length)
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.imageUrls[i]);
                    if (message.isPractitioner != null && Object.hasOwnProperty.call(message, "isPractitioner"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isPractitioner);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.email);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.phone);
                    if (message.jobTitle != null && Object.hasOwnProperty.call(message, "jobTitle"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jobTitle);
                    if (message.education != null && message.education.length)
                        for (var i = 0; i < message.education.length; ++i)
                            $root.space.kenko.proto.EducationProto.encode(message.education[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.credentials != null && message.credentials.length)
                        for (var i = 0; i < message.credentials.length; ++i)
                            $root.space.kenko.proto.LicenseOrCredentialsProto.encode(message.credentials[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.languages != null && message.languages.length)
                        for (var i = 0; i < message.languages.length; ++i)
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.languages[i]);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 100, wireType 0 =*/800).bool(message.disabled);
                    if (message.errors != null && message.errors.length)
                        for (var i = 0; i < message.errors.length; ++i)
                            $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ProviderBioProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderBioProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 3: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 6: {
                                message.isPractitioner = reader.bool();
                                break;
                            }
                        case 4: {
                                message.bio = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.imageUrls && message.imageUrls.length))
                                    message.imageUrls = [];
                                message.imageUrls.push(reader.string());
                                break;
                            }
                        case 8: {
                                message.email = reader.string();
                                break;
                            }
                        case 9: {
                                message.phone = reader.string();
                                break;
                            }
                        case 10: {
                                message.jobTitle = reader.string();
                                break;
                            }
                        case 11: {
                                if (!(message.education && message.education.length))
                                    message.education = [];
                                message.education.push($root.space.kenko.proto.EducationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 12: {
                                if (!(message.credentials && message.credentials.length))
                                    message.credentials = [];
                                message.credentials.push($root.space.kenko.proto.LicenseOrCredentialsProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 13: {
                                if (!(message.languages && message.languages.length))
                                    message.languages = [];
                                message.languages.push(reader.string());
                                break;
                            }
                        case 100: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 101: {
                                if (!(message.errors && message.errors.length))
                                    message.errors = [];
                                message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderBioProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderBioProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.isPractitioner != null && message.hasOwnProperty("isPractitioner"))
                        if (typeof message.isPractitioner !== "boolean")
                            return "isPractitioner: boolean expected";
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        if (!$util.isString(message.bio))
                            return "bio: string expected";
                    if (message.imageUrls != null && message.hasOwnProperty("imageUrls")) {
                        if (!Array.isArray(message.imageUrls))
                            return "imageUrls: array expected";
                        for (var i = 0; i < message.imageUrls.length; ++i)
                            if (!$util.isString(message.imageUrls[i]))
                                return "imageUrls: string[] expected";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        if (!$util.isString(message.jobTitle))
                            return "jobTitle: string expected";
                    if (message.education != null && message.hasOwnProperty("education")) {
                        if (!Array.isArray(message.education))
                            return "education: array expected";
                        for (var i = 0; i < message.education.length; ++i) {
                            var error = $root.space.kenko.proto.EducationProto.verify(message.education[i]);
                            if (error)
                                return "education." + error;
                        }
                    }
                    if (message.credentials != null && message.hasOwnProperty("credentials")) {
                        if (!Array.isArray(message.credentials))
                            return "credentials: array expected";
                        for (var i = 0; i < message.credentials.length; ++i) {
                            var error = $root.space.kenko.proto.LicenseOrCredentialsProto.verify(message.credentials[i]);
                            if (error)
                                return "credentials." + error;
                        }
                    }
                    if (message.languages != null && message.hasOwnProperty("languages")) {
                        if (!Array.isArray(message.languages))
                            return "languages: array expected";
                        for (var i = 0; i < message.languages.length; ++i)
                            if (!$util.isString(message.languages[i]))
                                return "languages: string[] expected";
                    }
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (var i = 0; i < message.errors.length; ++i) {
                            var error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ProviderBioProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 */
                ProviderBioProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderBioProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderBioProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.isPractitioner != null)
                        message.isPractitioner = Boolean(object.isPractitioner);
                    if (object.bio != null)
                        message.bio = String(object.bio);
                    if (object.imageUrls) {
                        if (!Array.isArray(object.imageUrls))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.imageUrls: array expected");
                        message.imageUrls = [];
                        for (var i = 0; i < object.imageUrls.length; ++i)
                            message.imageUrls[i] = String(object.imageUrls[i]);
                    }
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.jobTitle != null)
                        message.jobTitle = String(object.jobTitle);
                    if (object.education) {
                        if (!Array.isArray(object.education))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.education: array expected");
                        message.education = [];
                        for (var i = 0; i < object.education.length; ++i) {
                            if (typeof object.education[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.education: object expected");
                            message.education[i] = $root.space.kenko.proto.EducationProto.fromObject(object.education[i]);
                        }
                    }
                    if (object.credentials) {
                        if (!Array.isArray(object.credentials))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.credentials: array expected");
                        message.credentials = [];
                        for (var i = 0; i < object.credentials.length; ++i) {
                            if (typeof object.credentials[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.credentials: object expected");
                            message.credentials[i] = $root.space.kenko.proto.LicenseOrCredentialsProto.fromObject(object.credentials[i]);
                        }
                    }
                    if (object.languages) {
                        if (!Array.isArray(object.languages))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.languages: array expected");
                        message.languages = [];
                        for (var i = 0; i < object.languages.length; ++i)
                            message.languages[i] = String(object.languages[i]);
                    }
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.errors: array expected");
                        message.errors = [];
                        for (var i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.errors: object expected");
                            message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderBioProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.ProviderBioProto} message ProviderBioProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderBioProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.imageUrls = [];
                        object.education = [];
                        object.credentials = [];
                        object.languages = [];
                        object.errors = [];
                    }
                    if (options.defaults) {
                        object.providerId = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.bio = "";
                        object.isPractitioner = false;
                        object.email = "";
                        object.phone = "";
                        object.jobTitle = "";
                        object.disabled = false;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        object.bio = message.bio;
                    if (message.imageUrls && message.imageUrls.length) {
                        object.imageUrls = [];
                        for (var j = 0; j < message.imageUrls.length; ++j)
                            object.imageUrls[j] = message.imageUrls[j];
                    }
                    if (message.isPractitioner != null && message.hasOwnProperty("isPractitioner"))
                        object.isPractitioner = message.isPractitioner;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        object.jobTitle = message.jobTitle;
                    if (message.education && message.education.length) {
                        object.education = [];
                        for (var j = 0; j < message.education.length; ++j)
                            object.education[j] = $root.space.kenko.proto.EducationProto.toObject(message.education[j], options);
                    }
                    if (message.credentials && message.credentials.length) {
                        object.credentials = [];
                        for (var j = 0; j < message.credentials.length; ++j)
                            object.credentials[j] = $root.space.kenko.proto.LicenseOrCredentialsProto.toObject(message.credentials[j], options);
                    }
                    if (message.languages && message.languages.length) {
                        object.languages = [];
                        for (var j = 0; j < message.languages.length; ++j)
                            object.languages[j] = message.languages[j];
                    }
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (var j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ProviderBioProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderBioProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderBioProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderBioProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderBioProto";
                };

                return ProviderBioProto;
            })();

            proto.SpecialtyProto = (function() {

                /**
                 * Properties of a SpecialtyProto.
                 * @memberof space.kenko.proto
                 * @interface ISpecialtyProto
                 * @property {string|null} [specialty] SpecialtyProto specialty
                 * @property {number|null} [yearsExperience] SpecialtyProto yearsExperience
                 */

                /**
                 * Constructs a new SpecialtyProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a SpecialtyProto.
                 * @implements ISpecialtyProto
                 * @constructor
                 * @param {space.kenko.proto.ISpecialtyProto=} [properties] Properties to set
                 */
                function SpecialtyProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SpecialtyProto specialty.
                 * @member {string} specialty
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 */
                SpecialtyProto.prototype.specialty = "";

                /**
                 * SpecialtyProto yearsExperience.
                 * @member {number} yearsExperience
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 */
                SpecialtyProto.prototype.yearsExperience = 0;

                /**
                 * Creates a new SpecialtyProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto instance
                 */
                SpecialtyProto.create = function create(properties) {
                    return new SpecialtyProto(properties);
                };

                /**
                 * Encodes the specified SpecialtyProto message. Does not implicitly {@link space.kenko.proto.SpecialtyProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto} message SpecialtyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.specialty != null && Object.hasOwnProperty.call(message, "specialty"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.specialty);
                    if (message.yearsExperience != null && Object.hasOwnProperty.call(message, "yearsExperience"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.yearsExperience);
                    return writer;
                };

                /**
                 * Encodes the specified SpecialtyProto message, length delimited. Does not implicitly {@link space.kenko.proto.SpecialtyProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto} message SpecialtyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SpecialtyProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.SpecialtyProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.specialty = reader.string();
                                break;
                            }
                        case 2: {
                                message.yearsExperience = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SpecialtyProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SpecialtyProto message.
                 * @function verify
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpecialtyProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.specialty != null && message.hasOwnProperty("specialty"))
                        if (!$util.isString(message.specialty))
                            return "specialty: string expected";
                    if (message.yearsExperience != null && message.hasOwnProperty("yearsExperience"))
                        if (!$util.isInteger(message.yearsExperience))
                            return "yearsExperience: integer expected";
                    return null;
                };

                /**
                 * Creates a SpecialtyProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 */
                SpecialtyProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.SpecialtyProto)
                        return object;
                    var message = new $root.space.kenko.proto.SpecialtyProto();
                    if (object.specialty != null)
                        message.specialty = String(object.specialty);
                    if (object.yearsExperience != null)
                        message.yearsExperience = object.yearsExperience | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a SpecialtyProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.SpecialtyProto} message SpecialtyProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpecialtyProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.specialty = "";
                        object.yearsExperience = 0;
                    }
                    if (message.specialty != null && message.hasOwnProperty("specialty"))
                        object.specialty = message.specialty;
                    if (message.yearsExperience != null && message.hasOwnProperty("yearsExperience"))
                        object.yearsExperience = message.yearsExperience;
                    return object;
                };

                /**
                 * Converts this SpecialtyProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpecialtyProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SpecialtyProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpecialtyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.SpecialtyProto";
                };

                return SpecialtyProto;
            })();

            proto.EducationProto = (function() {

                /**
                 * Properties of an EducationProto.
                 * @memberof space.kenko.proto
                 * @interface IEducationProto
                 * @property {string|null} [school] EducationProto school
                 * @property {string|null} [degree] EducationProto degree
                 * @property {number|null} [yearCompleted] EducationProto yearCompleted
                 */

                /**
                 * Constructs a new EducationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an EducationProto.
                 * @implements IEducationProto
                 * @constructor
                 * @param {space.kenko.proto.IEducationProto=} [properties] Properties to set
                 */
                function EducationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EducationProto school.
                 * @member {string} school
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.school = "";

                /**
                 * EducationProto degree.
                 * @member {string} degree
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.degree = "";

                /**
                 * EducationProto yearCompleted.
                 * @member {number} yearCompleted
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.yearCompleted = 0;

                /**
                 * Creates a new EducationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.EducationProto} EducationProto instance
                 */
                EducationProto.create = function create(properties) {
                    return new EducationProto(properties);
                };

                /**
                 * Encodes the specified EducationProto message. Does not implicitly {@link space.kenko.proto.EducationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto} message EducationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EducationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.school != null && Object.hasOwnProperty.call(message, "school"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.school);
                    if (message.degree != null && Object.hasOwnProperty.call(message, "degree"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.degree);
                    if (message.yearCompleted != null && Object.hasOwnProperty.call(message, "yearCompleted"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.yearCompleted);
                    return writer;
                };

                /**
                 * Encodes the specified EducationProto message, length delimited. Does not implicitly {@link space.kenko.proto.EducationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto} message EducationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EducationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EducationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EducationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.EducationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.school = reader.string();
                                break;
                            }
                        case 2: {
                                message.degree = reader.string();
                                break;
                            }
                        case 3: {
                                message.yearCompleted = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EducationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EducationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EducationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EducationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.school != null && message.hasOwnProperty("school"))
                        if (!$util.isString(message.school))
                            return "school: string expected";
                    if (message.degree != null && message.hasOwnProperty("degree"))
                        if (!$util.isString(message.degree))
                            return "degree: string expected";
                    if (message.yearCompleted != null && message.hasOwnProperty("yearCompleted"))
                        if (!$util.isInteger(message.yearCompleted))
                            return "yearCompleted: integer expected";
                    return null;
                };

                /**
                 * Creates an EducationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 */
                EducationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.EducationProto)
                        return object;
                    var message = new $root.space.kenko.proto.EducationProto();
                    if (object.school != null)
                        message.school = String(object.school);
                    if (object.degree != null)
                        message.degree = String(object.degree);
                    if (object.yearCompleted != null)
                        message.yearCompleted = object.yearCompleted | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an EducationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.EducationProto} message EducationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EducationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.school = "";
                        object.degree = "";
                        object.yearCompleted = 0;
                    }
                    if (message.school != null && message.hasOwnProperty("school"))
                        object.school = message.school;
                    if (message.degree != null && message.hasOwnProperty("degree"))
                        object.degree = message.degree;
                    if (message.yearCompleted != null && message.hasOwnProperty("yearCompleted"))
                        object.yearCompleted = message.yearCompleted;
                    return object;
                };

                /**
                 * Converts this EducationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EducationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EducationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EducationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.EducationProto";
                };

                return EducationProto;
            })();

            proto.LicenseOrCredentialsProto = (function() {

                /**
                 * Properties of a LicenseOrCredentialsProto.
                 * @memberof space.kenko.proto
                 * @interface ILicenseOrCredentialsProto
                 * @property {string|null} [licenseOrCredential] LicenseOrCredentialsProto licenseOrCredential
                 * @property {Array.<string>|null} [supportDocumentationUrl] LicenseOrCredentialsProto supportDocumentationUrl
                 */

                /**
                 * Constructs a new LicenseOrCredentialsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LicenseOrCredentialsProto.
                 * @implements ILicenseOrCredentialsProto
                 * @constructor
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto=} [properties] Properties to set
                 */
                function LicenseOrCredentialsProto(properties) {
                    this.supportDocumentationUrl = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LicenseOrCredentialsProto licenseOrCredential.
                 * @member {string} licenseOrCredential
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 */
                LicenseOrCredentialsProto.prototype.licenseOrCredential = "";

                /**
                 * LicenseOrCredentialsProto supportDocumentationUrl.
                 * @member {Array.<string>} supportDocumentationUrl
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 */
                LicenseOrCredentialsProto.prototype.supportDocumentationUrl = $util.emptyArray;

                /**
                 * Creates a new LicenseOrCredentialsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto instance
                 */
                LicenseOrCredentialsProto.create = function create(properties) {
                    return new LicenseOrCredentialsProto(properties);
                };

                /**
                 * Encodes the specified LicenseOrCredentialsProto message. Does not implicitly {@link space.kenko.proto.LicenseOrCredentialsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto} message LicenseOrCredentialsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LicenseOrCredentialsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.licenseOrCredential != null && Object.hasOwnProperty.call(message, "licenseOrCredential"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.licenseOrCredential);
                    if (message.supportDocumentationUrl != null && message.supportDocumentationUrl.length)
                        for (var i = 0; i < message.supportDocumentationUrl.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.supportDocumentationUrl[i]);
                    return writer;
                };

                /**
                 * Encodes the specified LicenseOrCredentialsProto message, length delimited. Does not implicitly {@link space.kenko.proto.LicenseOrCredentialsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto} message LicenseOrCredentialsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LicenseOrCredentialsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LicenseOrCredentialsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LicenseOrCredentialsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LicenseOrCredentialsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.licenseOrCredential = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.supportDocumentationUrl && message.supportDocumentationUrl.length))
                                    message.supportDocumentationUrl = [];
                                message.supportDocumentationUrl.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LicenseOrCredentialsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LicenseOrCredentialsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LicenseOrCredentialsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LicenseOrCredentialsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseOrCredential != null && message.hasOwnProperty("licenseOrCredential"))
                        if (!$util.isString(message.licenseOrCredential))
                            return "licenseOrCredential: string expected";
                    if (message.supportDocumentationUrl != null && message.hasOwnProperty("supportDocumentationUrl")) {
                        if (!Array.isArray(message.supportDocumentationUrl))
                            return "supportDocumentationUrl: array expected";
                        for (var i = 0; i < message.supportDocumentationUrl.length; ++i)
                            if (!$util.isString(message.supportDocumentationUrl[i]))
                                return "supportDocumentationUrl: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a LicenseOrCredentialsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 */
                LicenseOrCredentialsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LicenseOrCredentialsProto)
                        return object;
                    var message = new $root.space.kenko.proto.LicenseOrCredentialsProto();
                    if (object.licenseOrCredential != null)
                        message.licenseOrCredential = String(object.licenseOrCredential);
                    if (object.supportDocumentationUrl) {
                        if (!Array.isArray(object.supportDocumentationUrl))
                            throw TypeError(".space.kenko.proto.LicenseOrCredentialsProto.supportDocumentationUrl: array expected");
                        message.supportDocumentationUrl = [];
                        for (var i = 0; i < object.supportDocumentationUrl.length; ++i)
                            message.supportDocumentationUrl[i] = String(object.supportDocumentationUrl[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LicenseOrCredentialsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.LicenseOrCredentialsProto} message LicenseOrCredentialsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LicenseOrCredentialsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.supportDocumentationUrl = [];
                    if (options.defaults)
                        object.licenseOrCredential = "";
                    if (message.licenseOrCredential != null && message.hasOwnProperty("licenseOrCredential"))
                        object.licenseOrCredential = message.licenseOrCredential;
                    if (message.supportDocumentationUrl && message.supportDocumentationUrl.length) {
                        object.supportDocumentationUrl = [];
                        for (var j = 0; j < message.supportDocumentationUrl.length; ++j)
                            object.supportDocumentationUrl[j] = message.supportDocumentationUrl[j];
                    }
                    return object;
                };

                /**
                 * Converts this LicenseOrCredentialsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LicenseOrCredentialsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LicenseOrCredentialsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LicenseOrCredentialsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LicenseOrCredentialsProto";
                };

                return LicenseOrCredentialsProto;
            })();

            proto.PracticeDetailsProto = (function() {

                /**
                 * Properties of a PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface IPracticeDetailsProto
                 * @property {Array.<space.kenko.proto.IServiceProto>|null} [services] PracticeDetailsProto services
                 */

                /**
                 * Constructs a new PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PracticeDetailsProto.
                 * @implements IPracticeDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 */
                function PracticeDetailsProto(properties) {
                    this.services = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PracticeDetailsProto services.
                 * @member {Array.<space.kenko.proto.IServiceProto>} services
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 */
                PracticeDetailsProto.prototype.services = $util.emptyArray;

                /**
                 * Creates a new PracticeDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto instance
                 */
                PracticeDetailsProto.create = function create(properties) {
                    return new PracticeDetailsProto(properties);
                };

                /**
                 * Encodes the specified PracticeDetailsProto message. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.services != null && message.services.length)
                        for (var i = 0; i < message.services.length; ++i)
                            $root.space.kenko.proto.ServiceProto.encode(message.services[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PracticeDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PracticeDetailsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.services && message.services.length))
                                    message.services = [];
                                message.services.push($root.space.kenko.proto.ServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PracticeDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PracticeDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.services != null && message.hasOwnProperty("services")) {
                        if (!Array.isArray(message.services))
                            return "services: array expected";
                        for (var i = 0; i < message.services.length; ++i) {
                            var error = $root.space.kenko.proto.ServiceProto.verify(message.services[i]);
                            if (error)
                                return "services." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PracticeDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 */
                PracticeDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PracticeDetailsProto)
                        return object;
                    var message = new $root.space.kenko.proto.PracticeDetailsProto();
                    if (object.services) {
                        if (!Array.isArray(object.services))
                            throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: array expected");
                        message.services = [];
                        for (var i = 0; i < object.services.length; ++i) {
                            if (typeof object.services[i] !== "object")
                                throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: object expected");
                            message.services[i] = $root.space.kenko.proto.ServiceProto.fromObject(object.services[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PracticeDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.PracticeDetailsProto} message PracticeDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PracticeDetailsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.services = [];
                    if (message.services && message.services.length) {
                        object.services = [];
                        for (var j = 0; j < message.services.length; ++j)
                            object.services[j] = $root.space.kenko.proto.ServiceProto.toObject(message.services[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PracticeDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PracticeDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PracticeDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PracticeDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PracticeDetailsProto";
                };

                return PracticeDetailsProto;
            })();

            proto.ServiceProto = (function() {

                /**
                 * Properties of a ServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceProto
                 * @property {string|null} [sku] ServiceProto sku
                 * @property {string|null} [name] ServiceProto name
                 * @property {string|null} [description] ServiceProto description
                 * @property {number|null} [lengthMinutes] ServiceProto lengthMinutes
                 * @property {string|null} [category] ServiceProto category
                 * @property {Array.<number>|null} [availableLocations] ServiceProto availableLocations
                 * @property {boolean|null} [virtualAvailable] ServiceProto virtualAvailable
                 * @property {Array.<space.kenko.proto.IPractitionerServiceProto>|null} [offeredBy] ServiceProto offeredBy
                 */

                /**
                 * Constructs a new ServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceProto.
                 * @implements IServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 */
                function ServiceProto(properties) {
                    this.availableLocations = [];
                    this.offeredBy = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.sku = "";

                /**
                 * ServiceProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.name = "";

                /**
                 * ServiceProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.description = "";

                /**
                 * ServiceProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.category = "";

                /**
                 * ServiceProto availableLocations.
                 * @member {Array.<number>} availableLocations
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.availableLocations = $util.emptyArray;

                /**
                 * ServiceProto virtualAvailable.
                 * @member {boolean} virtualAvailable
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.virtualAvailable = false;

                /**
                 * ServiceProto offeredBy.
                 * @member {Array.<space.kenko.proto.IPractitionerServiceProto>} offeredBy
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.offeredBy = $util.emptyArray;

                /**
                 * Creates a new ServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto instance
                 */
                ServiceProto.create = function create(properties) {
                    return new ServiceProto(properties);
                };

                /**
                 * Encodes the specified ServiceProto message. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.category);
                    if (message.availableLocations != null && message.availableLocations.length) {
                        writer.uint32(/* id 6, wireType 2 =*/50).fork();
                        for (var i = 0; i < message.availableLocations.length; ++i)
                            writer.int32(message.availableLocations[i]);
                        writer.ldelim();
                    }
                    if (message.virtualAvailable != null && Object.hasOwnProperty.call(message, "virtualAvailable"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.virtualAvailable);
                    if (message.offeredBy != null && message.offeredBy.length)
                        for (var i = 0; i < message.offeredBy.length; ++i)
                            $root.space.kenko.proto.PractitionerServiceProto.encode(message.offeredBy[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.category = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.availableLocations && message.availableLocations.length))
                                    message.availableLocations = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.availableLocations.push(reader.int32());
                                } else
                                    message.availableLocations.push(reader.int32());
                                break;
                            }
                        case 7: {
                                message.virtualAvailable = reader.bool();
                                break;
                            }
                        case 8: {
                                if (!(message.offeredBy && message.offeredBy.length))
                                    message.offeredBy = [];
                                message.offeredBy.push($root.space.kenko.proto.PractitionerServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.availableLocations != null && message.hasOwnProperty("availableLocations")) {
                        if (!Array.isArray(message.availableLocations))
                            return "availableLocations: array expected";
                        for (var i = 0; i < message.availableLocations.length; ++i)
                            if (!$util.isInteger(message.availableLocations[i]))
                                return "availableLocations: integer[] expected";
                    }
                    if (message.virtualAvailable != null && message.hasOwnProperty("virtualAvailable"))
                        if (typeof message.virtualAvailable !== "boolean")
                            return "virtualAvailable: boolean expected";
                    if (message.offeredBy != null && message.hasOwnProperty("offeredBy")) {
                        if (!Array.isArray(message.offeredBy))
                            return "offeredBy: array expected";
                        for (var i = 0; i < message.offeredBy.length; ++i) {
                            var error = $root.space.kenko.proto.PractitionerServiceProto.verify(message.offeredBy[i]);
                            if (error)
                                return "offeredBy." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 */
                ServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceProto)
                        return object;
                    var message = new $root.space.kenko.proto.ServiceProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.availableLocations) {
                        if (!Array.isArray(object.availableLocations))
                            throw TypeError(".space.kenko.proto.ServiceProto.availableLocations: array expected");
                        message.availableLocations = [];
                        for (var i = 0; i < object.availableLocations.length; ++i)
                            message.availableLocations[i] = object.availableLocations[i] | 0;
                    }
                    if (object.virtualAvailable != null)
                        message.virtualAvailable = Boolean(object.virtualAvailable);
                    if (object.offeredBy) {
                        if (!Array.isArray(object.offeredBy))
                            throw TypeError(".space.kenko.proto.ServiceProto.offeredBy: array expected");
                        message.offeredBy = [];
                        for (var i = 0; i < object.offeredBy.length; ++i) {
                            if (typeof object.offeredBy[i] !== "object")
                                throw TypeError(".space.kenko.proto.ServiceProto.offeredBy: object expected");
                            message.offeredBy[i] = $root.space.kenko.proto.PractitionerServiceProto.fromObject(object.offeredBy[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.ServiceProto} message ServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.availableLocations = [];
                        object.offeredBy = [];
                    }
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.category = "";
                        object.virtualAvailable = false;
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.availableLocations && message.availableLocations.length) {
                        object.availableLocations = [];
                        for (var j = 0; j < message.availableLocations.length; ++j)
                            object.availableLocations[j] = message.availableLocations[j];
                    }
                    if (message.virtualAvailable != null && message.hasOwnProperty("virtualAvailable"))
                        object.virtualAvailable = message.virtualAvailable;
                    if (message.offeredBy && message.offeredBy.length) {
                        object.offeredBy = [];
                        for (var j = 0; j < message.offeredBy.length; ++j)
                            object.offeredBy[j] = $root.space.kenko.proto.PractitionerServiceProto.toObject(message.offeredBy[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceProto";
                };

                return ServiceProto;
            })();

            proto.PractitionerServiceProto = (function() {

                /**
                 * Properties of a PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerServiceProto
                 * @property {string|null} [providerId] PractitionerServiceProto providerId
                 * @property {space.kenko.proto.IPriceProto|null} [price] PractitionerServiceProto price
                 */

                /**
                 * Constructs a new PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerServiceProto.
                 * @implements IPractitionerServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 */
                function PractitionerServiceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerServiceProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.providerId = "";

                /**
                 * PractitionerServiceProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.price = null;

                /**
                 * Creates a new PractitionerServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto instance
                 */
                PractitionerServiceProto.create = function create(properties) {
                    return new PractitionerServiceProto(properties);
                };

                /**
                 * Encodes the specified PractitionerServiceProto message. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerServiceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 */
                PractitionerServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerServiceProto)
                        return object;
                    var message = new $root.space.kenko.proto.PractitionerServiceProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerServiceProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.PractitionerServiceProto} message PractitionerServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.providerId = "";
                        object.price = null;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    return object;
                };

                /**
                 * Converts this PractitionerServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerServiceProto";
                };

                return PractitionerServiceProto;
            })();

            proto.ServiceSchemaProto = (function() {

                /**
                 * Properties of a ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceSchemaProto
                 * @property {string|null} [sku] ServiceSchemaProto sku
                 * @property {string|null} [name] ServiceSchemaProto name
                 * @property {string|null} [description] ServiceSchemaProto description
                 * @property {number|null} [lengthMinutes] ServiceSchemaProto lengthMinutes
                 * @property {space.kenko.proto.IPriceProto|null} [price] ServiceSchemaProto price
                 * @property {string|null} [category] ServiceSchemaProto category
                 */

                /**
                 * Constructs a new ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceSchemaProto.
                 * @implements IServiceSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 */
                function ServiceSchemaProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceSchemaProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.sku = "";

                /**
                 * ServiceSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.name = "";

                /**
                 * ServiceSchemaProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.description = "";

                /**
                 * ServiceSchemaProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceSchemaProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.price = null;

                /**
                 * ServiceSchemaProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.category = "";

                /**
                 * Creates a new ServiceSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto instance
                 */
                ServiceSchemaProto.create = function create(properties) {
                    return new ServiceSchemaProto(properties);
                };

                /**
                 * Encodes the specified ServiceSchemaProto message. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.category);
                    return writer;
                };

                /**
                 * Encodes the specified ServiceSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.category = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    return null;
                };

                /**
                 * Creates a ServiceSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 */
                ServiceSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ServiceSchemaProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.ServiceSchemaProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    if (object.category != null)
                        message.category = String(object.category);
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.ServiceSchemaProto} message ServiceSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.price = null;
                        object.category = "";
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    return object;
                };

                /**
                 * Converts this ServiceSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceSchemaProto";
                };

                return ServiceSchemaProto;
            })();

            proto.InvitationProtos = (function() {

                /**
                 * Properties of an InvitationProtos.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProtos
                 * @property {Array.<space.kenko.proto.IInvitationProto>|null} [invitations] InvitationProtos invitations
                 */

                /**
                 * Constructs a new InvitationProtos.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProtos.
                 * @implements IInvitationProtos
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 */
                function InvitationProtos(properties) {
                    this.invitations = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProtos invitations.
                 * @member {Array.<space.kenko.proto.IInvitationProto>} invitations
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 */
                InvitationProtos.prototype.invitations = $util.emptyArray;

                /**
                 * Creates a new InvitationProtos instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos instance
                 */
                InvitationProtos.create = function create(properties) {
                    return new InvitationProtos(properties);
                };

                /**
                 * Encodes the specified InvitationProtos message. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invitations != null && message.invitations.length)
                        for (var i = 0; i < message.invitations.length; ++i)
                            $root.space.kenko.proto.InvitationProto.encode(message.invitations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProtos message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProtos();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.invitations && message.invitations.length))
                                    message.invitations = [];
                                message.invitations.push($root.space.kenko.proto.InvitationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProtos message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProtos.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invitations != null && message.hasOwnProperty("invitations")) {
                        if (!Array.isArray(message.invitations))
                            return "invitations: array expected";
                        for (var i = 0; i < message.invitations.length; ++i) {
                            var error = $root.space.kenko.proto.InvitationProto.verify(message.invitations[i]);
                            if (error)
                                return "invitations." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an InvitationProtos message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 */
                InvitationProtos.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProtos)
                        return object;
                    var message = new $root.space.kenko.proto.InvitationProtos();
                    if (object.invitations) {
                        if (!Array.isArray(object.invitations))
                            throw TypeError(".space.kenko.proto.InvitationProtos.invitations: array expected");
                        message.invitations = [];
                        for (var i = 0; i < object.invitations.length; ++i) {
                            if (typeof object.invitations[i] !== "object")
                                throw TypeError(".space.kenko.proto.InvitationProtos.invitations: object expected");
                            message.invitations[i] = $root.space.kenko.proto.InvitationProto.fromObject(object.invitations[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProtos message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.InvitationProtos} message InvitationProtos
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProtos.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.invitations = [];
                    if (message.invitations && message.invitations.length) {
                        object.invitations = [];
                        for (var j = 0; j < message.invitations.length; ++j)
                            object.invitations[j] = $root.space.kenko.proto.InvitationProto.toObject(message.invitations[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this InvitationProtos to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProtos.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProtos
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProtos.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProtos";
                };

                return InvitationProtos;
            })();

            proto.InvitationProto = (function() {

                /**
                 * Properties of an InvitationProto.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProto
                 * @property {string|null} [inviteCode] InvitationProto inviteCode
                 * @property {number|Long|null} [generatedTimestamp] InvitationProto generatedTimestamp
                 * @property {boolean|null} [alphaProviderAutoApprove] InvitationProto alphaProviderAutoApprove
                 */

                /**
                 * Constructs a new InvitationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProto.
                 * @implements IInvitationProto
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 */
                function InvitationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProto inviteCode.
                 * @member {string} inviteCode
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.inviteCode = "";

                /**
                 * InvitationProto generatedTimestamp.
                 * @member {number|Long} generatedTimestamp
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.generatedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * InvitationProto alphaProviderAutoApprove.
                 * @member {boolean} alphaProviderAutoApprove
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.alphaProviderAutoApprove = false;

                /**
                 * Creates a new InvitationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto instance
                 */
                InvitationProto.create = function create(properties) {
                    return new InvitationProto(properties);
                };

                /**
                 * Encodes the specified InvitationProto message. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
                    if (message.generatedTimestamp != null && Object.hasOwnProperty.call(message, "generatedTimestamp"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generatedTimestamp);
                    if (message.alphaProviderAutoApprove != null && Object.hasOwnProperty.call(message, "alphaProviderAutoApprove"))
                        writer.uint32(/* id 1000, wireType 0 =*/8000).bool(message.alphaProviderAutoApprove);
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProto message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inviteCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.generatedTimestamp = reader.int64();
                                break;
                            }
                        case 1000: {
                                message.alphaProviderAutoApprove = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        if (!$util.isString(message.inviteCode))
                            return "inviteCode: string expected";
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (!$util.isInteger(message.generatedTimestamp) && !(message.generatedTimestamp && $util.isInteger(message.generatedTimestamp.low) && $util.isInteger(message.generatedTimestamp.high)))
                            return "generatedTimestamp: integer|Long expected";
                    if (message.alphaProviderAutoApprove != null && message.hasOwnProperty("alphaProviderAutoApprove"))
                        if (typeof message.alphaProviderAutoApprove !== "boolean")
                            return "alphaProviderAutoApprove: boolean expected";
                    return null;
                };

                /**
                 * Creates an InvitationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 */
                InvitationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProto)
                        return object;
                    var message = new $root.space.kenko.proto.InvitationProto();
                    if (object.inviteCode != null)
                        message.inviteCode = String(object.inviteCode);
                    if (object.generatedTimestamp != null)
                        if ($util.Long)
                            (message.generatedTimestamp = $util.Long.fromValue(object.generatedTimestamp)).unsigned = false;
                        else if (typeof object.generatedTimestamp === "string")
                            message.generatedTimestamp = parseInt(object.generatedTimestamp, 10);
                        else if (typeof object.generatedTimestamp === "number")
                            message.generatedTimestamp = object.generatedTimestamp;
                        else if (typeof object.generatedTimestamp === "object")
                            message.generatedTimestamp = new $util.LongBits(object.generatedTimestamp.low >>> 0, object.generatedTimestamp.high >>> 0).toNumber();
                    if (object.alphaProviderAutoApprove != null)
                        message.alphaProviderAutoApprove = Boolean(object.alphaProviderAutoApprove);
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.InvitationProto} message InvitationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.inviteCode = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.generatedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedTimestamp = options.longs === String ? "0" : 0;
                        object.alphaProviderAutoApprove = false;
                    }
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        object.inviteCode = message.inviteCode;
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (typeof message.generatedTimestamp === "number")
                            object.generatedTimestamp = options.longs === String ? String(message.generatedTimestamp) : message.generatedTimestamp;
                        else
                            object.generatedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.generatedTimestamp) : options.longs === Number ? new $util.LongBits(message.generatedTimestamp.low >>> 0, message.generatedTimestamp.high >>> 0).toNumber() : message.generatedTimestamp;
                    if (message.alphaProviderAutoApprove != null && message.hasOwnProperty("alphaProviderAutoApprove"))
                        object.alphaProviderAutoApprove = message.alphaProviderAutoApprove;
                    return object;
                };

                /**
                 * Converts this InvitationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProto";
                };

                return InvitationProto;
            })();

            proto.UserProfileSchemaProto = (function() {

                /**
                 * Properties of a UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IUserProfileSchemaProto
                 * @property {Array.<space.kenko.proto.IClientAppointmentSchemaProto>|null} [appointments] UserProfileSchemaProto appointments
                 * @property {string|null} [name] UserProfileSchemaProto name
                 * @property {space.kenko.proto.IWellnessSurveySchemaProto|null} [wellnessSurvey] UserProfileSchemaProto wellnessSurvey
                 */

                /**
                 * Constructs a new UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a UserProfileSchemaProto.
                 * @implements IUserProfileSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 */
                function UserProfileSchemaProto(properties) {
                    this.appointments = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserProfileSchemaProto appointments.
                 * @member {Array.<space.kenko.proto.IClientAppointmentSchemaProto>} appointments
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.appointments = $util.emptyArray;

                /**
                 * UserProfileSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.name = "";

                /**
                 * UserProfileSchemaProto wellnessSurvey.
                 * @member {space.kenko.proto.IWellnessSurveySchemaProto|null|undefined} wellnessSurvey
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.wellnessSurvey = null;

                /**
                 * Creates a new UserProfileSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto instance
                 */
                UserProfileSchemaProto.create = function create(properties) {
                    return new UserProfileSchemaProto(properties);
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointments != null && message.appointments.length)
                        for (var i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.ClientAppointmentSchemaProto.encode(message.appointments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.wellnessSurvey != null && Object.hasOwnProperty.call(message, "wellnessSurvey"))
                        $root.space.kenko.proto.WellnessSurveySchemaProto.encode(message.wellnessSurvey, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.ClientAppointmentSchemaProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserProfileSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProfileSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (var i = 0; i < message.appointments.length; ++i) {
                            var error = $root.space.kenko.proto.ClientAppointmentSchemaProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey")) {
                        var error = $root.space.kenko.proto.WellnessSurveySchemaProto.verify(message.wellnessSurvey);
                        if (error)
                            return "wellnessSurvey." + error;
                    }
                    return null;
                };

                /**
                 * Creates a UserProfileSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 */
                UserProfileSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UserProfileSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: array expected");
                        message.appointments = [];
                        for (var i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.ClientAppointmentSchemaProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.wellnessSurvey != null) {
                        if (typeof object.wellnessSurvey !== "object")
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.wellnessSurvey: object expected");
                        message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.fromObject(object.wellnessSurvey);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a UserProfileSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.UserProfileSchemaProto} message UserProfileSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProfileSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.appointments = [];
                    if (options.defaults) {
                        object.name = "";
                        object.wellnessSurvey = null;
                    }
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (var j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.ClientAppointmentSchemaProto.toObject(message.appointments[j], options);
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey"))
                        object.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.toObject(message.wellnessSurvey, options);
                    return object;
                };

                /**
                 * Converts this UserProfileSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProfileSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserProfileSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProfileSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UserProfileSchemaProto";
                };

                return UserProfileSchemaProto;
            })();

            proto.WellnessSurveySchemaProto = (function() {

                /**
                 * Properties of a WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IWellnessSurveySchemaProto
                 * @property {Array.<string>|null} [holisticHealthInterests] WellnessSurveySchemaProto holisticHealthInterests
                 * @property {string|null} [familiarityWithEasternDietaryPractices] WellnessSurveySchemaProto familiarityWithEasternDietaryPractices
                 * @property {Array.<string>|null} [fitnessGoals] WellnessSurveySchemaProto fitnessGoals
                 * @property {string|null} [currentSkincareRoutine] WellnessSurveySchemaProto currentSkincareRoutine
                 * @property {Array.<string>|null} [hairRelatedConcerns] WellnessSurveySchemaProto hairRelatedConcerns
                 */

                /**
                 * Constructs a new WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a WellnessSurveySchemaProto.
                 * @implements IWellnessSurveySchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 */
                function WellnessSurveySchemaProto(properties) {
                    this.holisticHealthInterests = [];
                    this.fitnessGoals = [];
                    this.hairRelatedConcerns = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WellnessSurveySchemaProto holisticHealthInterests.
                 * @member {Array.<string>} holisticHealthInterests
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.holisticHealthInterests = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto familiarityWithEasternDietaryPractices.
                 * @member {string} familiarityWithEasternDietaryPractices
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.familiarityWithEasternDietaryPractices = "";

                /**
                 * WellnessSurveySchemaProto fitnessGoals.
                 * @member {Array.<string>} fitnessGoals
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.fitnessGoals = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto currentSkincareRoutine.
                 * @member {string} currentSkincareRoutine
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.currentSkincareRoutine = "";

                /**
                 * WellnessSurveySchemaProto hairRelatedConcerns.
                 * @member {Array.<string>} hairRelatedConcerns
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.hairRelatedConcerns = $util.emptyArray;

                /**
                 * Creates a new WellnessSurveySchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto instance
                 */
                WellnessSurveySchemaProto.create = function create(properties) {
                    return new WellnessSurveySchemaProto(properties);
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.holisticHealthInterests != null && message.holisticHealthInterests.length)
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.holisticHealthInterests[i]);
                    if (message.familiarityWithEasternDietaryPractices != null && Object.hasOwnProperty.call(message, "familiarityWithEasternDietaryPractices"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.familiarityWithEasternDietaryPractices);
                    if (message.fitnessGoals != null && message.fitnessGoals.length)
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fitnessGoals[i]);
                    if (message.currentSkincareRoutine != null && Object.hasOwnProperty.call(message, "currentSkincareRoutine"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentSkincareRoutine);
                    if (message.hairRelatedConcerns != null && message.hairRelatedConcerns.length)
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.hairRelatedConcerns[i]);
                    return writer;
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.holisticHealthInterests && message.holisticHealthInterests.length))
                                    message.holisticHealthInterests = [];
                                message.holisticHealthInterests.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.familiarityWithEasternDietaryPractices = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fitnessGoals && message.fitnessGoals.length))
                                    message.fitnessGoals = [];
                                message.fitnessGoals.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.currentSkincareRoutine = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.hairRelatedConcerns && message.hairRelatedConcerns.length))
                                    message.hairRelatedConcerns = [];
                                message.hairRelatedConcerns.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WellnessSurveySchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WellnessSurveySchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.holisticHealthInterests != null && message.hasOwnProperty("holisticHealthInterests")) {
                        if (!Array.isArray(message.holisticHealthInterests))
                            return "holisticHealthInterests: array expected";
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            if (!$util.isString(message.holisticHealthInterests[i]))
                                return "holisticHealthInterests: string[] expected";
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        if (!$util.isString(message.familiarityWithEasternDietaryPractices))
                            return "familiarityWithEasternDietaryPractices: string expected";
                    if (message.fitnessGoals != null && message.hasOwnProperty("fitnessGoals")) {
                        if (!Array.isArray(message.fitnessGoals))
                            return "fitnessGoals: array expected";
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            if (!$util.isString(message.fitnessGoals[i]))
                                return "fitnessGoals: string[] expected";
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        if (!$util.isString(message.currentSkincareRoutine))
                            return "currentSkincareRoutine: string expected";
                    if (message.hairRelatedConcerns != null && message.hasOwnProperty("hairRelatedConcerns")) {
                        if (!Array.isArray(message.hairRelatedConcerns))
                            return "hairRelatedConcerns: array expected";
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            if (!$util.isString(message.hairRelatedConcerns[i]))
                                return "hairRelatedConcerns: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a WellnessSurveySchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 */
                WellnessSurveySchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.WellnessSurveySchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    if (object.holisticHealthInterests) {
                        if (!Array.isArray(object.holisticHealthInterests))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.holisticHealthInterests: array expected");
                        message.holisticHealthInterests = [];
                        for (var i = 0; i < object.holisticHealthInterests.length; ++i)
                            message.holisticHealthInterests[i] = String(object.holisticHealthInterests[i]);
                    }
                    if (object.familiarityWithEasternDietaryPractices != null)
                        message.familiarityWithEasternDietaryPractices = String(object.familiarityWithEasternDietaryPractices);
                    if (object.fitnessGoals) {
                        if (!Array.isArray(object.fitnessGoals))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.fitnessGoals: array expected");
                        message.fitnessGoals = [];
                        for (var i = 0; i < object.fitnessGoals.length; ++i)
                            message.fitnessGoals[i] = String(object.fitnessGoals[i]);
                    }
                    if (object.currentSkincareRoutine != null)
                        message.currentSkincareRoutine = String(object.currentSkincareRoutine);
                    if (object.hairRelatedConcerns) {
                        if (!Array.isArray(object.hairRelatedConcerns))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.hairRelatedConcerns: array expected");
                        message.hairRelatedConcerns = [];
                        for (var i = 0; i < object.hairRelatedConcerns.length; ++i)
                            message.hairRelatedConcerns[i] = String(object.hairRelatedConcerns[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WellnessSurveySchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.WellnessSurveySchemaProto} message WellnessSurveySchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WellnessSurveySchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.holisticHealthInterests = [];
                        object.fitnessGoals = [];
                        object.hairRelatedConcerns = [];
                    }
                    if (options.defaults) {
                        object.familiarityWithEasternDietaryPractices = "";
                        object.currentSkincareRoutine = "";
                    }
                    if (message.holisticHealthInterests && message.holisticHealthInterests.length) {
                        object.holisticHealthInterests = [];
                        for (var j = 0; j < message.holisticHealthInterests.length; ++j)
                            object.holisticHealthInterests[j] = message.holisticHealthInterests[j];
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        object.familiarityWithEasternDietaryPractices = message.familiarityWithEasternDietaryPractices;
                    if (message.fitnessGoals && message.fitnessGoals.length) {
                        object.fitnessGoals = [];
                        for (var j = 0; j < message.fitnessGoals.length; ++j)
                            object.fitnessGoals[j] = message.fitnessGoals[j];
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        object.currentSkincareRoutine = message.currentSkincareRoutine;
                    if (message.hairRelatedConcerns && message.hairRelatedConcerns.length) {
                        object.hairRelatedConcerns = [];
                        for (var j = 0; j < message.hairRelatedConcerns.length; ++j)
                            object.hairRelatedConcerns[j] = message.hairRelatedConcerns[j];
                    }
                    return object;
                };

                /**
                 * Converts this WellnessSurveySchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WellnessSurveySchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WellnessSurveySchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WellnessSurveySchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.WellnessSurveySchemaProto";
                };

                return WellnessSurveySchemaProto;
            })();

            proto.ClientAppointmentSchemaProto = (function() {

                /**
                 * Properties of a ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IClientAppointmentSchemaProto
                 * @property {string|null} [appointmentId] ClientAppointmentSchemaProto appointmentId
                 * @property {number|Long|null} [startTimeMs] ClientAppointmentSchemaProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] ClientAppointmentSchemaProto endTimeMs
                 * @property {string|null} [serviceName] ClientAppointmentSchemaProto serviceName
                 * @property {string|null} [providerName] ClientAppointmentSchemaProto providerName
                 * @property {string|null} [providerId] ClientAppointmentSchemaProto providerId
                 * @property {string|null} [businessId] ClientAppointmentSchemaProto businessId
                 * @property {string|null} [address] ClientAppointmentSchemaProto address
                 * @property {space.kenko.proto.IPriceProto|null} [price] ClientAppointmentSchemaProto price
                 */

                /**
                 * Constructs a new ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ClientAppointmentSchemaProto.
                 * @implements IClientAppointmentSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 */
                function ClientAppointmentSchemaProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ClientAppointmentSchemaProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.appointmentId = "";

                /**
                 * ClientAppointmentSchemaProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto serviceName.
                 * @member {string} serviceName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.serviceName = "";

                /**
                 * ClientAppointmentSchemaProto providerName.
                 * @member {string} providerName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.providerName = "";

                /**
                 * ClientAppointmentSchemaProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.providerId = "";

                /**
                 * ClientAppointmentSchemaProto businessId.
                 * @member {string} businessId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.businessId = "";

                /**
                 * ClientAppointmentSchemaProto address.
                 * @member {string} address
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.address = "";

                /**
                 * ClientAppointmentSchemaProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.price = null;

                /**
                 * Creates a new ClientAppointmentSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto instance
                 */
                ClientAppointmentSchemaProto.create = function create(properties) {
                    return new ClientAppointmentSchemaProto(properties);
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.serviceName != null && Object.hasOwnProperty.call(message, "serviceName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceName);
                    if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerName);
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.providerId);
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.businessId);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.address);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.serviceName = reader.string();
                                break;
                            }
                        case 5: {
                                message.providerName = reader.string();
                                break;
                            }
                        case 6: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 7: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 8: {
                                message.address = reader.string();
                                break;
                            }
                        case 9: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClientAppointmentSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClientAppointmentSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        if (!$util.isString(message.serviceName))
                            return "serviceName: string expected";
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        if (!$util.isString(message.providerName))
                            return "providerName: string expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ClientAppointmentSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 */
                ClientAppointmentSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ClientAppointmentSchemaProto)
                        return object;
                    var message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.serviceName != null)
                        message.serviceName = String(object.serviceName);
                    if (object.providerName != null)
                        message.providerName = String(object.providerName);
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.ClientAppointmentSchemaProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ClientAppointmentSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.ClientAppointmentSchemaProto} message ClientAppointmentSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClientAppointmentSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.serviceName = "";
                        object.providerName = "";
                        object.providerId = "";
                        object.businessId = "";
                        object.address = "";
                        object.price = null;
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        object.serviceName = message.serviceName;
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        object.providerName = message.providerName;
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    return object;
                };

                /**
                 * Converts this ClientAppointmentSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClientAppointmentSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ClientAppointmentSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClientAppointmentSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ClientAppointmentSchemaProto";
                };

                return ClientAppointmentSchemaProto;
            })();

            proto.SettingsProto = (function() {

                /**
                 * Properties of a SettingsProto.
                 * @memberof space.kenko.proto
                 * @interface ISettingsProto
                 * @property {string|null} [llmModel] SettingsProto llmModel
                 * @property {string|null} [llmSystemPrompt] SettingsProto llmSystemPrompt
                 * @property {string|null} [llmBackend] SettingsProto llmBackend
                 * @property {string|null} [providerImagesBucket] SettingsProto providerImagesBucket
                 * @property {string|null} [kenkoSupportBusinessId] SettingsProto kenkoSupportBusinessId
                 * @property {string|null} [kenkoConsultationServiceSku] SettingsProto kenkoConsultationServiceSku
                 * @property {string|null} [sendgridApiKey] SettingsProto sendgridApiKey
                 */

                /**
                 * Constructs a new SettingsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a SettingsProto.
                 * @implements ISettingsProto
                 * @constructor
                 * @param {space.kenko.proto.ISettingsProto=} [properties] Properties to set
                 */
                function SettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SettingsProto llmModel.
                 * @member {string} llmModel
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmModel = "";

                /**
                 * SettingsProto llmSystemPrompt.
                 * @member {string} llmSystemPrompt
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmSystemPrompt = "";

                /**
                 * SettingsProto llmBackend.
                 * @member {string} llmBackend
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmBackend = "";

                /**
                 * SettingsProto providerImagesBucket.
                 * @member {string} providerImagesBucket
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.providerImagesBucket = "";

                /**
                 * SettingsProto kenkoSupportBusinessId.
                 * @member {string} kenkoSupportBusinessId
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.kenkoSupportBusinessId = "";

                /**
                 * SettingsProto kenkoConsultationServiceSku.
                 * @member {string} kenkoConsultationServiceSku
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.kenkoConsultationServiceSku = "";

                /**
                 * SettingsProto sendgridApiKey.
                 * @member {string} sendgridApiKey
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.sendgridApiKey = "";

                /**
                 * Creates a new SettingsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto instance
                 */
                SettingsProto.create = function create(properties) {
                    return new SettingsProto(properties);
                };

                /**
                 * Encodes the specified SettingsProto message. Does not implicitly {@link space.kenko.proto.SettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.llmModel != null && Object.hasOwnProperty.call(message, "llmModel"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.llmModel);
                    if (message.llmSystemPrompt != null && Object.hasOwnProperty.call(message, "llmSystemPrompt"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.llmSystemPrompt);
                    if (message.llmBackend != null && Object.hasOwnProperty.call(message, "llmBackend"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.llmBackend);
                    if (message.providerImagesBucket != null && Object.hasOwnProperty.call(message, "providerImagesBucket"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.providerImagesBucket);
                    if (message.kenkoSupportBusinessId != null && Object.hasOwnProperty.call(message, "kenkoSupportBusinessId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.kenkoSupportBusinessId);
                    if (message.kenkoConsultationServiceSku != null && Object.hasOwnProperty.call(message, "kenkoConsultationServiceSku"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.kenkoConsultationServiceSku);
                    if (message.sendgridApiKey != null && Object.hasOwnProperty.call(message, "sendgridApiKey"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.sendgridApiKey);
                    return writer;
                };

                /**
                 * Encodes the specified SettingsProto message, length delimited. Does not implicitly {@link space.kenko.proto.SettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.SettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.llmModel = reader.string();
                                break;
                            }
                        case 2: {
                                message.llmSystemPrompt = reader.string();
                                break;
                            }
                        case 3: {
                                message.llmBackend = reader.string();
                                break;
                            }
                        case 4: {
                                message.providerImagesBucket = reader.string();
                                break;
                            }
                        case 5: {
                                message.kenkoSupportBusinessId = reader.string();
                                break;
                            }
                        case 6: {
                                message.kenkoConsultationServiceSku = reader.string();
                                break;
                            }
                        case 7: {
                                message.sendgridApiKey = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SettingsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.llmModel != null && message.hasOwnProperty("llmModel"))
                        if (!$util.isString(message.llmModel))
                            return "llmModel: string expected";
                    if (message.llmSystemPrompt != null && message.hasOwnProperty("llmSystemPrompt"))
                        if (!$util.isString(message.llmSystemPrompt))
                            return "llmSystemPrompt: string expected";
                    if (message.llmBackend != null && message.hasOwnProperty("llmBackend"))
                        if (!$util.isString(message.llmBackend))
                            return "llmBackend: string expected";
                    if (message.providerImagesBucket != null && message.hasOwnProperty("providerImagesBucket"))
                        if (!$util.isString(message.providerImagesBucket))
                            return "providerImagesBucket: string expected";
                    if (message.kenkoSupportBusinessId != null && message.hasOwnProperty("kenkoSupportBusinessId"))
                        if (!$util.isString(message.kenkoSupportBusinessId))
                            return "kenkoSupportBusinessId: string expected";
                    if (message.kenkoConsultationServiceSku != null && message.hasOwnProperty("kenkoConsultationServiceSku"))
                        if (!$util.isString(message.kenkoConsultationServiceSku))
                            return "kenkoConsultationServiceSku: string expected";
                    if (message.sendgridApiKey != null && message.hasOwnProperty("sendgridApiKey"))
                        if (!$util.isString(message.sendgridApiKey))
                            return "sendgridApiKey: string expected";
                    return null;
                };

                /**
                 * Creates a SettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 */
                SettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.SettingsProto)
                        return object;
                    var message = new $root.space.kenko.proto.SettingsProto();
                    if (object.llmModel != null)
                        message.llmModel = String(object.llmModel);
                    if (object.llmSystemPrompt != null)
                        message.llmSystemPrompt = String(object.llmSystemPrompt);
                    if (object.llmBackend != null)
                        message.llmBackend = String(object.llmBackend);
                    if (object.providerImagesBucket != null)
                        message.providerImagesBucket = String(object.providerImagesBucket);
                    if (object.kenkoSupportBusinessId != null)
                        message.kenkoSupportBusinessId = String(object.kenkoSupportBusinessId);
                    if (object.kenkoConsultationServiceSku != null)
                        message.kenkoConsultationServiceSku = String(object.kenkoConsultationServiceSku);
                    if (object.sendgridApiKey != null)
                        message.sendgridApiKey = String(object.sendgridApiKey);
                    return message;
                };

                /**
                 * Creates a plain object from a SettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.SettingsProto} message SettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.llmModel = "";
                        object.llmSystemPrompt = "";
                        object.llmBackend = "";
                        object.providerImagesBucket = "";
                        object.kenkoSupportBusinessId = "";
                        object.kenkoConsultationServiceSku = "";
                        object.sendgridApiKey = "";
                    }
                    if (message.llmModel != null && message.hasOwnProperty("llmModel"))
                        object.llmModel = message.llmModel;
                    if (message.llmSystemPrompt != null && message.hasOwnProperty("llmSystemPrompt"))
                        object.llmSystemPrompt = message.llmSystemPrompt;
                    if (message.llmBackend != null && message.hasOwnProperty("llmBackend"))
                        object.llmBackend = message.llmBackend;
                    if (message.providerImagesBucket != null && message.hasOwnProperty("providerImagesBucket"))
                        object.providerImagesBucket = message.providerImagesBucket;
                    if (message.kenkoSupportBusinessId != null && message.hasOwnProperty("kenkoSupportBusinessId"))
                        object.kenkoSupportBusinessId = message.kenkoSupportBusinessId;
                    if (message.kenkoConsultationServiceSku != null && message.hasOwnProperty("kenkoConsultationServiceSku"))
                        object.kenkoConsultationServiceSku = message.kenkoConsultationServiceSku;
                    if (message.sendgridApiKey != null && message.hasOwnProperty("sendgridApiKey"))
                        object.sendgridApiKey = message.sendgridApiKey;
                    return object;
                };

                /**
                 * Converts this SettingsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SettingsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.SettingsProto";
                };

                return SettingsProto;
            })();

            proto.ProviderSurveyProto = (function() {

                /**
                 * Properties of a ProviderSurveyProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderSurveyProto
                 * @property {string|null} [businessName] ProviderSurveyProto businessName
                 * @property {string|null} [businessPhone] ProviderSurveyProto businessPhone
                 * @property {string|null} [businessEmail] ProviderSurveyProto businessEmail
                 * @property {string|null} [website] ProviderSurveyProto website
                 * @property {Array.<string>|null} [servicesOffered] ProviderSurveyProto servicesOffered
                 * @property {space.kenko.proto.SurveyRole|null} [personsBusinessRole] ProviderSurveyProto personsBusinessRole
                 * @property {Array.<space.kenko.proto.ISpecialtyProto>|null} [specialty] ProviderSurveyProto specialty
                 * @property {Array.<space.kenko.proto.IEducationProto>|null} [education] ProviderSurveyProto education
                 * @property {Array.<space.kenko.proto.ILicenseOrCredentialsProto>|null} [licenseCredentials] ProviderSurveyProto licenseCredentials
                 * @property {Array.<space.kenko.proto.SurveySessionType>|null} [sessionTypes] ProviderSurveyProto sessionTypes
                 * @property {Array.<space.kenko.proto.SurveyApptLocations>|null} [appointmentLocations] ProviderSurveyProto appointmentLocations
                 * @property {space.kenko.proto.IAddressProto|null} [addresses] ProviderSurveyProto addresses
                 * @property {string|null} [surveyCompletedByProviderId] ProviderSurveyProto surveyCompletedByProviderId
                 */

                /**
                 * Constructs a new ProviderSurveyProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderSurveyProto.
                 * @implements IProviderSurveyProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderSurveyProto=} [properties] Properties to set
                 */
                function ProviderSurveyProto(properties) {
                    this.servicesOffered = [];
                    this.specialty = [];
                    this.education = [];
                    this.licenseCredentials = [];
                    this.sessionTypes = [];
                    this.appointmentLocations = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderSurveyProto businessName.
                 * @member {string} businessName
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.businessName = "";

                /**
                 * ProviderSurveyProto businessPhone.
                 * @member {string} businessPhone
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.businessPhone = "";

                /**
                 * ProviderSurveyProto businessEmail.
                 * @member {string} businessEmail
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.businessEmail = "";

                /**
                 * ProviderSurveyProto website.
                 * @member {string} website
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.website = "";

                /**
                 * ProviderSurveyProto servicesOffered.
                 * @member {Array.<string>} servicesOffered
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.servicesOffered = $util.emptyArray;

                /**
                 * ProviderSurveyProto personsBusinessRole.
                 * @member {space.kenko.proto.SurveyRole} personsBusinessRole
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.personsBusinessRole = 0;

                /**
                 * ProviderSurveyProto specialty.
                 * @member {Array.<space.kenko.proto.ISpecialtyProto>} specialty
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.specialty = $util.emptyArray;

                /**
                 * ProviderSurveyProto education.
                 * @member {Array.<space.kenko.proto.IEducationProto>} education
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.education = $util.emptyArray;

                /**
                 * ProviderSurveyProto licenseCredentials.
                 * @member {Array.<space.kenko.proto.ILicenseOrCredentialsProto>} licenseCredentials
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.licenseCredentials = $util.emptyArray;

                /**
                 * ProviderSurveyProto sessionTypes.
                 * @member {Array.<space.kenko.proto.SurveySessionType>} sessionTypes
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.sessionTypes = $util.emptyArray;

                /**
                 * ProviderSurveyProto appointmentLocations.
                 * @member {Array.<space.kenko.proto.SurveyApptLocations>} appointmentLocations
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.appointmentLocations = $util.emptyArray;

                /**
                 * ProviderSurveyProto addresses.
                 * @member {space.kenko.proto.IAddressProto|null|undefined} addresses
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.addresses = null;

                /**
                 * ProviderSurveyProto surveyCompletedByProviderId.
                 * @member {string} surveyCompletedByProviderId
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 */
                ProviderSurveyProto.prototype.surveyCompletedByProviderId = "";

                /**
                 * Creates a new ProviderSurveyProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {space.kenko.proto.IProviderSurveyProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderSurveyProto} ProviderSurveyProto instance
                 */
                ProviderSurveyProto.create = function create(properties) {
                    return new ProviderSurveyProto(properties);
                };

                /**
                 * Encodes the specified ProviderSurveyProto message. Does not implicitly {@link space.kenko.proto.ProviderSurveyProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {space.kenko.proto.IProviderSurveyProto} message ProviderSurveyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderSurveyProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.businessPhone != null && Object.hasOwnProperty.call(message, "businessPhone"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessPhone);
                    if (message.businessEmail != null && Object.hasOwnProperty.call(message, "businessEmail"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessEmail);
                    if (message.website != null && Object.hasOwnProperty.call(message, "website"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.website);
                    if (message.servicesOffered != null && message.servicesOffered.length)
                        for (var i = 0; i < message.servicesOffered.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.servicesOffered[i]);
                    if (message.personsBusinessRole != null && Object.hasOwnProperty.call(message, "personsBusinessRole"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.personsBusinessRole);
                    if (message.specialty != null && message.specialty.length)
                        for (var i = 0; i < message.specialty.length; ++i)
                            $root.space.kenko.proto.SpecialtyProto.encode(message.specialty[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.education != null && message.education.length)
                        for (var i = 0; i < message.education.length; ++i)
                            $root.space.kenko.proto.EducationProto.encode(message.education[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.licenseCredentials != null && message.licenseCredentials.length)
                        for (var i = 0; i < message.licenseCredentials.length; ++i)
                            $root.space.kenko.proto.LicenseOrCredentialsProto.encode(message.licenseCredentials[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.sessionTypes != null && message.sessionTypes.length) {
                        writer.uint32(/* id 11, wireType 2 =*/90).fork();
                        for (var i = 0; i < message.sessionTypes.length; ++i)
                            writer.int32(message.sessionTypes[i]);
                        writer.ldelim();
                    }
                    if (message.appointmentLocations != null && message.appointmentLocations.length) {
                        writer.uint32(/* id 12, wireType 2 =*/98).fork();
                        for (var i = 0; i < message.appointmentLocations.length; ++i)
                            writer.int32(message.appointmentLocations[i]);
                        writer.ldelim();
                    }
                    if (message.addresses != null && Object.hasOwnProperty.call(message, "addresses"))
                        $root.space.kenko.proto.AddressProto.encode(message.addresses, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.surveyCompletedByProviderId != null && Object.hasOwnProperty.call(message, "surveyCompletedByProviderId"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.surveyCompletedByProviderId);
                    return writer;
                };

                /**
                 * Encodes the specified ProviderSurveyProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderSurveyProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {space.kenko.proto.IProviderSurveyProto} message ProviderSurveyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderSurveyProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderSurveyProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderSurveyProto} ProviderSurveyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderSurveyProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderSurveyProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessPhone = reader.string();
                                break;
                            }
                        case 3: {
                                message.businessEmail = reader.string();
                                break;
                            }
                        case 4: {
                                message.website = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.servicesOffered && message.servicesOffered.length))
                                    message.servicesOffered = [];
                                message.servicesOffered.push(reader.string());
                                break;
                            }
                        case 6: {
                                message.personsBusinessRole = reader.int32();
                                break;
                            }
                        case 7: {
                                if (!(message.specialty && message.specialty.length))
                                    message.specialty = [];
                                message.specialty.push($root.space.kenko.proto.SpecialtyProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 8: {
                                if (!(message.education && message.education.length))
                                    message.education = [];
                                message.education.push($root.space.kenko.proto.EducationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 9: {
                                if (!(message.licenseCredentials && message.licenseCredentials.length))
                                    message.licenseCredentials = [];
                                message.licenseCredentials.push($root.space.kenko.proto.LicenseOrCredentialsProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 11: {
                                if (!(message.sessionTypes && message.sessionTypes.length))
                                    message.sessionTypes = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.sessionTypes.push(reader.int32());
                                } else
                                    message.sessionTypes.push(reader.int32());
                                break;
                            }
                        case 12: {
                                if (!(message.appointmentLocations && message.appointmentLocations.length))
                                    message.appointmentLocations = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.appointmentLocations.push(reader.int32());
                                } else
                                    message.appointmentLocations.push(reader.int32());
                                break;
                            }
                        case 13: {
                                message.addresses = $root.space.kenko.proto.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.surveyCompletedByProviderId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderSurveyProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderSurveyProto} ProviderSurveyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderSurveyProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderSurveyProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderSurveyProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessPhone != null && message.hasOwnProperty("businessPhone"))
                        if (!$util.isString(message.businessPhone))
                            return "businessPhone: string expected";
                    if (message.businessEmail != null && message.hasOwnProperty("businessEmail"))
                        if (!$util.isString(message.businessEmail))
                            return "businessEmail: string expected";
                    if (message.website != null && message.hasOwnProperty("website"))
                        if (!$util.isString(message.website))
                            return "website: string expected";
                    if (message.servicesOffered != null && message.hasOwnProperty("servicesOffered")) {
                        if (!Array.isArray(message.servicesOffered))
                            return "servicesOffered: array expected";
                        for (var i = 0; i < message.servicesOffered.length; ++i)
                            if (!$util.isString(message.servicesOffered[i]))
                                return "servicesOffered: string[] expected";
                    }
                    if (message.personsBusinessRole != null && message.hasOwnProperty("personsBusinessRole"))
                        switch (message.personsBusinessRole) {
                        default:
                            return "personsBusinessRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.specialty != null && message.hasOwnProperty("specialty")) {
                        if (!Array.isArray(message.specialty))
                            return "specialty: array expected";
                        for (var i = 0; i < message.specialty.length; ++i) {
                            var error = $root.space.kenko.proto.SpecialtyProto.verify(message.specialty[i]);
                            if (error)
                                return "specialty." + error;
                        }
                    }
                    if (message.education != null && message.hasOwnProperty("education")) {
                        if (!Array.isArray(message.education))
                            return "education: array expected";
                        for (var i = 0; i < message.education.length; ++i) {
                            var error = $root.space.kenko.proto.EducationProto.verify(message.education[i]);
                            if (error)
                                return "education." + error;
                        }
                    }
                    if (message.licenseCredentials != null && message.hasOwnProperty("licenseCredentials")) {
                        if (!Array.isArray(message.licenseCredentials))
                            return "licenseCredentials: array expected";
                        for (var i = 0; i < message.licenseCredentials.length; ++i) {
                            var error = $root.space.kenko.proto.LicenseOrCredentialsProto.verify(message.licenseCredentials[i]);
                            if (error)
                                return "licenseCredentials." + error;
                        }
                    }
                    if (message.sessionTypes != null && message.hasOwnProperty("sessionTypes")) {
                        if (!Array.isArray(message.sessionTypes))
                            return "sessionTypes: array expected";
                        for (var i = 0; i < message.sessionTypes.length; ++i)
                            switch (message.sessionTypes[i]) {
                            default:
                                return "sessionTypes: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.appointmentLocations != null && message.hasOwnProperty("appointmentLocations")) {
                        if (!Array.isArray(message.appointmentLocations))
                            return "appointmentLocations: array expected";
                        for (var i = 0; i < message.appointmentLocations.length; ++i)
                            switch (message.appointmentLocations[i]) {
                            default:
                                return "appointmentLocations: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                    }
                    if (message.addresses != null && message.hasOwnProperty("addresses")) {
                        var error = $root.space.kenko.proto.AddressProto.verify(message.addresses);
                        if (error)
                            return "addresses." + error;
                    }
                    if (message.surveyCompletedByProviderId != null && message.hasOwnProperty("surveyCompletedByProviderId"))
                        if (!$util.isString(message.surveyCompletedByProviderId))
                            return "surveyCompletedByProviderId: string expected";
                    return null;
                };

                /**
                 * Creates a ProviderSurveyProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderSurveyProto} ProviderSurveyProto
                 */
                ProviderSurveyProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderSurveyProto)
                        return object;
                    var message = new $root.space.kenko.proto.ProviderSurveyProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessPhone != null)
                        message.businessPhone = String(object.businessPhone);
                    if (object.businessEmail != null)
                        message.businessEmail = String(object.businessEmail);
                    if (object.website != null)
                        message.website = String(object.website);
                    if (object.servicesOffered) {
                        if (!Array.isArray(object.servicesOffered))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.servicesOffered: array expected");
                        message.servicesOffered = [];
                        for (var i = 0; i < object.servicesOffered.length; ++i)
                            message.servicesOffered[i] = String(object.servicesOffered[i]);
                    }
                    switch (object.personsBusinessRole) {
                    default:
                        if (typeof object.personsBusinessRole === "number") {
                            message.personsBusinessRole = object.personsBusinessRole;
                            break;
                        }
                        break;
                    case "ROLE_UNKNOWN":
                    case 0:
                        message.personsBusinessRole = 0;
                        break;
                    case "OFFICE_MANAGER_OR_STAFF":
                    case 1:
                        message.personsBusinessRole = 1;
                        break;
                    case "PRACTITIONER":
                    case 2:
                        message.personsBusinessRole = 2;
                        break;
                    case "BUSINESS_OWNER_AND_PRACTITIONER":
                    case 3:
                        message.personsBusinessRole = 3;
                        break;
                    case "BUSINESS_OWNER_NON_PRACTITIONER":
                    case 4:
                        message.personsBusinessRole = 4;
                        break;
                    case "OTHER":
                    case 5:
                        message.personsBusinessRole = 5;
                        break;
                    }
                    if (object.specialty) {
                        if (!Array.isArray(object.specialty))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.specialty: array expected");
                        message.specialty = [];
                        for (var i = 0; i < object.specialty.length; ++i) {
                            if (typeof object.specialty[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSurveyProto.specialty: object expected");
                            message.specialty[i] = $root.space.kenko.proto.SpecialtyProto.fromObject(object.specialty[i]);
                        }
                    }
                    if (object.education) {
                        if (!Array.isArray(object.education))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.education: array expected");
                        message.education = [];
                        for (var i = 0; i < object.education.length; ++i) {
                            if (typeof object.education[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSurveyProto.education: object expected");
                            message.education[i] = $root.space.kenko.proto.EducationProto.fromObject(object.education[i]);
                        }
                    }
                    if (object.licenseCredentials) {
                        if (!Array.isArray(object.licenseCredentials))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.licenseCredentials: array expected");
                        message.licenseCredentials = [];
                        for (var i = 0; i < object.licenseCredentials.length; ++i) {
                            if (typeof object.licenseCredentials[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderSurveyProto.licenseCredentials: object expected");
                            message.licenseCredentials[i] = $root.space.kenko.proto.LicenseOrCredentialsProto.fromObject(object.licenseCredentials[i]);
                        }
                    }
                    if (object.sessionTypes) {
                        if (!Array.isArray(object.sessionTypes))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.sessionTypes: array expected");
                        message.sessionTypes = [];
                        for (var i = 0; i < object.sessionTypes.length; ++i)
                            switch (object.sessionTypes[i]) {
                            default:
                                if (typeof object.sessionTypes[i] === "number") {
                                    message.sessionTypes[i] = object.sessionTypes[i];
                                    break;
                                }
                            case "SESSION_TYPE_UNKNOWN":
                            case 0:
                                message.sessionTypes[i] = 0;
                                break;
                            case "PRIVATE_SESSIONS":
                            case 1:
                                message.sessionTypes[i] = 1;
                                break;
                            case "SEMI_PRIVATE_SESSIONS":
                            case 2:
                                message.sessionTypes[i] = 2;
                                break;
                            case "GROUP_CLASSES":
                            case 3:
                                message.sessionTypes[i] = 3;
                                break;
                            }
                    }
                    if (object.appointmentLocations) {
                        if (!Array.isArray(object.appointmentLocations))
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.appointmentLocations: array expected");
                        message.appointmentLocations = [];
                        for (var i = 0; i < object.appointmentLocations.length; ++i)
                            switch (object.appointmentLocations[i]) {
                            default:
                                if (typeof object.appointmentLocations[i] === "number") {
                                    message.appointmentLocations[i] = object.appointmentLocations[i];
                                    break;
                                }
                            case "APPT_LOCATION_UNKNOWN":
                            case 0:
                                message.appointmentLocations[i] = 0;
                                break;
                            case "IN_PERSON_AT_BUSINESS":
                            case 1:
                                message.appointmentLocations[i] = 1;
                                break;
                            case "VIRTUAL_APPOINTMENTS":
                            case 2:
                                message.appointmentLocations[i] = 2;
                                break;
                            case "IN_HOME_APPOINTMENTS":
                            case 3:
                                message.appointmentLocations[i] = 3;
                                break;
                            }
                    }
                    if (object.addresses != null) {
                        if (typeof object.addresses !== "object")
                            throw TypeError(".space.kenko.proto.ProviderSurveyProto.addresses: object expected");
                        message.addresses = $root.space.kenko.proto.AddressProto.fromObject(object.addresses);
                    }
                    if (object.surveyCompletedByProviderId != null)
                        message.surveyCompletedByProviderId = String(object.surveyCompletedByProviderId);
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderSurveyProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {space.kenko.proto.ProviderSurveyProto} message ProviderSurveyProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderSurveyProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.servicesOffered = [];
                        object.specialty = [];
                        object.education = [];
                        object.licenseCredentials = [];
                        object.sessionTypes = [];
                        object.appointmentLocations = [];
                    }
                    if (options.defaults) {
                        object.businessName = "";
                        object.businessPhone = "";
                        object.businessEmail = "";
                        object.website = "";
                        object.personsBusinessRole = options.enums === String ? "ROLE_UNKNOWN" : 0;
                        object.addresses = null;
                        object.surveyCompletedByProviderId = "";
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.businessPhone != null && message.hasOwnProperty("businessPhone"))
                        object.businessPhone = message.businessPhone;
                    if (message.businessEmail != null && message.hasOwnProperty("businessEmail"))
                        object.businessEmail = message.businessEmail;
                    if (message.website != null && message.hasOwnProperty("website"))
                        object.website = message.website;
                    if (message.servicesOffered && message.servicesOffered.length) {
                        object.servicesOffered = [];
                        for (var j = 0; j < message.servicesOffered.length; ++j)
                            object.servicesOffered[j] = message.servicesOffered[j];
                    }
                    if (message.personsBusinessRole != null && message.hasOwnProperty("personsBusinessRole"))
                        object.personsBusinessRole = options.enums === String ? $root.space.kenko.proto.SurveyRole[message.personsBusinessRole] === undefined ? message.personsBusinessRole : $root.space.kenko.proto.SurveyRole[message.personsBusinessRole] : message.personsBusinessRole;
                    if (message.specialty && message.specialty.length) {
                        object.specialty = [];
                        for (var j = 0; j < message.specialty.length; ++j)
                            object.specialty[j] = $root.space.kenko.proto.SpecialtyProto.toObject(message.specialty[j], options);
                    }
                    if (message.education && message.education.length) {
                        object.education = [];
                        for (var j = 0; j < message.education.length; ++j)
                            object.education[j] = $root.space.kenko.proto.EducationProto.toObject(message.education[j], options);
                    }
                    if (message.licenseCredentials && message.licenseCredentials.length) {
                        object.licenseCredentials = [];
                        for (var j = 0; j < message.licenseCredentials.length; ++j)
                            object.licenseCredentials[j] = $root.space.kenko.proto.LicenseOrCredentialsProto.toObject(message.licenseCredentials[j], options);
                    }
                    if (message.sessionTypes && message.sessionTypes.length) {
                        object.sessionTypes = [];
                        for (var j = 0; j < message.sessionTypes.length; ++j)
                            object.sessionTypes[j] = options.enums === String ? $root.space.kenko.proto.SurveySessionType[message.sessionTypes[j]] === undefined ? message.sessionTypes[j] : $root.space.kenko.proto.SurveySessionType[message.sessionTypes[j]] : message.sessionTypes[j];
                    }
                    if (message.appointmentLocations && message.appointmentLocations.length) {
                        object.appointmentLocations = [];
                        for (var j = 0; j < message.appointmentLocations.length; ++j)
                            object.appointmentLocations[j] = options.enums === String ? $root.space.kenko.proto.SurveyApptLocations[message.appointmentLocations[j]] === undefined ? message.appointmentLocations[j] : $root.space.kenko.proto.SurveyApptLocations[message.appointmentLocations[j]] : message.appointmentLocations[j];
                    }
                    if (message.addresses != null && message.hasOwnProperty("addresses"))
                        object.addresses = $root.space.kenko.proto.AddressProto.toObject(message.addresses, options);
                    if (message.surveyCompletedByProviderId != null && message.hasOwnProperty("surveyCompletedByProviderId"))
                        object.surveyCompletedByProviderId = message.surveyCompletedByProviderId;
                    return object;
                };

                /**
                 * Converts this ProviderSurveyProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderSurveyProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderSurveyProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderSurveyProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderSurveyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderSurveyProto";
                };

                return ProviderSurveyProto;
            })();

            /**
             * SurveyRole enum.
             * @name space.kenko.proto.SurveyRole
             * @enum {number}
             * @property {number} ROLE_UNKNOWN=0 ROLE_UNKNOWN value
             * @property {number} OFFICE_MANAGER_OR_STAFF=1 OFFICE_MANAGER_OR_STAFF value
             * @property {number} PRACTITIONER=2 PRACTITIONER value
             * @property {number} BUSINESS_OWNER_AND_PRACTITIONER=3 BUSINESS_OWNER_AND_PRACTITIONER value
             * @property {number} BUSINESS_OWNER_NON_PRACTITIONER=4 BUSINESS_OWNER_NON_PRACTITIONER value
             * @property {number} OTHER=5 OTHER value
             */
            proto.SurveyRole = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ROLE_UNKNOWN"] = 0;
                values[valuesById[1] = "OFFICE_MANAGER_OR_STAFF"] = 1;
                values[valuesById[2] = "PRACTITIONER"] = 2;
                values[valuesById[3] = "BUSINESS_OWNER_AND_PRACTITIONER"] = 3;
                values[valuesById[4] = "BUSINESS_OWNER_NON_PRACTITIONER"] = 4;
                values[valuesById[5] = "OTHER"] = 5;
                return values;
            })();

            /**
             * SurveySessionType enum.
             * @name space.kenko.proto.SurveySessionType
             * @enum {number}
             * @property {number} SESSION_TYPE_UNKNOWN=0 SESSION_TYPE_UNKNOWN value
             * @property {number} PRIVATE_SESSIONS=1 PRIVATE_SESSIONS value
             * @property {number} SEMI_PRIVATE_SESSIONS=2 SEMI_PRIVATE_SESSIONS value
             * @property {number} GROUP_CLASSES=3 GROUP_CLASSES value
             */
            proto.SurveySessionType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SESSION_TYPE_UNKNOWN"] = 0;
                values[valuesById[1] = "PRIVATE_SESSIONS"] = 1;
                values[valuesById[2] = "SEMI_PRIVATE_SESSIONS"] = 2;
                values[valuesById[3] = "GROUP_CLASSES"] = 3;
                return values;
            })();

            /**
             * SurveyApptLocations enum.
             * @name space.kenko.proto.SurveyApptLocations
             * @enum {number}
             * @property {number} APPT_LOCATION_UNKNOWN=0 APPT_LOCATION_UNKNOWN value
             * @property {number} IN_PERSON_AT_BUSINESS=1 IN_PERSON_AT_BUSINESS value
             * @property {number} VIRTUAL_APPOINTMENTS=2 VIRTUAL_APPOINTMENTS value
             * @property {number} IN_HOME_APPOINTMENTS=3 IN_HOME_APPOINTMENTS value
             */
            proto.SurveyApptLocations = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "APPT_LOCATION_UNKNOWN"] = 0;
                values[valuesById[1] = "IN_PERSON_AT_BUSINESS"] = 1;
                values[valuesById[2] = "VIRTUAL_APPOINTMENTS"] = 2;
                values[valuesById[3] = "IN_HOME_APPOINTMENTS"] = 3;
                return values;
            })();

            return proto;
        })();

        return kenko;
    })();

    return space;
})();

module.exports = $root;
